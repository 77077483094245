// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barcode-match-table {
    font-family: Calibri, Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

.barcode-match-table th,
.barcode-match-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.barcode-match-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.barcode-match-table .header {
    background-color: #4CAF50;
    color: white;
}

.barcode-match-table .subheader {
    background-color: #8BC34A;
    color: white;
}

.barcode-match-table .row-label {
    font-weight: bold;
    
}

.barcode-match-table .grand-total {
    font-weight: bold;
}

.barcode-match-table .empty-cell {
    background-color: #f9f9f9;
}
#datae{
    padding: 5px;
  }

  .ContainerPopBarcode {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.HeadingPopbarcode {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;

}


.LoaderContainerbarcode  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/barcodeCount/BarcodeMatchTable.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,iBAAiB;;AAErB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;;AAEvB;;;AAGA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".barcode-match-table {\n    font-family: Calibri, Arial, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n    margin-top: 20px;\n}\n\n.barcode-match-table th,\n.barcode-match-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n}\n\n.barcode-match-table th {\n    background-color: #f2f2f2;\n    font-weight: bold;\n}\n\n.barcode-match-table .header {\n    background-color: #4CAF50;\n    color: white;\n}\n\n.barcode-match-table .subheader {\n    background-color: #8BC34A;\n    color: white;\n}\n\n.barcode-match-table .row-label {\n    font-weight: bold;\n    \n}\n\n.barcode-match-table .grand-total {\n    font-weight: bold;\n}\n\n.barcode-match-table .empty-cell {\n    background-color: #f9f9f9;\n}\n#datae{\n    padding: 5px;\n  }\n\n  .ContainerPopBarcode {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.HeadingPopbarcode {\n    font-size: 16px;\n    font-weight: bold;\n    margin-bottom: 10px;\n\n}\n\n\n.LoaderContainerbarcode  {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 50vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
