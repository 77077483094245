import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@mui/system'
import { Chip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Select from "./SelectRole"
import Input from '../../components/input/Input'
import axiosInstance from '../../axios/AxiosInstance'
import { useNavigate } from 'react-router-dom'
import ChipSelect from '../../components/select/ChipSelect'

const View = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Main = styled.div`
    width:90%;
    height:80%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Cards = styled.div`
    width:50%;
    background-color:white;
    padding:15px;
`
const Button = styled.div`
    height:40px;
    background-color:black;
    color:white;
    border-radius:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:15px;
    margin-top:30px;
    margin-bottom:20px;
`
const Heading = styled.div`
    fonst-size:20px;
    font-weight:700;
`
const SelDiv = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:20px;
`

const Items = [
    'create',
    'read',
    'delete',
    'update'
];


const AddRole = () => {

    const [permissions, SetPermissions] = useState([]);
    const [name, setName] = useState("");
    const [rolename, setRolename] = useState({});
    const navigate = useNavigate();

    const getdata = async () => {
        try {
            const res = await axiosInstance.get("api/permission");
            SetPermissions(res.data.data);
        } catch (error) {
            console.error(error)
        }
    }

    const handleAdd = async () => {

        var newarr = [];
        for (const key in rolename) {
            const newObj = { permissionId: key };
            rolename[key].map((curr) => {
                newObj[curr] = true;
            })
            newarr.push(newObj);
        }

        const userid = JSON.parse(localStorage.getItem('wh_tk'))?.data?._id;
        const body = {
            name: name,
            permissions: newarr,
            userId: userid
        }

        try {
            const addrole = await axiosInstance.post("api/role", body);

            if (addrole.status === 200) {
                alert("Role added successfully");
                navigate("/userrole");
            }
            else {
                alert("Something went wrong");
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getdata();
    }, [])

    return (
        <View>
            <Main>
                <Cards style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
                        <Heading>Add new user role</Heading>
                    </div>
                    <div style={{ marginBottom: "30px" }}>
                        <div style={{ fontWeight: "700" }}>Role Name</div>
                        <Input value={name} setvalue={setName} placeholder="Enter Role name" />
                    </div>
                    <div style={{ fontWeight: "700", fontSize: "20px" }}>User permissions</div>
                    {
                        permissions.map((curr) => {
                            return (
                                <SelDiv>
                                    <div style={{ fontWeight: "500", padding: "10px", border: "1px solid black", borderRadius: "5px" }}>{curr.name}</div>
                                    {/* <Select items={Items}  permissionId={curr._id} rolename={rolename} setRolename={setRolename}  label = "Operations" /> */}
                                    <ChipSelect names={Items} permissionId={curr._id} rolename={rolename} setRolename={setRolename} />
                                </SelDiv>
                            );
                        })
                    }
                    {/* <SelDiv>
                    <div style={{fontWeight:"500", padding:"10px", border:"1px solid black", borderRadius:"5px"}}>Task</div>
                    <Select items={Items} label = "Operations" />
                </SelDiv>
                <SelDiv>
                    <div style={{fontWeight:"500", padding:"10px", border:"1px solid black", borderRadius:"5px"}}>Rangermaster1</div>
                    <Select items={Items} label = "Operations" />
                </SelDiv> */}
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button onClick={handleAdd}>
                            <AddIcon />
                            &nbsp;
                            Add
                        </Button>
                    </div>
                </Cards>
            </Main>
        </View>
    )
}

export default AddRole