import { Box, Stack, Typography, OutlinedInput, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AgentTable from './agenttable/AgentTable';
import './Agent.css';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import FilterAgent from "./agenttable/FilterAgent"

const Agent = () => {

    const [showAdd, setShowAdd] = useState(false);
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState("All");
    function createData(curr) {
        // mode = mode ? "Online" : "Offline";
        return { ...curr };
    }

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('wh_tk'))?.data;
        if (userData) {
            const userRole = userData.role;
            const userType = userData.type;
            const outArr = userData.out;
            const userPermissions = userRole[0].permissions;
            if (userType == "admin") {
                setShowAdd(true);
            }
        }
    }, [])

    const navigate = useNavigate();
    return (
        <div>
            <Typography sx={{
                fontSize: '1.5rem',
                fontWeight: '700',
                marginTop: "30px"
            }} >Agents</Typography>
            <Box sx={{
                width: '100%',
                height: '100%',

                backgroundColor: '#fff',
                marginTop: '1rem'
            }} >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        marginBottom: '1rem',
                        flexWrap: 'wrap'
                    }} >
                    <FilterAgent agents={agents} setAgents={setAgents} setLoading={setLoading} createData={createData} loading={loading} statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
                    {showAdd &&
                        <Stack>
                            <button
                                className='TatSubmitbutton'
                                onClick={() => { navigate('/agent/add_agent') }}>
                                <AddIcon sx={{
                                    marginRight: '0.5rem'
                                }} />
                                Add Agent
                            </button>
                        </Stack>
                    }
                </Stack>
                <AgentTable
                    agents={agents}
                    setAgents={setAgents}
                    loading={loading}
                    setLoading={setLoading}
                    createData={createData}
                    statusFilter={statusFilter}
                />
            </Box>
        </div>
    )
}

export default Agent;