import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import phone from "../../assets/phone.png";
import Email from "../../assets/Email.png";
import Contact from "../../assets/Contact.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "black",
};

export default function Contect({ data }) {
  // console.log(data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <img style={{ width: "27px" }} src={Contact} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Contact Information</h3>

          <Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ padding: "5px" }}>
                <img style={{ width: "25px" }} src={phone} alt="react logo" />
              </Box>
              <Box sx={{ padding: "5px" }}>{data.phone}</Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ padding: "5px" }}>
                <img style={{ width: "25px" }} src={Email} alt="react logo" />
              </Box>
              <Box sx={{ padding: "5px" }}>{data.email}</Box>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ padding: "5px" }}>

                <img style={{ width: "25px" }} src={Email} alt="react logo" />
              </Box>
              <Box sx={{ padding: "5px" }}>{
                data.address?.cf_address_1
              }{data.address?.cf_address_2}{data.address?.cf_area}{data.address?.cf_city},{data.address?.cf_pincode},{data.address?.cf_state}</Box>
            </Box>

          </Box>
        </Box>
      </Modal>
    </div>
  );
}
