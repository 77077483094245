import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Select from "../../../components/select/Select";
import axiosInstance from '../../../axios/AxiosInstance';
import Input from "../../../components/input/Input";
import { Stack } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import ClearIcon from '@mui/icons-material/Clear';
import Loading from '../../../components/loading/Loading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 600px)': {
        width: 330
    }
};

const items = [
    {
        value: 'ASC',
        text: "ASC"
    },
    {
        value: "DESC",
        text: "DESC"
    }
]


const BasicModal = ({
    flag,
    setFlag,
    city,
    setTableData,
    filters,
    setFilters,
    setTotalPage,
    page,
    limit,
    setPage
}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [assigned, setAssigned] = useState("");
    const [schedule, setSchedule] = useState("");
    const [deliver, setDeliver] = useState("");

    const UserData = JSON.parse(localStorage.getItem('wh_tk'))?.data;
    const userId = UserData?._id;

    const handleAssignChange = (ev) => {
        setAssigned(ev.target.value);
        setSchedule('');
        setDeliver('');
    }

    const handleScheduleChange = (ev) => {
        setSchedule(ev.target.value);
        setAssigned('');
        setDeliver('');
    }

    const handleDeliverChange = (ev) => {
        setSchedule('');
        setAssigned('');
        setDeliver(ev.target.value);
    }

    const getdata = async () => {

        setLoading1(true);

        try {
            let url = '';

            if (assigned !== '') {
                url = url + `&sortType=assigned&sort=${assigned}`;
            }
            if (schedule !== '') {
                url = url + `&sortType=scheduled&sort=${schedule}`;
            }
            if (deliver !== '') {
                url = url + `&sortType=deliver&sort=${deliver}`;
            }

            setFilters(url);
            let baseUrl = ''
            if (UserData.type === 'agent') {
                baseUrl = `api/ticket?page=${page + 1}&limit=${limit}&agentId=${userId}&city=${city}`;
            }
            else {
                baseUrl = `api/ticket?page=${page + 1}&limit=${limit}&city=${city}`;
            }

            url = baseUrl + url;
            // console.log(url);
            const tableRes = await axiosInstance.get(url);
            if (tableRes.status === 200) {
                setLoading1(false);
                setTableData(tableRes.data.data);
                setTotalPage(tableRes.data.count);
            }
            else {
                setLoading1(false);
                alert("Something went wrong !!")
            }
        }
        catch (err) {
            setLoading1(false);
            alert("Something went wrong !!")
        }
        setFlag(!flag);
        handleClose();
    }

    const reset = async () => {
        setAssigned('');
        setSchedule('');
        setDeliver('');
        setFilters('');
        setPage(0);
        setLoading2(true);

        try {
            let baseUrl = ''
            if (UserData.type === 'agent') {
                baseUrl = `api/ticket?page=${1}&limit=${limit}&agentId=${userId}&city=${city}`;
            }
            else {
                baseUrl = `api/ticket?page=${1}&limit=${limit}&city=${city}`;
            }

            const tableRes = await axiosInstance.get(baseUrl);
            if (tableRes.status === 200) {
                setLoading2(false);
                setTableData(tableRes.data.data);
                setTotalPage(tableRes.data.count);
            }
            else {
                setLoading2(false);
                alert("Something went wrong !!");
            }
        }
        catch (err) {
            setLoading2(false);
            alert("Something went wrong !!")
        }
        handleClose();
    }

    return (
        <div>
            <Button variant="contained"
                sx={{
                    height: "40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize: "0.875rem !important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    },
                    '@media (max-width: 785px)': {
                        fontSize: "0.6rem !important",
                        // width:"60px",
                        height: "30px"
                    }
                }} onClick={handleOpen}>
                <SortIcon sx={{
                    marginRight: "10px",
                    '@media (max-width: 785px)': {
                        fontSize: "1rem !important",
                    }
                }} />
                Sort
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ClearIcon sx={{
                        position: "absolute",
                        right: "-30px",
                        top: "-22px",
                        cursor: "pointer",
                        color: "white",
                        '@media (max-width: 400px)': {
                            right: "10px",
                            top: "10px",
                            color: "black",
                        },
                    }} onClick={() => {
                        setAssigned('');
                        setSchedule('');
                        setDeliver('');
                        handleClose();
                        setFilters('');
                    }} />
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Sort By
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Stack
                            direction="column"
                        >
                            <Stack
                                direction="column"
                                spacing={3}
                                sx={{
                                    marginBottom: "50px",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                    }} >Assigned</Typography>
                                    <Select items={items} handleChange={handleAssignChange}
                                        value={assigned} label="Sort"
                                        boxstyle={{
                                            width: "70%",
                                            height: "40px",
                                            '@media (max-width: 600px)': {
                                                width: "60%"
                                            }
                                        }} />
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                    }} >Schedule</Typography>
                                    <Select items={items} handleChange={handleScheduleChange}
                                        value={schedule} label="Sort"
                                        boxstyle={{
                                            width: "70%",
                                            height: "40px",
                                            '@media (max-width: 600px)': {
                                                width: "60%"
                                            }
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '1rem',
                                        fontWeight: '500',
                                    }} >Completed</Typography>
                                    <Select items={items}
                                        handleChange={handleDeliverChange}
                                        value={deliver} label="Sort"
                                        boxstyle={{
                                            width: "70%",
                                            height: "40px",
                                            '@media (max-width: 600px)': {
                                                width: "60%"
                                            }
                                        }}
                                    />
                                </Stack>
                                {/* <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            width:"20%"
                            }} >Sub Category</Typography>
                        <Input value={subCategory} setvalue={setSubCategory} style={{width:"70%"}} type="text" placeholder="Enter sub category" />    
                    </Stack> */}
                                {/* <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                        width:"100%",
                    }}
                    >
                        <Typography sx={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            }} >Status</Typography>
                        <Input value={status} setvalue={setStatus} style={{width:"70%"}} type="text" placeholder="Enter status" />    
                    </Stack> */}
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Button variant="contained"
                                    sx={{
                                        height: "40px",
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        fontSize: "0.8rem !important",
                                        '&:hover': {
                                            backgroundColor: '#000',
                                            opacity: '0.8'
                                        }
                                    }} onClick={getdata} >
                                    {
                                        loading1 ? <Loading /> : "Ok"
                                    }
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        height: '40px',
                                        backgroundColor: '#000',
                                        color: '#fff',
                                        fontSize: "0.8rem!important",
                                        '&:hover': {
                                            backgroundColor: '#000',
                                            opacity: '0.8'
                                        }
                                    }} onClick={reset} >
                                    {
                                        loading2 ? <Loading /> : "Reset"
                                    }
                                </Button>
                            </Stack>
                        </Stack>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default BasicModal;

