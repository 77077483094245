// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @tailwind base;
@tailwind components;
@tailwind utilities; */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-div::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(228, 228, 228);
  height: 2px;
  color: black;
}

.sidebar-div::-webkit-scrollbar-thumb {
  background-color: rgb(143, 143, 143);
  border-radius: 20px;
  height: 2px;
  color: black;
}

.abc {
  height: 41px;
  width: 151;
}

#status-select {
  height: 25px;
  width: 180px;
}

#simple-popup {
  z-index: 100;

}

#DatePicker {
  width: 12pc;
  height: 2pc;
  border-radius: 10px;
  padding: 1pc;
}

.dataDiv {
  border-top: 1px solid black;
  text-align: center;
  padding: 8px;
}

#DashboardInput {
  padding: 18px;
}

#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}

#disabledNew {
  display: none;
}

#someDatePicker {
  padding: 5px;
}


#TableId {
  width: 1px !important;
  background-color: #fff;
}

#TableHeader {
  background-color: #000 !important;
  color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;sBAEsB;;AAEtB;EACE;aACW;AACb;;AAEA;EACE,UAAU;EACV,oCAAoC;EACpC,WAAW;EACX,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;;AAEd;;AAEA;EACE,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;;AAGA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;EACjC,sBAAsB;EACtB,wBAAwB;EACxB,kCAAkC;EAClC,8BAA8B;;AAEhC","sourcesContent":["/* @tailwind base;\n@tailwind components;\n@tailwind utilities; */\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.sidebar-div::-webkit-scrollbar {\n  width: 5px;\n  background-color: rgb(228, 228, 228);\n  height: 2px;\n  color: black;\n}\n\n.sidebar-div::-webkit-scrollbar-thumb {\n  background-color: rgb(143, 143, 143);\n  border-radius: 20px;\n  height: 2px;\n  color: black;\n}\n\n.abc {\n  height: 41px;\n  width: 151;\n}\n\n#status-select {\n  height: 25px;\n  width: 180px;\n}\n\n#simple-popup {\n  z-index: 100;\n\n}\n\n#DatePicker {\n  width: 12pc;\n  height: 2pc;\n  border-radius: 10px;\n  padding: 1pc;\n}\n\n.dataDiv {\n  border-top: 1px solid black;\n  text-align: center;\n  padding: 8px;\n}\n\n#DashboardInput {\n  padding: 18px;\n}\n\n#html5-qrcode-anchor-scan-type-change {\n  display: none !important;\n}\n\n#disabledNew {\n  display: none;\n}\n\n#someDatePicker {\n  padding: 5px;\n}\n\n\n#TableId {\n  width: 1px !important;\n  background-color: #fff;\n}\n\n#TableHeader {\n  background-color: #000 !important;\n  color: #fff !important;\n  display: flex !important;\n  justify-content: center !important;\n  align-items: center !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
