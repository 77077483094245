// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-page_tablePosi__ajk3Y {
  position: relative;
  overflow: scroll;
  border-radius: 10px !important;
}

.new-page_tablePosi__ajk3Y::-webkit-scrollbar {
  /* padding: 0 !important; */
  /* top: 0; */
  /* overflow-y: hidden; */
}

.new-page_scrollablediv__LAp\\+h {
  overflow-x: scroll;
  position: sticky;
  bottom: 0;
  height: 16px;
}

.new-page_tablePosi__ajk3Y {
  overflow: hidden;
}

.new-page_tablePosi__ajk3Y:hover {
  overflow: scroll;
}

.new-page_filter__7Dllh {
  display: flex;
}

.new-page_tbodyContainer__kAdRf {
  overflow-y: scroll;
  height: 75vh;
  /* width: 100%; */
}

/* .scrollabledivY {
  width: 108px;
  position: absolute;
  height: "75vh";
  right: 100;
  bottom: 50px;
  z-index: 12;
  background-color: black;
} */

.new-page_stickyColumn__t8ZKT {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  /* You can adjust the background color as needed */
}


.new-page_tableContainer__6pMx8 {
  padding: 10px;
  border-radius: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/new-page/new-page.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;;;;;;;;GAQG;;AAEH;EACE,gBAAgB;EAChB,OAAO;EACP,UAAU;EACV,uBAAuB;EACvB,kDAAkD;AACpD;;;AAGA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".tablePosi {\n  position: relative;\n  overflow: scroll;\n  border-radius: 10px !important;\n}\n\n.tablePosi::-webkit-scrollbar {\n  /* padding: 0 !important; */\n  /* top: 0; */\n  /* overflow-y: hidden; */\n}\n\n.scrollablediv {\n  overflow-x: scroll;\n  position: sticky;\n  bottom: 0;\n  height: 16px;\n}\n\n.tablePosi {\n  overflow: hidden;\n}\n\n.tablePosi:hover {\n  overflow: scroll;\n}\n\n.filter {\n  display: flex;\n}\n\n.tbodyContainer {\n  overflow-y: scroll;\n  height: 75vh;\n  /* width: 100%; */\n}\n\n/* .scrollabledivY {\n  width: 108px;\n  position: absolute;\n  height: \"75vh\";\n  right: 100;\n  bottom: 50px;\n  z-index: 12;\n  background-color: black;\n} */\n\n.stickyColumn {\n  position: sticky;\n  left: 0;\n  z-index: 1;\n  background-color: white;\n  /* You can adjust the background color as needed */\n}\n\n\n.tableContainer {\n  padding: 10px;\n  border-radius: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablePosi": `new-page_tablePosi__ajk3Y`,
	"scrollablediv": `new-page_scrollablediv__LAp+h`,
	"filter": `new-page_filter__7Dllh`,
	"tbodyContainer": `new-page_tbodyContainer__kAdRf`,
	"stickyColumn": `new-page_stickyColumn__t8ZKT`,
	"tableContainer": `new-page_tableContainer__6pMx8`
};
export default ___CSS_LOADER_EXPORT___;
