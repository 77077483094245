import {
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import SlotCard from "../../components/slots/SlotCard";
import axios from "axios";
import HolidayCard from "../../components/slots/HolidayCard";
import moment from "moment-timezone";
import axiosInstance from "../../axios/AxiosInstance"

const cityItems = [
  {
    name: "Noida",
  },
  {
    name: "Hyderabad",
  },
  {
    name: "Bangalore",
  },
  {
    name: "Pune",
  },
  {
    name: "Mumbai",
  },
  {
    name: "Gurgaon",
  },
  {
    name: "Jodhpur",
  },
];

const Slot = () => {
  const d = new Date();

  // code to loop through calendar dates

  const [monthDelta, setMonthDelta] = useState(d.getMonth());
  const [yearDelta, setYearDelta] = useState(0);
  const [MasterSlotData, setMasterSlotData] = useState([]);
  const [taskLimit, setTaskLimit] = useState({});
  const [date, setDate] = useState(new Date());
  let month = 0;
  let year = date.getFullYear();
  let daysInMonth = new Date(year, monthDelta + 1, 0).getDate();
  let dates = Array.from(
    { length: daysInMonth },
    (_, i) => new Date(year + yearDelta, month + monthDelta, i + 1)
  );

  // Current week is the starting week
  const [start, setStart] = useState(Math.floor(d.getDate() / 7));

  const updateDates = () => {
    // console.log(yearDelta , monthDelta);

    dates = Array.from(
      { length: daysInMonth },
      (_, i) => new Date(year + yearDelta, month + monthDelta, i + 1)
    );
    // console.log(dates , "dates");
  };

  const handleNextWeek = () => {
    setStart(start + 1);
    if (start === (monthDelta === 1 ? 3 : 4)) {
      setStart(0);
      setMonthDelta(monthDelta + 1);
      if (monthDelta === 11) {
        setMonthDelta(0);
        setYearDelta(yearDelta + 1);
      }
      updateDates();
    }
  };

  const handlePrevWeek = () => {
    setStart(Math.max(0, start - 1));
    if (start === 0) {
      setStart(monthDelta === 2 ? 3 : 4);
      setMonthDelta(monthDelta - 1);
      // console.log(month+monthDelta+1);
      if (monthDelta === 0) {
        setMonthDelta(11);
        setYearDelta(yearDelta - 1);
      }
      updateDates();
      // updateNextSevenDays();
    }
  };

  let nextSevenDays = dates
    ?.slice(7 * start, Math.min(7 * (start + 1), dates.length))
    ?.map((date) => ({
      date: date?.getDate(),
      dayName: date?.toLocaleString("en-us", { weekday: "short" }).toUpperCase(),
    }));

  const updateNextSevenDays = () => {
    // console.log(dates , start ,dates.length);
    nextSevenDays = dates
      ?.slice(7 * start, Math.min(7 * (start + 1), dates.length))
      ?.map((date) => ({
        date: date.getDate(),
        dayName: date
          .toLocaleString("en-us", { weekday: "short" })
          .toUpperCase(),
      }));
    // console.log(nextSevenDays)
  };

  const handleNextMonth = () => {
    setStart(0);
    setMonthDelta(1 + monthDelta);

    if (monthDelta === 11) {
      setMonthDelta(0);
      setYearDelta(yearDelta + 1);
    }
    updateDates();
  };

  const handlePrevMonth = () => {
    setStart(0);
    setMonthDelta(monthDelta - 1);

    if (monthDelta === 0) {
      setMonthDelta(11);
      setYearDelta(yearDelta - 1);
    }
    updateDates();
  };

  const [selectedCity, setSelectedCity] = React.useState("Pune");

  useEffect(() => {
    updateNextSevenDays();
  }, []);

  const [slotData, setSlotData] = useState(null);

  useEffect(() => {
    const res = axiosInstance
      .get(
        `api/slot?start=${convertToUTC(nextSevenDays[0])}&end=${convertToUTC(
          nextSevenDays[nextSevenDays.length - 1]
        )}&city=${selectedCity}`
      )
      .then((res) => {
        setTaskLimit(res?.data?.data?.con)
        setMasterSlotData(res?.data?.data?.ticketsData)
        setSlotData(res?.data?.data?.response);
      })
      .catch((err) => {
        console.log(err);
        setSlotData(null);
      });
  }, [selectedCity, start]);

  function convertToUTC(day) {

    let start = new Date(
      Date.UTC(year + yearDelta, month + monthDelta, day?.date)
    ).getTime();
    return start;
  }

  function convertToIST(utcDateTime) {
    const utcMoment = moment(utcDateTime).utc();
    const istMoment = utcMoment.tz("Asia/Kolkata");
    return istMoment.format("HH:mm");
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "max-content",
        padding: "1.5rem",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.5rem",
          fontWeight: "700",
        }}
      >
        Slots
      </Typography>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: "1.5rem",
          backgroundColor: "#fff",
          marginTop: "1rem",
          overflowX: "auto",
        }}
      >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            marginBottom: "1rem",
            flexWrap: "wrap",
          }}
          spacing={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              flexWrap: "wrap",
            }}
          >
            <KeyboardArrowLeftIcon
              sx={{
                fontSize: "1rem",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handlePrevMonth}
            />

            <CalendarTodayIcon
              sx={{
                fontSize: "1rem",
                color: "#000",
                cursor: "pointer",
              }}
            />

            <Typography
              sx={{
                fontWeight: "500",
              }}
            >
              {dates[0].toLocaleString("en-us", { month: "short" }) +
                " " +
                dates[0].getFullYear()}
            </Typography>

            <KeyboardArrowRightIcon
              sx={{
                fontSize: "1rem",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handleNextMonth}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Select
              value={selectedCity}
              labelId="City"
              onChange={(e) => setSelectedCity(e.target.value)}
              sx={{
                height: "40px",
                backgroundColor: "#000",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
              }}
            >
              {cityItems.map((item, index) => (
                <MenuItem value={item.name} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>

        {slotData === null ? (
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "500",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            No slot data found
          </Typography>
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={
                nextSevenDays?.length === 7 ? "space-between" : "flex-start"
              }
              overflow="auto"
              sx={{
                backgroundColor: "#f6f6ff",
                padding: "1rem",
              }}
            >
              <KeyboardArrowLeftIcon
                sx={{
                  fontSize: "1rem",
                  color: "#000",
                  cursor: "pointer",
                  marginLeft: "1rem",
                }}
                onClick={() => handlePrevWeek()}
              />

              {nextSevenDays?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    paddingLeft: "2.85rem",
                    paddingRight: "2.85rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "1rem",
                    }}
                  >
                    {item.date},{item.dayName}
                  </Typography>
                </div>
              ))}
              <KeyboardArrowRightIcon
                sx={{
                  fontSize: "1rem",
                  color: "#000",
                  cursor: "pointer",
                  marginRight: "1rem",
                  marginLeft: nextSevenDays?.length !== 7 ? "auto" : "0",
                }}
                onClick={() => handleNextWeek()}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent={
                nextSevenDays?.length === 7 ? "space-between" : "flex-start"
              }
              sx={{
                marginTop: "1rem",
                overflowX: "auto",
              }}>
              <KeyboardArrowLeftIcon
                sx={{
                  fontSize: "1rem",
                  color: "#000",
                  cursor: "pointer",
                  marginLeft: "1rem",
                  alignSelf: "center",
                  scale: "1.5",
                }}
                onClick={() => handlePrevWeek()}
              />
              {nextSevenDays?.map((valu, index) => (
                <Stack
                  key={index}
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    margin: "0.5rem",
                  }}
                >
                  {console.log(MasterSlotData.find(entry => entry._id === slotData[index]?.date))}
                  {MasterSlotData.find(entry => entry._id === slotData[index]?.date) ?
                    <SlotCard
                      key={1}
                      master={true}
                      startTime={""}
                      endTime={""}
                      deliveryCount={MasterSlotData.find(entry => entry._id === slotData[index]?.date).deliverCount}
                      pickupCount={MasterSlotData.find(entry => entry._id === slotData[index]?.date).pickUpCount}
                      srCount={MasterSlotData.find(entry => entry._id === slotData[index]?.date).srCount}
                      taskLimit={taskLimit}
                      holiday={MasterSlotData.find(entry => entry._id === slotData[index]?.date)?.holiday}
                      weekoff={MasterSlotData.find(entry => entry._id === slotData[index]?.date)?.weekoff}
                    /> : <SlotCard
                      key={1}
                      master={true}
                      startTime={""}
                      endTime={""}
                      deliveryCount={0}
                      pickupCount={0}
                      srCount={0}
                      taskLimit={taskLimit}
                      holiday={MasterSlotData.find(entry => entry._id === slotData[index]?.date)?.holiday}
                      weekoff={MasterSlotData.find(entry => entry._id === slotData[index]?.date)?.weekoff}
                    />
                  }
                </Stack>
              ))}

              <KeyboardArrowRightIcon
                sx={{
                  fontSize: "1rem",
                  color: "#000",
                  cursor: "pointer",
                  alignSelf: "center",
                  marginRight: "1rem",
                  scale: "1.5",
                  marginLeft: nextSevenDays?.length !== 7 ? "auto" : "0",
                }}
                onClick={() => handleNextWeek()}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent={nextSevenDays?.length === 7 ? "space-between" : "flex-start"}
              sx={{
                marginTop: "1rem",
                overflowX: "auto",
              }}>
              <KeyboardArrowLeftIcon
                sx={{
                  fontSize: "1rem",
                  color: "#000",
                  cursor: "pointer",
                  marginLeft: "1rem",
                  alignSelf: "center",
                  scale: "1.5",
                }}
                onClick={() => handlePrevWeek()}
              />

              {nextSevenDays?.map((valu, index) => (
                <Stack
                  key={index}
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    margin: "0.5rem",
                  }}>
                  {slotData !== null &&
                    slotData[index]?.is_holiday === false &&
                    slotData[index]?.slots.map((slot, id) => (
                      <SlotCard
                        key={id}
                        startTime={convertToIST(slot.start_time)}
                        endTime={convertToIST(slot.end_time)}
                        // Note: Change the below values to slot.delivery_count, slot.pickup_count, slot.sr_count after the API is updated
                        deliveryCount={slot.delivery_count || 0}
                        pickupCount={slot.pickup_count || 0}
                        srCount={slot.sr_count || 0}
                        deliveryAvailable={slot.delivery_available}
                        pickupAvailable={slot.pickup_available}
                        srAvailable={slot.sr_available}
                      />
                    ))}
                  {slotData !== null &&
                    slotData[index]?.is_holiday === true && slotData[index]?.leave_name == "Week off" &&
                    slotData[index]?.slots.map((slot, id) => (
                      <SlotCard
                        key={id}
                        startTime={convertToIST(slot.start_time)}
                        endTime={convertToIST(slot.end_time)}
                        // Note: Change the below values to slot.delivery_count, slot.pickup_count, slot.sr_count after the API is updated
                        deliveryCount={slot.delivery_count || 0}
                        pickupCount={slot.pickup_count || 0}
                        srCount={slot.sr_count || 0}
                        deliveryAvailable={slot.delivery_available}
                        pickupAvailable={slot.pickup_available}
                        srAvailable={slot.sr_available}
                      />
                    ))}
                  {slotData !== null &&
                    slotData[index]?.is_holiday === true && slotData[index]?.leave_name != "Week off" && (
                      <HolidayCard name={slotData[index]?.leave_name} />
                    )}
                </Stack>
              ))}

              <KeyboardArrowRightIcon
                sx={{
                  fontSize: "1rem",
                  color: "#000",
                  cursor: "pointer",
                  alignSelf: "center",
                  marginRight: "1rem",
                  scale: "1.5",
                  marginLeft: nextSevenDays?.length !== 7 ? "auto" : "0",
                }}
                onClick={() => handleNextWeek()}
              />
            </Stack>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Slot;
