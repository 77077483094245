import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit';
import Select from "./SelectRole";
import { Col } from 'react-grid-system';
import axiosInstance from '../../axios/AxiosInstance';
import { Stack, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Card = styled.div`
    width:100%;
    background-color:white;
    padding:10px 20px;
    height:100%;
    border-radius:10px;
`
const MyButton = styled.div`
    padding:3px;
    padding-left:10px;
    padding-right:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:12px;
    font-weight:700;
    border:1px solid black;
    border-radius:18px;
`
const SelDiv = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:20px;

`
const Permis = styled.div`
    height:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:5px;
    font-size:15px;
    font-weight:500;
    border: 1px solid grey;
    border-radius:5px;
`
const ManagerItems = [
    {
        value: 'one',
        text: 'one'
    },
    {
        value: 'two',
        text: 'two'
    }, {
        value: 'three',
        text: 'three'
    },
    {
        value: 'four',
        text: 'four'
    },
];
const TaskItems = [
    {
        value: 'one',
        text: 'one'
    },
    {
        value: 'two',
        text: 'two'
    }, {
        value: 'three',
        text: 'three'
    },
    {
        value: 'four',
        text: 'four'
    },
];
const RangerItems = [
    {
        value: 'one',
        text: 'one'
    },
    {
        value: 'two',
        text: 'two'
    }, {
        value: 'three',
        text: 'three'
    },
    {
        value: 'four',
        text: 'four'
    },
];

const RoleCard = ({ data, update, localupdate, setLocalupdate }) => {

    const userrole = JSON.parse(localStorage.getItem('wh_tk'))?.data?.role;
    const userId = JSON.parse(localStorage.getItem('wh_tk'))?.data?._id;
    const outArr = JSON.parse(localStorage.getItem('wh_tk'))?.data?.out;

    const navigate = useNavigate();

    const [idmatch, setIdmatch] = useState(false);

    const handleUpdate = async (roleId) => {

        const body = {
            roleId: roleId
        }

        try {
            const res = await axiosInstance.post(`api/user/${userId}`, body)
            // console.log(res);
            if (res.status === 200) {
                const mydata = { data: res.data.data[0] };
                localStorage.setItem("wh_tk", JSON.stringify(mydata));
                setLocalupdate(!localupdate);
                alert("Role Updated successfully");
                window.location.reload();
            }
        }
        catch (err) {
            alert(err);
        }
    }

    useEffect(() => {
        if (userrole) {
            const roleId = userrole[0]._id;
            if (data._id === roleId) {
                setIdmatch(true);
            }
            else {
                setIdmatch(false);
            }
        }
    }, [])

    const handleEdit = (role, id) => {
        navigate("/editrole", { state: { role: role, id: id } });
    }

    return (
        <Col style={{ textAlign: "center", marginBottom: "20px" }} sm={6}>
            <Card
                style={{
                    border: idmatch ? "3px solid black" : ""
                }}
            >
                <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "20px", width: "100%", textAlign: "start" }}>
                    {data.name}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <div style={{ fontSize: "14px", fontWeight: "500" }}>USER PERMISSIONS</div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <MyButton style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => { handleEdit(data, data._id) }}><EditIcon fontSize='small' />&nbsp;Edit</MyButton>
                        {/* <MyButton><AddIcon />&nbsp;Add</MyButton> */}
                    </div>
                </div>
                {
                    data.permissions.map((curr, index) => {

                        return (
                            <>
                                <SelDiv>
                                    <div style={{ fontWeight: "500" }}>{data.out[index].name}</div>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        {curr.create === true && <Permis>Create</Permis>}
                                        {curr.read === true && <Permis>Read</Permis>}
                                        {curr.update === true && <Permis>Update</Permis>}
                                        {curr.delete === true && <Permis>Delete</Permis>}
                                    </Stack>
                                </SelDiv>
                            </>
                        );
                    })
                }
                {(idmatch === false) ?

                    (update &&
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                width: "100%",
                                marginBottom: "20px"
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    height: "40px",
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    marginTop: "30px",
                                    '&:hover': {
                                        backgroundColor: '#000',
                                        opacity: '0.8'
                                    }
                                }}
                                onClick={() => { handleUpdate(data._id) }}
                            >
                                Update role
                            </Button>
                        </Stack>
                    )
                    :
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: "100%",
                            marginBottom: "20px"
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                height: "40px",
                                backgroundColor: '#000',
                                color: '#fff',
                                marginTop: "30px",
                                '&:hover': {
                                    backgroundColor: '#000',
                                    opacity: '1'
                                }
                            }}
                        >
                            Selected
                        </Button>
                    </Stack>
                }
            </Card>
        </Col>
    )
}

export default RoleCard