// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#input_input__8qvUX {
    height: "48px";
    font-size: "14px";
}


.input_spinner_overlay__WuVGv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.input_spinner_container__O2Dsm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.input_FilterContainer__L2DWu {
    padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/new-page/input.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;;;AAGA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,0BAA0B;IAC1B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;;AAE1B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["#input {\n    height: \"48px\";\n    font-size: \"14px\";\n}\n\n\n.spinner_overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.1);\n    backdrop-filter: blur(5px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999;\n}\n\n.spinner_container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n\n}\n\n.FilterContainer {\n    padding-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `input_input__8qvUX`,
	"spinner_overlay": `input_spinner_overlay__WuVGv`,
	"spinner_container": `input_spinner_container__O2Dsm`,
	"FilterContainer": `input_FilterContainer__L2DWu`
};
export default ___CSS_LOADER_EXPORT___;
