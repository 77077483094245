import { Box, Stack, Typography, OutlinedInput, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AgentTable from './agenttable/AgentTable';
import './Agent.css';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import FilterAgent from "./agenttable/FilterAgent"
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import axios from 'axios';

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    minHeight: "40vh",
    overflow: 'scroll'
};

const Agent = () => {

    const [showAdd, setShowAdd] = useState(false);
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusFilter, setStatusFilter] = useState("All");
    
    function createData(curr) {
        // mode = mode ? "Online" : "Offline";
        return { ...curr };
    }

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('wh_tk'))?.data;
        if (userData) {
            const userRole = userData.role;
            const userType = userData.type;
            const outArr = userData.out;
            const userPermissions = userRole[0].permissions;
            if (userType == "admin") {
                setShowAdd(true);
            }
        }
    }, [])

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [agentName, setAgentName] = useState();
    const [agentPhone, setAgentPhone] = useState();
    const [statusOfButton, setStatusOfButton] = useState(false)

    const openPop = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }


    const getToken = async () => {
        try {

            const options = {
                method: 'POST',
                url: `${process.env.REACT_APP_OZONETEL_BASE_URL}ca_apis/CAToken/generateToken`,
                headers: { accept: 'application/json', apiKey: process.env.REACT_APP_OZONETEL_API_KEY, 'content-type': '*/*' },
                data: { userName: process.env.REACT_APP_OZONETEL_USER_NAME }
            };
            axios
                .request(options)
                .then(res => console.log(res.data))
                .catch(err => console.error(err));
        } catch (error) {

        }
    }



    const handleCreateClick = async () => {
        getToken();
        // if (agentName == agentPhone) {
        //     const options = {
        //         method: 'POST',
        //         url: `${process.env.REACT_APP_OZONETEL_BASE_URL}ca_apis/PhoneManageV2`,
        //         headers: {
        //             accept: 'application/json',
        //             apiKey: process.env.REACT_APP_OZONETEL_API_KEY,
        //             'Content-Type': 'application/json',
        //         },
        //         data: {
        //             "userName": process.env.REACT_APP_OZONETEL_USER_NAME,
        //             "action": "create",
        //             "phoneNumber": agentPhone,
        //             "phoneName": agentPhone,
        //             "skill": "Agent"
        //         }

        //     };
        //     await axios
        //         .request(options)
        //         .then(res => console.log(res.data))
        //         .catch(err => console.error(err));
        // } else {
        //     alert("Your Phone Number and Name should be Same ");
        // }
    }


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style1}>
                    <div className={"ContainerPopAgent"}>
                        <div className={"HeadingPopAgent"}>
                            Add Agent In Ozonetel
                        </div>
                        <div className={"CrossButtonAgent"}>
                            <ClearIcon
                                style={{
                                    cursor: "pointer",
                                    color: "black",
                                }}
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                    <div className='AddContainerAgent'>
                        <input onChange={e => setAgentName(e.target.value)} className='PhoneNameAgent' placeholder="Enter Phone Number" />
                        <input onChange={e => setAgentPhone(e.target.value)} className="PhoneNumberAgent" placeholder="Enter Phone Number" />
                        <button onClick={() => handleCreateClick()} className='CreateAgentButton'>Create Agent</button>
                    </div>
                </Box>
            </Modal>
            <Typography sx={{
                fontSize: '1.5rem',
                fontWeight: '700',
                marginTop: "30px"
            }} >Agents</Typography>
            <Box sx={{
                width: '100%',
                height: '100%',

                backgroundColor: '#fff',
                marginTop: '1rem'
            }} >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        marginBottom: '1rem',
                        flexWrap: 'wrap'
                    }} >
                    <FilterAgent agents={agents} setAgents={setAgents} setLoading={setLoading} createData={createData} loading={loading} statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
                    {/* <Stack>
                        <button
                            className='TatSubmitbutton'
                            onClick={() => openPop()}>
                            <AddIcon sx={{
                                marginRight: '0.5rem'
                            }} />
                            Add Agent in Ozonetel
                        </button>
                    </Stack> */}
                    {/* <Stack>
                        <button
                            className='TatSubmitbutton'
                            onClick={() => openPop()}>
                            <AddIcon sx={{
                                marginRight: '0.5rem'
                            }} />
                            Delete Agent  in Ozonetel
                        </button>
                    </Stack> */}
                    {showAdd &&
                        <Stack>
                            <button
                                className='TatSubmitbutton'
                                onClick={() => { navigate('/agent/add_agent') }}>
                                <AddIcon sx={{
                                    marginRight: '0.5rem'
                                }} />
                                Add Agent
                            </button>
                        </Stack>
                    }

                </Stack>
                <AgentTable
                    agents={agents}
                    setAgents={setAgents}
                    loading={loading}
                    setLoading={setLoading}
                    createData={createData}
                    statusFilter={statusFilter}
                />
            </Box>
        </div>
    )
}

export default Agent;