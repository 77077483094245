export const Delhi_wareHouse_did = "918071722439";
export const Banglore_wareHouse_did = "918071722438";
export const Mumbai_Warehouse_did = "918071722436";
export const Hyderabad_Warehouse_did = "918071722437";
export const Pune_Warehouse_did = "918071722144";

export const Delhi_wareHouse_Number = "9289322457";
export const Banglore_wareHouse_Number = "7428117898";
export const Mumbai_Warehouse_Number = "9599639661";
export const Hyderabad_Warehouse_Number = "7288094195";
export const Pune_Warehouse_Number = "8448687811";

export const Agent_did = "918071722145";
