import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import images from "../../assets/cart.svg";
import axiosInstance from "../../axios/AxiosInstance";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import moment from "moment-timezone";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  color: "black",
  overflowY: "scroll",
  height: "95vh"
};

export default function Product({ data, city }) {
  const [open, setOpen] = useState(false);
  let [InArray, setInArray] = useState([]);
  let [OutArray, setOutArray] = useState([]);
  const [OderData, setOderData] = useState([]);
  const [reload, setReload] = useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setstate] = useState(false);
  const [pickUpmaster, setPickUpMaster] = useState(true);
  const [masterDate, setmasterDate] = useState("");
  const admin = JSON.parse(localStorage.getItem("wh_tk")).data?.type
  const getData = async () => {
    let body = {
      id: data._id,
    };
    let getOderData;

    if (data?.subCategory == "Pickup and Refund") {
      let so_numbers = "";
      if (
        data.cf_so_number_1 &&
        data.cf_so_number_1 != "-" &&
        data.cf_so_number_1 != "."
      ) {
        so_numbers = data.cf_so_number_1;
      }
      if (
        data.cf_so_number_2 &&
        data.cf_so_number_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_so_number_2;
      }
      if (
        data.cf_so_number_3 &&
        data.cf_so_number_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_so_number_3;
      }
      if (
        data.cf_so_number_4 &&
        data.cf_so_number_4 !== "-" &&
        data.cf_so_number_4 != "."
      ) {
        so_numbers = data.cf_so_number_4;
      }
      if (
        data.cf_so_number_5 &&
        data.cf_so_number_5 != "-" &&
        data.cf_so_number_5 != "."
      ) {
        so_numbers = data.cf_so_number_5;
      }
      body.so_numbers = so_numbers;
      getOderData = await axiosInstance.post(
        `/api/order/get_pickup_data`,
        body
      );
    } else if (data?.subCategory == "Upgrade") {
      let so_numbers = "";
      let Upgrade_so = "";

      if (
        data.cf_delivery_so_1 &&
        data.cf_delivery_so_1 != "-" &&
        data.cf_delivery_so_1 != "."
      ) {
        Upgrade_so = data.cf_delivery_so_1;
      }
      if (
        data.cf_delivery_so_2 &&
        data.cf_delivery_so_2 != "-" &&
        data.cf_delivery_so_2 != "."
      ) {
        Upgrade_so = data.cf_delivery_so_2;
      }
      if (
        data.cf_delivery_so_3 &&
        data.cf_delivery_so_3 != "-" &&
        data.cf_delivery_so_3 != "."
      ) {
        Upgrade_so = data.cf_delivery_so_3;
      }

      if (
        data?.cf_so_number_1 &&
        data?.cf_so_number_1 != "-" &&
        data?.cf_so_number_1 != "."
      ) {
        so_numbers = data?.cf_so_number_1;
      }

      if (
        data.cf_so_number_2 &&
        data.cf_so_number_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_so_number_2;
      }
      if (
        data.cf_so_number_3 &&
        data.cf_so_number_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_so_number_3;
      }
      if (
        data.cf_so_number_4 &&
        data.cf_so_number_4 !== "-" &&
        data.cf_so_number_4 != "."
      ) {
        so_numbers = data.cf_so_number_4;
      }
      if (
        data.cf_so_number_5 &&
        data.cf_so_number_5 != "-" &&
        data.cf_so_number_5 != "."
      ) {
        so_numbers = data.cf_so_number_5;
      }
      body.order_id = Upgrade_so;
      let dataRes;
      if (Upgrade_so) {
        dataRes = await axiosInstance.post(`/api/order/order_id`, body);
      }

      delete body.order_id;
      body.so_numbers = so_numbers;
      let dataRes1;
      if (so_numbers) {
        dataRes1 = await axiosInstance.post(`/api/order/get_pickup_data`, body);
        setInArray(dataRes1.data.result);
        setOutArray(dataRes.data.result);
      }
    } else if (
      data?.subCategory == "Replace" ||
      data?.subCategory == "Repair"
    ) {
      let so_numbers = "";
      if (
        data?.cf_so_number_1 &&
        data?.cf_so_number_1 != "-" &&
        data?.cf_so_number_1 != "."
      ) {
        so_numbers = data.cf_so_number_1;
      }
      if (
        data.cf_so_number_2 &&
        data.cf_so_number_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_so_number_2;
      }
      if (
        data.cf_so_number_3 &&
        data.cf_so_number_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_so_number_3;
      }
      if (
        data.cf_so_number_4 &&
        data.cf_so_number_4 !== "-" &&
        data.cf_so_number_4 != "."
      ) {
        so_numbers = data.cf_so_number_4;
      }
      if (
        data.cf_so_number_5 &&
        data.cf_so_number_5 != "-" &&
        data.cf_so_number_5 != "."
      ) {
        so_numbers = data.cf_so_number_5;
      }
      body.so_numbers = so_numbers;
      const data1 = await axiosInstance.post(`/api/order/so_numbers`, body);

      setOutArray(
        data1.data.result?.filter(
          (item) => item?.client_Status === "Delivery Pending"
        )
      );
      setInArray(
        data1.data.result?.filter(
          (item) => item?.client_Status === "Replacement In"
        )
      );
    } else {
      let so_numbers = "";
      if (
        data.cf_delivery_so_1 &&
        data.cf_delivery_so_1 != "-" &&
        data.cf_so_number_1 != "."
      ) {
        so_numbers = data.cf_delivery_so_1;
      }
      if (
        data.cf_delivery_so_2 &&
        data.cf_delivery_so_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_delivery_so_2;
      }
      if (
        data.cf_delivery_so_3 &&
        data.cf_delivery_so_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_delivery_so_3;
      }
      body.order_id = so_numbers;

      if (body.order_id) {
        getOderData = await axiosInstance.post(`/api/order/order_id`, body);
      } else {
      }
    }

    if (getOderData?.data?.result) {
      setOderData(getOderData?.data?.result);
    } else {
      if (OutArray?.length > 0 || InArray?.length > 0) {
        setOderData(OutArray?.concat(InArray));
      }
    }
    const res = await axiosInstance.get("api/master");

    let PickUpBarcode = res.data.data.filter((item) => item.master == "PickUp Barcode")[0].rows
    PickUpBarcode = PickUpBarcode.find((newItem) => { return newItem.city == city })
    let ZohoBooksDeliveryDate = res.data.data.filter((item) => item.master == "Zoho Books Delivery Date")[0].rows
    ZohoBooksDeliveryDate = ZohoBooksDeliveryDate.find((newItem) => { return newItem.city == city })
    setmasterDate(ZohoBooksDeliveryDate.date)
    setPickUpMaster(PickUpBarcode.status)
    setstate(true)
  };

  const update = async (id, status, barcode) => {
    const userId = JSON.parse(localStorage.getItem("wh_tk")).data?._id;
    const auditTrailId = data.auditTrailId;

    if (data.category == "Order") {
      await axiosInstance.post(`/api/order`, {
        id,
        status,
        admin: true,
        userId,
        auditTrailId,
        barcode,
      });
    } else {
      await axiosInstance.post(`/api/order/pickUp`, {
        id,
        status,
        admin: true,
        userId,
        auditTrailId,
        barcode
      });
    }
    setReload(Math.random());
  };

  function checkDates(esd, orderDate) {
    if (pickUpmaster) {
      let format = moment(masterDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      const date1Obj = new Date(format);
      const date2Obj = esd ? new Date(esd) : null;
      const date3Obj = orderDate ? new Date(orderDate) : null;

      // Check if date1 is valid
      if (isNaN(date1Obj.getTime())) {
        throw new Error("The first date is not a valid date.");
      }

      // Check if date1 is later than the valid date(s)
      const isLaterThanDate2 = !date2Obj || date1Obj.getTime() > date2Obj.getTime();
      const isLaterThanDate3 = !date3Obj || date1Obj.getTime() > date3Obj.getTime();
      if (isLaterThanDate2 && isLaterThanDate3) {
        return false;
      } else { return true }
    } else {
      console.log("else");
      return false
    }
  }

  useEffect(() => {
    if (open == true && Object.keys(data).length) {
      getData();
    }
  }, [open, reload, data]);

  return (
    <div>
      <img
        style={{ width: "27px" }}
        src={images}
        onClick={handleOpen}
        alt="SVG"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {state && <>
            <h1 style={{ marginBottom: "-2px" }}>Product</h1>
            {["Pickup and Refund"].includes(data.subCategory) ||
              ["Order"].includes(data.category) ? (
              <>
                <div>
                  {OderData?.map((data, index) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginTop: "15px", display: "flex" }}>
                            <div>
                              {checkDates(data?.esd, data?.OrderEsd) && <QrCodeScannerIcon />}
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                              <p key={index}> {data?.Product_name}</p>
                              <p key={index}> {data?.barcode}</p>
                            </div>
                          </div>
                          <div style={{ gap: "5px" }}>
                            <UnpublishedIcon
                              sx={{
                                fontSize: "35px",
                                color: data.status == 3 ? "red" : "gray",
                              }}
                              onClick={() => {
                                if (admin == "admin") {
                                  update(data._id, 3, data?.barcode);
                                }
                              }}
                            />
                            <TaskAltIcon
                              sx={{
                                fontSize: "35px",
                                color: data.status == 2 ? "green" : "gray",
                              }}
                              onClick={() => {
                                if (admin == "admin") {
                                  update(data._id, 2, data?.barcode);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <div>
                <h4 style={{ marginBottom: "-12px" }}>Product In</h4>
                {InArray?.map((data, index) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginTop: "15px" }}>
                          <p key={index}> {data?.Product_name}</p>
                          <p key={index}> {data?.barcode}</p>
                        </div>
                        <div style={{ gap: "5px" }}>
                          <UnpublishedIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 3 ? "red" : "gray",
                            }}
                            onClick={() => {
                              if (admin == "admin") {
                                update(data._id, 3, data?.barcode);
                              }
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 2 ? "green" : "gray",
                            }}
                            onClick={() => {
                              if (admin == "admin") {
                                update(data._id, 2, data?.barcode);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
                <h4 style={{ marginTop: "10px", marginBottom: "-12px" }}>Product out</h4>
                {OutArray?.map((data, index) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginTop: "15px" }}>
                          <p key={index}> {data?.Product_name}</p>
                          <p key={index}> {data?.barcode}</p>
                        </div>
                        <div style={{ gap: "5px" }}>
                          <UnpublishedIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 3 ? "red" : "gray",
                            }}
                            onClick={() => {
                              if (admin == "admin") {
                                update(data._id, 3, data?.barcode);
                              }
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 2 ? "green" : "gray",
                            }}
                            onClick={() => {
                              if (admin == "admin") {
                                update(data._id, 2, data?.barcode);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </>}
        </Box>
      </Modal>
    </div>
  );
}
