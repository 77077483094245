import { Box, Stack, Typography, OutlinedInput, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import MessageTable from "./messagetable/MessageTable";
import "./Message.css";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import FilterMessage from "./messagetable/FilterMessage";
import wh_export_img from "../../assets/wh_export_img.png";
import axiosInstance from "../../axios/AxiosInstance";
import MessageDateModal from "./messagetable/messageDateModal";

const Message = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportData, setExportData] = useState("");

  const [flag, setFlag] = useState(true);
  const [filters, setFilters] = useState("");
  const [cityname, setCityName] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [tableData, setTableData] = useState(0);
  const [lastPage, setLastPage] = useState(true);
  const [finalPage, setFinalPage] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [isCityFilterApplied, setIsCityFilterApplied] = useState(false);
  const anchorRef = React.useRef(null);

  function createData(curr) {
    // mode = mode ? "Online" : "Offline";
    return { ...curr };
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
    if (userData) {
      const userRole = userData.role;
      const outArr = userData.out;
      const userPermissions = userRole[0].permissions;

      userPermissions.map((curr, index) => {
        if (outArr[index].key === "message" && curr.create === true) {
          setShowAdd(true);
        }
      });
    }
    // console.log(userData);
  }, []);
  const exportTicket = async () => {
    if (exportData) {
      // console.log(exportData);
      var newWindow = window.open(`${process.env.REACT_APP_API_URL}${exportData}download=csv`);
    } else {
      var newWindow = window.open(
        `${process.env.REACT_APP_API_URL}api/ticket/sms/record?download=csv`
      );
    }
  };
  const navigate = useNavigate();
  return (
    <div>
      <Typography
        sx={{
          fontSize: "1.5rem",
          fontWeight: "700",
          marginTop: "30px",
        }}
      >
        Messages
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          marginTop: "1rem",
        }}
      >
        <Stack
          display="flex"
          direction={"row"}
          justifyContent="flex-start"
          gap="15px"
          sx={{
            marginBottom: "1rem",
            flexWrap: "wrap",
          }}
        >
          <FilterMessage
            messages={messages}
            setMessages={setMessages}
            setLoading={setLoading}
            createData={createData}
            exportData={setExportData}
            loading={loading}
            setCityName={setCityName}
            cityname={cityname}
            setTotalPage={setTotalPage}
            setIsCityFilterApplied={setIsCityFilterApplied}
            setPage={setPage}
          />
          <MessageDateModal
            flag={flag}
            setFlag={setFlag}
            city={cityname}
            setTableData={setTableData}
            filters={filters}
            setFilters={setFilters}
            setTotalPage={setTotalPage}
            page={page}
            limit={limit}
            setPage={setPage}
            loading={loading}
            setLoading={setLoading}
            setStart={setStart}
            setEnd={setEnd}
            setLastPage={setLastPage}
            setFinalPage={setFinalPage}
            setIsDateFilterApplied={setIsDateFilterApplied}
            setMessages={setMessages}
          />
          <Button
            variant="contained"
            sx={{
              width: "120px",
              height: "40px",
              // marginLeft:"20px",
              backgroundColor: "#000",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#000",
                opacity: "0.8",
              },
              "@media (max-width: 785px)": {
                fontSize: "0.6rem",
                width: "100px",
                height: "40px",
              },
            }}
            onClick={exportTicket}
          >
            <img
              style={{
                marginRight: "0.5rem",
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem",
                },
              }}
              src={wh_export_img}
              className="wh_export_img"
              alt="export img"
            />
            Export
          </Button>
        </Stack>

        {showAdd && (
          <Stack>
            <Button
              variant="contained"
              sx={{
                width: "200px",
                backgroundColor: "#000",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
              }}
              onClick={() => {
                navigate("/message/add_message");
              }}
            >
              <AddIcon
                sx={{
                  marginRight: "0.5rem",
                }}
              />
              Add Message
            </Button>
          </Stack>
        )}
        {/* </Stack> */}
        <MessageTable
          messages={messages}
          setMessages={setMessages}
          loading={loading}
          setLoading={setLoading}
          createData={createData}
          totalPage={totalPage}
          start={start}
          end={end}
          filters={filters}
          isDateFilterApplied={isDateFilterApplied}
          setTotalPage={setTotalPage}
          setCityName={setCityName}
          cityname={cityname}
          isCityFilterApplied={isCityFilterApplied}
        />
      </Box>
    </div>
  );
};

export default Message;
