import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import images from "../../assets/cart.svg";
import axiosInstance from "../../axios/AxiosInstance";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { getDetails_refurb_tracker_out, getDetails_refurb_tracker_in, getDetails_internal_transfer_tracker_in, getDetails_internal_transfer_tracker_out, getDetails_purchase_recieve_tracker_pick } from "../../helpers/odoo"
import moment from "moment-timezone";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  color: "black",
  overflowY: "scroll",
  height: "95vh"
};

export default function Product({ data, city }) {

  const [open, setOpen] = useState(false);
  let [InArray, setInArray] = useState([]);
  let [OutArray, setOutArray] = useState([]);
  const [OderData, setOderData] = useState([]);
  const [reload, setReload] = useState(1);
  const handleOpen = () => setOpen(true);
  const [qtyInput, setQtyInput] = useState(0);
  const handleClose = () => setOpen(false);
  const [state, setstate] = useState(false);
  const [pickUpmaster, setPickUpMaster] = useState(true);
  const [masterDate, setmasterDate] = useState("");
  const admin = JSON.parse(localStorage.getItem("wh_tk")).data?.type

  const getData = async () => {
    let body = {
      id: data._id,
    };
    let getOderData;


    if (data?.subCategory == "Refurb Transfer") {
      if (data?.cf_odoo_id) {
        const dataa = await getDetails_refurb_tracker_out(data?.cf_odoo_id);
        setOutArray(dataa?.result)
      }
    }
    if (data?.subCategory == "Refurb Transfer") {
      if (data?.cf_odoo_id) {
        const dataa = await getDetails_refurb_tracker_in(data?.cf_odoo_id);
        setInArray(dataa?.result)
      }
    }

    if (data?.subCategory == "Stock Transfer") {
      if (data?.cf_odoo_id) {
        const dataa = await getDetails_internal_transfer_tracker_out(data?.cf_odoo_id);
        setOutArray(dataa)
      }
    }
    if (data?.subCategory == "Stock Transfer") {
      if (data?.cf_odoo_id) {
        const dataa = await getDetails_internal_transfer_tracker_in(data?.cf_odoo_id);
        setInArray(dataa)
      }
    }
    if (data.subCategory == 'PO Payment') {
      try {
        const dataa = await getDetails_purchase_recieve_tracker_pick(data?.cf_po_no);
        console.log((dataa));
      } catch (error) {

      }
    }



    if (data?.subCategory == "Pickup and Refund") {
      let so_numbers = "";
      if (
        data.cf_so_number_1 &&
        data.cf_so_number_1 != "-" &&
        data.cf_so_number_1 != "."
      ) {
        so_numbers = data.cf_so_number_1;
      }
      if (
        data.cf_so_number_2 &&
        data.cf_so_number_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_so_number_2;
      }
      if (
        data.cf_so_number_3 &&
        data.cf_so_number_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_so_number_3;
      }
      if (
        data.cf_so_number_4 &&
        data.cf_so_number_4 !== "-" &&
        data.cf_so_number_4 != "."
      ) {
        so_numbers = data.cf_so_number_4;
      }
      if (
        data.cf_so_number_5 &&
        data.cf_so_number_5 != "-" &&
        data.cf_so_number_5 != "."
      ) {
        so_numbers = data.cf_so_number_5;
      }
      body.so_numbers = so_numbers;
      getOderData = await axiosInstance.post(
        `/api/order/get_pickup_data`,
        body
      );
    } else if (data?.subCategory == "Upgrade") {
      let so_numbers = "";
      let Upgrade_so = "";

      if (
        data.cf_delivery_so_1 &&
        data.cf_delivery_so_1 != "-" &&
        data.cf_delivery_so_1 != "."
      ) {
        Upgrade_so = data.cf_delivery_so_1;
      }
      if (
        data.cf_delivery_so_2 &&
        data.cf_delivery_so_2 != "-" &&
        data.cf_delivery_so_2 != "."
      ) {
        Upgrade_so = data.cf_delivery_so_2;
      }
      if (
        data.cf_delivery_so_3 &&
        data.cf_delivery_so_3 != "-" &&
        data.cf_delivery_so_3 != "."
      ) {
        Upgrade_so = data.cf_delivery_so_3;
      }

      if (
        data?.cf_so_number_1 &&
        data?.cf_so_number_1 != "-" &&
        data?.cf_so_number_1 != "."
      ) {
        so_numbers = data?.cf_so_number_1;
      }

      if (
        data.cf_so_number_2 &&
        data.cf_so_number_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_so_number_2;
      }
      if (
        data.cf_so_number_3 &&
        data.cf_so_number_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_so_number_3;
      }
      if (
        data.cf_so_number_4 &&
        data.cf_so_number_4 !== "-" &&
        data.cf_so_number_4 != "."
      ) {
        so_numbers = data.cf_so_number_4;
      }
      if (
        data.cf_so_number_5 &&
        data.cf_so_number_5 != "-" &&
        data.cf_so_number_5 != "."
      ) {
        so_numbers = data.cf_so_number_5;
      }
      body.order_id = Upgrade_so;
      let dataRes;

      console.log(body, "Data");


      if (Upgrade_so) {

        dataRes = await axiosInstance.post(`/api/order/order_id`, body);

      }

      // 745127
      // 745631

      delete body.order_id;
      body.so_numbers = so_numbers;
      let dataRes1;
      if (so_numbers) {
        dataRes1 = await axiosInstance.post(`/api/order/get_pickup_data`, body);
        setInArray(dataRes1.data.result);
        setOutArray(dataRes.data.result);
      }
    } else if (
      data?.subCategory == "Replace" ||
      data?.subCategory == "Repair"
    ) {
      let so_numbers = "";
      if (
        data?.cf_so_number_1 &&
        data?.cf_so_number_1 != "-" &&
        data?.cf_so_number_1 != "."
      ) {
        so_numbers = data.cf_so_number_1;
      }
      if (
        data.cf_so_number_2 &&
        data.cf_so_number_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_so_number_2;
      }
      if (
        data.cf_so_number_3 &&
        data.cf_so_number_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_so_number_3;
      }
      if (
        data.cf_so_number_4 &&
        data.cf_so_number_4 !== "-" &&
        data.cf_so_number_4 != "."
      ) {
        so_numbers = data.cf_so_number_4;
      }
      if (
        data.cf_so_number_5 &&
        data.cf_so_number_5 != "-" &&
        data.cf_so_number_5 != "."
      ) {
        so_numbers = data.cf_so_number_5;
      }
      body.so_numbers = so_numbers;
      const data1 = await axiosInstance.post(`/api/order/so_numbers`, body);

      setOutArray(
        data1.data.result?.filter(
          (item) => item?.client_Status === "Delivery Pending"
        )
      );
      setInArray(
        data1.data.result?.filter(
          (item) => item?.client_Status === "Replacement In"
        )
      );
    } else {
      let so_numbers = "";
      if (
        data.cf_delivery_so_1 &&
        data.cf_delivery_so_1 != "-" &&
        data.cf_so_number_1 != "."
      ) {
        so_numbers = data.cf_delivery_so_1;
      }
      if (
        data.cf_delivery_so_2 &&
        data.cf_delivery_so_2 != "-" &&
        data.cf_so_number_2 != "."
      ) {
        so_numbers = data.cf_delivery_so_2;
      }
      if (
        data.cf_delivery_so_3 &&
        data.cf_delivery_so_3 != "-" &&
        data.cf_so_number_3 != "."
      ) {
        so_numbers = data.cf_delivery_so_3;
      }
      body.order_id = so_numbers;


      if (body.order_id) {
        getOderData = await axiosInstance.post(`/api/order/order_id`, body);


      } else {
        const newbody = {
          id: body.id,
          order_id: "."
        },
          getOderData = await axiosInstance.post(`/api/order/order_id`, newbody);
        setOderData(getOderData?.data?.result);
      }
    }
    if (getOderData?.data?.result) {
      setOderData(getOderData?.data?.result);
    } else {
      if (OutArray?.length > 0 || InArray?.length > 0) {
        setOderData(OutArray?.concat(InArray));
      }
    }
    const res = await axiosInstance.get("api/master");

    let PickUpBarcode = res.data.data.filter((item) => item.master == "PickUp Barcode")[0].rows
    PickUpBarcode = PickUpBarcode.find((newItem) => { return newItem.city == city })
    let ZohoBooksDeliveryDate = res.data.data.filter((item) => item.master == "Zoho Books Delivery Date")[0].rows
    ZohoBooksDeliveryDate = ZohoBooksDeliveryDate.find((newItem) => { return newItem.city == city })
    setmasterDate(ZohoBooksDeliveryDate.date);
    setPickUpMaster(PickUpBarcode.status)
    setstate(true)
  };

  const update = async (id, status, barcode) => {
    const userId = JSON.parse(localStorage.getItem("wh_tk")).data?._id;
    const auditTrailId = data.auditTrailId;

    if (data.category == "Order") {
      await axiosInstance.post(`/api/order`, {
        id,
        status,
        admin: true,
        userId,
        auditTrailId,
        barcode,
      });
    } else {
      await axiosInstance.post(`/api/order/pickUp`, {
        id,
        status,
        admin: true,
        userId,
        auditTrailId,
        barcode
      });
    }
    setReload(Math.random());
  };

  function checkDates(esd, orderDate) {
    if (pickUpmaster) {
      let format = moment(masterDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      const date1Obj = new Date(format);
      const date2Obj = esd ? new Date(esd) : null;
      const date3Obj = orderDate ? new Date(orderDate) : null;

      // Check if date1 is valid
      if (isNaN(date1Obj.getTime())) {
        throw new Error("The first date is not a valid date.");
      }

      // Check if date1 is later than the valid date(s)
      const isLaterThanDate2 = !date2Obj || date1Obj.getTime() > date2Obj.getTime();
      const isLaterThanDate3 = !date3Obj || date1Obj.getTime() > date3Obj.getTime();
      if (isLaterThanDate2 && isLaterThanDate3) {
        return false;
      } else { return true }
    } else {
      console.log("else");
      return false
    }
  }

  useEffect(() => {
    if (open == true && Object.keys(data).length) {
      getData();
    }
  }, [open, reload, data]);


  const handleQtyUpdate = async (item) => {
    await axiosInstance.post(`/api/order`, {
      "qty": qtyInput,
      "id": item?.deliveryId,
      "status": 2,
      "_id": item?._id
    })
      .then(() => {
        toast.success("Done");
        handleClose()
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        handleClose()
      });
  }

  return (
    <div>
      <img
        style={{ width: "27px" }}
        src={images}
        onClick={handleOpen}
        alt="SVG"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {state && <>
            <h1 style={{ marginBottom: "-2px" }}>Product</h1>
            {["Pickup and Refund"].includes(data.subCategory) ||
              ["Order"].includes(data.category) || ["PO Payment"].includes(data.subCategory) ? (
              <>
                <div>
                  {OderData?.map((data, index) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <div style={{ marginTop: "15px", display: "flex" }}>
                            <div>
                              {checkDates(data?.esd, data?.OrderEsd) && <QrCodeScannerIcon />}
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                              <p key={index}> {data?.Product_name}</p>
                              <p key={index}> {data?.barcode}</p>
                              {data.subCategory == "PO Payment" && <p key={index} >Product Qty : {data?.product_Qty}</p>}
                            </div>
                          </div>
                          {data.subCategory == "PO Payment" && <div style={{ display: 'flex', gap: "8px", justifyContent: 'center', alignItems: 'center' }}>
                            {/* <p key={index} style={{ fontSize: "12px" }}>Product Qty : {data?.product_Qty}</p> */}
                            <input style={{ maxWidth: '50px', borderRadius: "10px", padding: "2px" }} defaultValue={data?.agentMarkqty ? data?.agentMarkqty : qtyInput} type="number" onChange={(e) => setQtyInput(e.target.value)} />
                            <br />
                            <button style={{ padding: '2px 5px', background: "#000", color: "#fff", borderRadius: "5px" }} onClick={() => handleQtyUpdate(data)}>U</button>
                            <p key={index}> {data?.barcode}</p>
                            {/* <p key={index}> {data?}</p> */}

                          </div>}
                          <div style={{ gap: "5px" }}>
                            <UnpublishedIcon
                              sx={{
                                fontSize: "35px",
                                color: data.status == 3 ? "red" : "gray",
                              }}
                              onClick={() => {
                                if (admin == "admin") {
                                  update(data._id, 3, data?.barcode);
                                }
                              }}
                            />
                            <TaskAltIcon
                              sx={{
                                fontSize: "35px",
                                color: data.status == 2 ? "green" : "gray",
                              }}
                              onClick={() => {
                                if (admin == "admin") {
                                  update(data._id, 2, data?.barcode);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <div>
                {data?.subCategory === "Refurb Transfer" || data?.subCategory === "Stock Transfer" ? (
                  <>
                    {data?.status === "Material Receive Pending" && (
                      <>
                        <h4 style={{ marginBottom: "-12px" }}>Product In</h4>
                        {InArray?.map((data, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ marginTop: "15px" }}>
                              <p>
                                {data?.Product_name ? data?.Product_name : data["Product name"]}
                              </p>
                              <p>{data?.Barcode ? data?.Barcode : data?.barcode}</p>
                            </div>
                            <div style={{ gap: "5px" }}>
                              <UnpublishedIcon
                                sx={{
                                  fontSize: "35px",
                                  color: data.status == 3 ? "red" : "gray",
                                }}
                                onClick={() => {
                                  if (admin === "admin") {
                                    update(data._id, 3, data?.barcode);
                                  }
                                }}
                              />
                              <TaskAltIcon
                                sx={{
                                  fontSize: "35px",
                                  color: data.status == 2 ? "green" : "gray",
                                }}
                                onClick={() => {
                                  if (admin === "admin") {
                                    update(data._id, 2, data?.barcode);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {data?.status === "Material Dispatch Pending" && (
                      <>
                        <h4 style={{ marginTop: "10px", marginBottom: "-12px" }}>Product Out </h4>
                        {OutArray?.map((data, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ marginTop: "15px" }}>
                              <p>
                                {data?.Product_name ? data?.Product_name : data["Product name"]}
                              </p>
                              <p>{data?.Barcode ? data?.Barcode : data?.barcode}</p>
                            </div>
                            <div style={{ gap: "5px" }}>
                              <UnpublishedIcon
                                sx={{
                                  fontSize: "35px",
                                  color: data.status == 3 ? "red" : "gray",
                                }}
                                onClick={() => {
                                  if (admin === "admin") {
                                    update(data._id, 3, data?.barcode);
                                  }
                                }}
                              />
                              <TaskAltIcon
                                sx={{
                                  fontSize: "35px",
                                  color: data.status == 2 ? "green" : "gray",
                                }}
                                onClick={() => {
                                  if (admin === "admin") {
                                    update(data._id, 2, data?.barcode);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  // Normal rendering if subCategory is not "Refurb Transfer"
                  <>
                    <h4 style={{ marginBottom: "-12px" }}> Product In</h4>
                    {InArray?.map((data, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginTop: "15px" }}>
                          <p>
                            {data?.Product_name ? data?.Product_name : data["Product name"]}
                          </p>
                          <p>{data?.Barcode ? data?.Barcode : data?.barcode}</p>
                        </div>
                        <div style={{ gap: "5px" }}>
                          <UnpublishedIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 3 ? "red" : "gray",
                            }}
                            onClick={() => {
                              if (admin === "admin") {
                                update(data._id, 3, data?.barcode);
                              }
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 2 ? "green" : "gray",
                            }}
                            onClick={() => {
                              if (admin === "admin") {
                                update(data._id, 2, data?.barcode);
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}

                    <h4 style={{ marginTop: "10px", marginBottom: "-12px" }}> Product Out</h4>
                    {OutArray?.map((data, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ marginTop: "15px" }}>
                          {console.log(data, "Data From The CF")}
                          <p>
                            {data?.Product_name ? data?.Product_name : data["Product name"]}
                          </p>
                          <p>{data?.Barcode ? data?.Barcode : data?.barcode}</p>
                        </div>
                        <div style={{ gap: "5px" }}>
                          <UnpublishedIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 3 ? "red" : "gray",
                            }}
                            onClick={() => {
                              if (admin === "admin") {
                                update(data._id, 3, data?.barcode);
                              }
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              fontSize: "35px",
                              color: data.status == 2 ? "green" : "gray",
                            }}
                            onClick={() => {
                              if (admin === "admin") {
                                update(data._id, 2, data?.barcode);
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>

            )}
          </>}
        </Box>
      </Modal>
    </div>
  );
}
