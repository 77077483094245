import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import axiosInstance from "../../axios/AxiosInstance";

export default function AuditChanges({
  auditStyle,
  setAuditStyle,
  auditId,
  flag,
}) {
  const [auditData, setAuditData] = useState([]);
  const [userData, setUserData] = useState([]);
  const getData = async () => {
    
    try {
      const res = await axiosInstance.get(`api/ticket/audit/${auditId}`);
      setAuditData(await filter(res.data.data[0].auditTrail));
      setUserData(res.data.data[0].user);
    } catch (error) {
      console.error(error);
    }
  };
  const filter = async (data) => {
    let result = [];
    for (let i = 0; i < data?.length; i++) {
      const item = data[i];
      if (
        item?.oldData?.hasOwnProperty("agentId") &&
        item?.newData?.hasOwnProperty("agentId")
      ) {
        let oldDataAgentId
        let newDataAgentId
        if (item.oldData.agentId) {
          oldDataAgentId = await axiosInstance.get(
            `api/user/${item.oldData.agentId}`
          );
        }

        if (item.newData.agentId) {
          newDataAgentId = await axiosInstance.get(
            `api/user/${item.newData.agentId}`
          );
        }

        item.oldData.agentId = oldDataAgentId?.data?.data?.name;
        item.newData.agentId = newDataAgentId?.data?.data?.name;
        result.push(item);
      } else {
        result.push(item);
      }
    }
    return result;
  };
  const findDate = (val) => {
    if (val === null) return null;
    if (val === undefined) return null;
    const date = new Date(val);
    return date?.toISOString().split("T")[0];
  };

  const findTime = (val) => {
    if (val === null) return null;
    const date = new Date(val);
    return date.toTimeString().split(":").slice(0, 2).join(":");
  };

  useEffect(() => {
    getData();
  }, [auditId, flag]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "700",
          }}
        >
          Name / Date
        </Typography>
        <div style={{ width: "40%" }}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "700",
            }}
          >
            Changes
          </Typography>
        </div>
      </Stack>
      <Stack
        direction="column"
        spacing={3}
        sx={{
          width: "100%",
          overflowY: "scroll",
        }}
      >
        {auditData.map((curr) => {
          let name, email;
          userData.map((user) => {
            if (curr.userId === user._id) {
              name = user.name;
              email = user.email;
            }
          });

          const changesArr = [];
          for (const key in curr.oldData) {
            if (key === "scheduledDate" || key === "deliverDate" || key === "doneDate") {
              const oldDate = findDate(curr.oldData[key]);
              const oldTime = findTime(curr.oldData[key]);
              const newData = findDate(curr.newData[key]);
              const newTime = findTime(curr.newData[key]);

              const newobj = {
                keychange: `${key} :`,
                change: `${oldDate} ${oldTime} -> ${newData} ${newTime}`,
              };
              changesArr.push(newobj);
            } else {
              const newobj = {
                keychange: `${key} :`,
                change: `${curr.oldData[key]} -> ${curr.newData[key]}`,
              };
              changesArr.push(newobj);
            }
          }

          const displayDate = findDate(curr.createdAt);
          const time = findTime(curr.createdAt);

          return (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Stack direction="column" spacing={0.5}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                    }}
                  >
                    {email}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                    }}
                  >{`${displayDate}  ${time}`}</Typography>
                </Stack>

                <Stack
                  direction="column"
                  spacing={0.5}
                  sx={{
                    width: "40%",
                  }}
                >
                  {console.log(changesArr)}
                  {changesArr.map((item) => {
                    return (
                      <Stack direction="column" spacing={0.5}>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "500",
                          }}
                        >
                          {item.keychange}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {item.change}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </>
          );
        })}
      </Stack>
    </>
  );
}

/* else if(column.id === 'edit')
                  {
                    if(isAgent === true)
                    {
                      return(
                              <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth, backgroundColor: '#F2F4FF' }}
                              >
                                  {column.label}
                              </TableCell>
                      )
                    }
                  } */
