import React from 'react'
import styled from 'styled-components'
import Input from "../../components/input/Input"
import AddIcon from '@mui/icons-material/Add';
import MultiSelect from "../../components/select/MultiSelect"
import { useState } from 'react';
import axiosInstance from '../../axios/AxiosInstance';
import Loading from '../../components/loading/Loading';
import { useEffect } from 'react';
import { onResetArrayInput, onResetStringInput } from '../../helpers/ResetInput';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`
const Main = styled.div`
    width:50%;
    ${'' /* height:85%; */}
    background-color:white;
    padding:5%;
    display:flex;
    flex-direction:column;
    align-items:center;
`
const Heading = styled.div`
    color:black;
    font-size:36px;
    font-weight:700;
`
const Head = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
`
const Label = styled.div`
    font-weight:500;
`
const FieldItems = styled.div`
    margin-bottom:20px;
`
const Fields = styled.div`
    margin-top:50px;
    width:100%;
    height:80%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    margin-bottom:40px;
`
const Button = styled.div`
    height:40px;
    background-color:black;
    color:white;
    border-radius:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:15px;
`


const AddAgent = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [warehouses, setWarehouses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allWarehouses, setAllWarehouses] = useState([]);

    const navigate = useNavigate();

    const onAddAgent = async () => {
        setLoading(true);
        const newWarehouses = warehouses.map((warehouse) => { return warehouse._id });
        try {
            const data = {
                name: name,
                email: email,
                password: password,
                phone: phone,
                warehouses: newWarehouses,
                type: 'agent'
            };
            const response = await axiosInstance.post('/api/user', data);
            if (response.status === 200) {
                setLoading(false);
                onResetStringInput([setName, setEmail, setPassword, setPhone]);
                onResetArrayInput([setWarehouses]);
                alert("✅Agent added successfully");
                navigate(-1);
            }
            getToken();
        } catch (err) {
            console.log(err);
            setLoading(false);
            alert(err.response.data.message);
        }
    }

    const getAllWarehouses = async () => {
        try {
            const response = await axiosInstance.get('/api/warehouse');
            if (response.status === 200) {
                setAllWarehouses(response.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const onNavigate = () => {
        navigate('/agent', { replace: true });
    }

    useEffect(() => {
        getAllWarehouses();
    }, []);


    const getToken = async () => {
        try {

            const options = {
                method: 'POST',
                url: `${process.env.REACT_APP_OZONETEL_BASE_URL}ca_apis/CAToken/generateToken`,
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    apiKey: process.env.REACT_APP_OZONETEL_API_KEY
                },
                data: { userName: process.env.REACT_APP_OZONETEL_USER_NAME }
            };
            axios
                .request(options)
                .then(res => {

                    const options = {
                        method: 'POST',
                        url: `${process.env.REACT_APP_OZONETEL_BASE_URL}ca_apis/PhoneManageV2`,
                        headers: {
                            accept: 'application/json',
                            apiKey: process.env.REACT_APP_OZONETEL_API_KEY,
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${res?.data?.token}`
                        },
                        data: {
                            "userName": process.env.REACT_APP_OZONETEL_USER_NAME,
                            "action": "create",
                            "phoneNumber": phone,
                            "phoneName": phone,
                            "skill": "Delivery_skills"
                        }
                    };
                    axios
                        .request(options)
                        .then(res => console.log(res.data))
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        } catch (error) {
            console.error(error)
        }
    }

    const deleteAgentFromOzoneTel = async () => {
        try {
            const options = {
                method: 'POST',
                url: `${process.env.REACT_APP_OZONETEL_BASE_URL}ca_apis/CAToken/generateToken`,
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    apiKey: process.env.REACT_APP_OZONETEL_API_KEY
                },
                data: { userName: process.env.REACT_APP_OZONETEL_USER_NAME }
            };
            axios
                .request(options)
                .then(res => {
                    const options = {
                        method: 'POST',
                        url: `${process.env.REACT_APP_OZONETEL_BASE_URL}ca_apis/PhoneManageV2`,
                        headers: {
                            accept: 'application/json',
                            // apiKey: process.env.REACT_APP_OZONETEL_API_KEY,
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${res?.data?.token}`
                        },
                        data: {
                            "userName": process.env.REACT_APP_OZONETEL_USER_NAME,
                            "action": "delete",
                            "phoneNumber": phone,
                            "phoneName": phone,
                        }
                    };
                    axios
                        .request(options)
                        .then(res => console.log(res.data))
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        } catch (error) {
            console.error(error)
        }
    }

    const handleCreateClick = async () => {
        const token = getToken();
        const options = {
            method: 'POST',
            url: `${process.env.REACT_APP_OZONETEL_BASE_URL}ca_apis/PhoneManageV2`,
            headers: {
                accept: 'application/json',
                apiKey: process.env.REACT_APP_OZONETEL_API_KEY,
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },
            data: {
                "userName": process.env.REACT_APP_OZONETEL_USER_NAME,
                "action": "create",
                "phoneNumber": phone,
                "phoneName": phone,
                "skill": "Agent"
            }
        };
        await axios
            .request(options)
            .then(res => console.log(res.data))
            .catch(err => console.error(err));
    }

    return (
        <Container>
            <Main>
                <Button
                    onClick={onNavigate}
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '2%',
                        right: '5%'
                    }} >
                    Back
                </Button>
                <Head>
                    <Heading>Add Agent</Heading>
                </Head>
                <Fields>
                    <FieldItems>
                        <Label>Name</Label>
                        <Input value={name} setvalue={setName} style={{ height: "50px" }} placeholder="Enter agent name" />
                    </FieldItems>
                    <FieldItems>
                        <Label style={{ marginTop: "-2px" }}>Email</Label>
                        <Input value={email} setvalue={setEmail} style={{ height: "50px" }} placeholder="Enter Email" />
                    </FieldItems>
                    <FieldItems>
                        <Label style={{ marginTop: "1px" }}>Phone</Label>
                        <Input value={phone} setvalue={setPhone} style={{ height: "50px" }} placeholder="Enter phone number" />
                    </FieldItems>
                    <FieldItems>
                        <Label style={{ marginTop: "1px" }}>Password</Label>
                        <Input type="password" value={password} setvalue={setPassword} style={{ height: "50px" }} placeholder="Enter password" />
                    </FieldItems>
                    <FieldItems>
                        <Label>Assign City</Label>
                        <MultiSelect city={warehouses} setCity={setWarehouses} data={allWarehouses} />
                    </FieldItems>
                </Fields>
                <Button onClick={onAddAgent} style={{ cursor: 'pointer' }} >
                    {
                        loading ?
                            <Loading color="#fff" /> :
                            <>
                                <AddIcon />
                                &nbsp;
                                Add Agent
                            </>
                    }
                </Button>
            </Main>
        </Container>
    )
}

export default AddAgent