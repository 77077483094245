import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Pagination, Typography } from "@mui/material";
import axiosInstance from "../../../axios/AxiosInstance";
import Loading from "../../../components/loading/Loading";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { maxWidth } from "@mui/system";
import MessageEditModal from "./MessageEditModal";
import BounceLoader from "react-spinners/BounceLoader";

const columns = [
  {
    id: "name",
    label: "Customer Name",
    minWidth: 150,
  },
  {
    id: "email",
    label: "Customer Email",
    minWidth: 150,
  },
  {
    id: "city",
    label: "City",
    minWidth: 150,
  },
  {
    id: "task_id",
    label: "Task Id",
    minWidth: 150,
  },
  {
    id: "message",
    label: "SMS",
    minWidth: 200,
  },
  {
    id: "device_type",
    label: "SMS Type",
    minWidth: 200,
  },
  {
    id: "time_sent",
    label: "Date",
    minWidth: 100,
    align: "center",
  },
];

export default function MessageTable({
  messages,
  setMessages,
  loading,
  cityname,
  setTotalPage,
  totalPage,
  start,
  end,
  filters,
  isDateFilterApplied,
  setLoading,
  createData,
  isCityFilterApplied,
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [update, setUpdate] = useState(false);
  const [isMessage, setIsmessage] = useState(false);
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpdate = (row) => {
    // console.log(row);
    navigate("/updateroleother", { state: { row: row } });
  };
  const getMessages = async () => {
    try {
      setLoading(true);
      let response;
      if (isDateFilterApplied && isCityFilterApplied) {
        response = await axiosInstance.get(
          `/api/ticket/sms/record?page=${page}&limit=${limit}&city=${cityname}&type=scheduled&start=${start}&end=${end}`
        );
      } else if (isDateFilterApplied) {
        response = await axiosInstance.get(
          `/api/ticket/sms/record?page=${page}&limit=${limit}&type=scheduled&start=${start}&end=${end}`
        );
      } else if (isCityFilterApplied) {
        response = await axiosInstance.get(
          `/api/ticket/sms/record?page=${page}&limit=${limit}&city$contains=${cityname}`
        );
      } else {
        response = await axiosInstance.get(
          `/api/ticket/sms/record?page=${page}&limit=${limit}`
        );
      }
      if (response.status === 200) {
        setLoading(false);
        let allMessages = response.data.data.docs;
        // setTotalPage(response.data.data.totalPages);
        // console.log(response.data.data.totalPages, "dfsssssssdfdf");
        setTotalPage(response.data.data.totalPages);
        allMessages = allMessages.map((message) => {
          return createData(message);
        });
        // console.log(allMessages, "ddd");
        setMessages(allMessages);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getMessages();
  }, [flag, page]);
  useEffect(() => { }, [messages]);

  return (
    <div style={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "none",
        }}
      >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                backgroundColor: "#F2F4FF",
              }}
            >
              <TableRow>
                {columns?.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#F2F4FF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <div style={{ width: "90vw" }} className="LoadingContainer"><BounceLoader /> </div>
              ) : messages && messages?.length > 0 ? (
                messages
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        {columns?.map((column) => {
                          if (column.id === "name") {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {row?.userId?.firstName}
                              </TableCell>
                            );
                          } else if (column.id === "email") {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {row?.userId?.email}
                              </TableCell>
                            );
                          } else if (column.id === "city") {
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {row?.userId?.city}
                              </TableCell>
                            );
                          } else {
                            const value = row[column.id];
                            return (
                              <TableCell
                                sx={{
                                  border: "none",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <Typography sx={{ padding: "1rem" }}>
                    No Data Found
                  </Typography>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          <Pagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onChange={(e, value) => setPage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
}
