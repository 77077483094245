import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axiosInstance from "../../../axios/AxiosInstance";
import Input from "../../../components/input/Input";
import { Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Clear";
import Loading from "../../../components/loading/Loading";
import { Select, MenuItem } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: 330,
  },
};

const BasicModal = ({
  flag,
  setFlag,
  city,
  setTableData,
  filters,
  setFilters,
  setTotalPage,
  page,
  limit,
  setPage,
  loading,
  setLoading,
  setStart,
  setEnd,
  setLastPage,
  setFinalPage,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [status, setStatus] = useState("");
  const [ticketId, setTicketId] = useState("");
  const UserData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
  const userId = UserData?._id;

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const handleChangeSubCategory = (event) => {
    setSubCategory(event.target.value);
  };
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  const getdata = async () => {
    try {
      setLoading(true);
      let url = "";

      if (name !== "") {
        url = url + `&name$contains=${name}`;
      }
      if (category !== "") {
        url = url + `&category$contains=${category}`;
      }
      if (email !== "") {
        url = url + `&email$contains=${email}`;
      }
      if (status !== "") {
        url = url + `&status$contains=${status}`;
      }
      if (subCategory !== "") {
        url = url + `&subCategory$contains=${subCategory}`;
      }
      if (ticketId !== "") {
        url = url + `&ticketNumber$contains=${ticketId}`;
      }

      setFilters(url);
      let baseUrl = "";
      if (UserData.type === "agent") {
        baseUrl = `api/ticket?page=${
          page + 1
        }&limit=${limit}&agentId=${userId}&city=${city}`;
      } else {
        baseUrl = `api/ticket?page=${page + 1}&limit=${limit}&city=${city}`;
      }

      url = baseUrl + url;
      // console.log(url);
      const tableRes = await axiosInstance.get(url);
      if (tableRes.status === 200) {
        setLoading(false);
        // console.log(tableRes);
        setTableData(tableRes.data.data);
        setTotalPage(tableRes.data.count);
        setLastPage(true);
        if (tableRes.data.count === 0) setStart(0);
        else setStart(1);
        if (limit >= tableRes.data.count) setEnd(tableRes.data.count);
        else setEnd(limit);
        if (tableRes.data.count <= limit) setFinalPage(true);
        else setFinalPage(false);
      } else {
        setLoading(false);
        alert("Something went wrong!!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong!!");
    }
    setFlag(!flag);
    handleClose();
  };

  const reset = async () => {
    setName("");
    setEmail("");
    setCategory("");
    setStatus("");
    setSubCategory("");
    setFilters("");
    setPage(0);
    setTicketId("");

    try {
      setLoading(true);
      let baseUrl = "";
      if (UserData.type === "agent") {
        baseUrl = `api/ticket?page=${1}&limit=${limit}&agentId=${userId}&city=${city}`;
      } else {
        baseUrl = `api/ticket?page=${1}&limit=${limit}&city=${city}`;
      }

      const tableRes = await axiosInstance.get(baseUrl);
      if (tableRes.status === 200) {
        setLoading(false);
        setTableData(tableRes.data.data);
        setTotalPage(tableRes.data.count);
        setLastPage(true);
        if (tableRes.data.count === 0) setStart(0);
        else setStart(1);
        if (limit >= tableRes.data.count) setEnd(tableRes.data.count);
        else setEnd(limit);
        if (tableRes.data.count <= limit) setFinalPage(true);
        else setFinalPage(false);
      } else {
        setLoading(false);
        alert("Something went wrong!!");
      }
    } catch (err) {
      setLoading(false);
      alert("Something went wrong!!");
    }
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          whiteSpace: "nowrap",
          height: "40px",
          backgroundColor: "#000",
          color: "#fff",
          fontSize: "0.875rem !important",
          "&:hover": {
            backgroundColor: "#000",
            opacity: "0.8",
          },
          "@media (max-width: 785px)": {
            fontSize: "0.6rem !important",
            // width:"60px",
            height: "30px",
          },
        }}
        onClick={handleOpen}
      >
        <FilterAltIcon
          sx={{
            // marginRight: "10px",
            "@media (max-width: 785px)": {
              fontSize: "1rem !important",
            },
          }}
        />
        Filter
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon
            sx={{
              position: "absolute",
              right: "-30px",
              top: "-22px",
              cursor: "pointer",
              color: "white",
              "@media (max-width: 400px)": {
                right: "10px",
                top: "10px",
                color: "black",
              },
            }}
            onClick={() => {
              setName("");
              setEmail("");
              setCategory("");
              setStatus("");
              setTicketId("");
              setSubCategory("");
              handleClose();
              setFilters("");
            }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filter
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack direction="column">
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  marginBottom: "50px",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      width: "20%",
                    }}
                  >
                    Name
                  </Typography>
                  <Input
                    value={name}
                    setvalue={setName}
                    style={{ width: "70%" }}
                    type="text"
                    placeholder="Enter name"
                    some={getdata}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      width: "20%",
                    }}
                  >
                    Email
                  </Typography>
                  <Input
                    value={email}
                    setvalue={setEmail}
                    style={{ width: "70%" }}
                    type="text"
                    placeholder="Enter email"
                    some={getdata}
                  />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      width: "20%",
                    }}
                  >
                    Category
                  </Typography>
                  <Select
                    value={category}
                    style={{ width: "70%", borderColor: "black" }}
                    type="text"
                    placeholder="Enter City"
                    some={getdata}
                    onChange={handleChangeCategory}
                  >
                    <MenuItem value={"Service Request"}>
                      Service Request
                    </MenuItem>
                    <MenuItem value={"Order"}>Order</MenuItem>
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      width: "20%",
                    }}
                  >
                    Sub Category
                  </Typography>
                  <Select
                    value={subCategory}
                    style={{ width: "70%", borderColor: "black" }}
                    type="text"
                    placeholder="Enter City"
                    some={getdata}
                    onChange={handleChangeSubCategory}
                  >
                    <MenuItem value={"Pickup and Refund"}>
                      Pickup and Refund
                    </MenuItem>
                    <MenuItem value={"Replace"}>Replace</MenuItem>
                    <MenuItem value={"New - Rental"}>New - Rental</MenuItem>
                    <MenuItem value={"Repair"}>Repair</MenuItem>
                    <MenuItem value={"Relocation"}>Relocation</MenuItem>
                    <MenuItem value={"New - Buy"}>New - Buy</MenuItem>
                    <MenuItem value={"Offline - Rental"}>
                      Offline - Rental
                    </MenuItem>
                    <MenuItem value={"Repair"}>Repair</MenuItem>
                    <MenuItem value={"Upgrade"}>Upgrade</MenuItem>
                    <MenuItem value={"Installation"}>Installation</MenuItem>
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      width: "20%",
                    }}
                  >
                    Status
                  </Typography>
                  <Select
                    value={status}
                    style={{ width: "70%", borderColor: "black" }}
                    type="text"
                    placeholder="Enter City"
                    some={getdata}
                    onChange={handleChange}
                  >
                    <MenuItem value={"Pickup Pending"}>Pickup Pending</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Order Verified"}>Order Verified</MenuItem>
                    <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                    <MenuItem value={"Delivered"}>Delivered</MenuItem>
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                    <MenuItem value={"Pickup Done"}>Pickup Done</MenuItem>
                    <MenuItem value={"Pickup Scheduled"}>
                      Pickup Scheduled
                    </MenuItem>
                    <MenuItem value={"Delivery Scheduled"}>
                      Delivery Scheduled
                    </MenuItem>
                  </Select>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      width: "20%",
                    }}
                  >
                    Ticket Id
                  </Typography>
                  <Input
                    value={ticketId}
                    setvalue={setTicketId}
                    style={{ width: "70%" }}
                    type="text"
                    placeholder="Enter Ticket Id"
                    some={getdata}
                  />
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    whiteSpace: "nowrap",
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={getdata}
                >
                  {loading ? <Loading /> : "Ok"}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    whiteSpace: "nowrap",
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem!important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={reset}
                >
                  {loading ? <Loading /> : "Reset Filters"}
                </Button>
              </Stack>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default BasicModal;
