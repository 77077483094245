import React, { useState, useEffect } from 'react';
import './BarcodeMatchTable.css'; // Make sure to import the CSS file
import { TextField, MenuItem, Button, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../../axios/AxiosInstance';
import moment from "moment/moment";
import BounceLoader from "react-spinners/BounceLoader";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";

import * as XLSX from "xlsx";
import { toast } from 'react-toastify';

const cities = ["All", 'Mumbai', 'Hyderabad', 'Bangalore', 'Pune', 'Noida', 'Gurgaon'];
const citiesAll = ['Mumbai', 'Hyderabad', 'Bangalore', 'Pune', 'Noida', 'Gurgaon'];

const jobTypes = ["All", "Pickup and Refund", "New - Rental", "Repair", "Replace", "Manual Entry", "PO Payment", "Refurb Transfer", "Stock Transfer"];
const jobTypeAll = ["Pickup and Refund", "New - Rental", "Repair", "Replace", "Manual Entry", "PO Payment", "Refurb Transfer", "Stock Transfer"];

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    minHeight: "50vh",
    overflow: 'scroll'
};

const BarcodeMatchTable = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [loading, setLoading] = useState(false);

    const [city, setCity] = useState(cities[1]);
    const [jobType, setJobType] = useState(jobTypes[1]);
    const [data, setData] = useState({});
    const [loadingPop, setLoadingPop] = useState(false);
    const [taskDetails, setTaskDetails] = useState([]);

    let CityNames = JSON.parse(localStorage.getItem("wh_tk"))?.data?.warehouses;
    let checkAdmin = JSON.parse(localStorage.getItem("wh_tk"))?.data?.type;

    let house = [
        {
            _id: "6353dd2fc9383308bab3b855",
            name: "Noida",
            createdAt: "2022-10-22T12:08:15.928Z",
            updatedAt: "2022-10-22T12:08:15.928Z",
            __v: 0,
            city: "Noida",
        },
        {
            _id: "6385d82369d7b11ec8dbce2f",
            name: "Pune",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Pune",
        },
        {
            _id: "639ee663a0a2c3856669742c",
            name: "Mumbai",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Mumbai",
        },
        {
            _id: "639ee6aaa0a2c3856669742e",
            name: "Jodhpur",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Jodhpur",
        },
        {
            _id: "639ee6d6a0a2c38566697430",
            name: "Gurgaon",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Gurgaon",
        },
        {
            _id: "639ee6ffa0a2c38566697431",
            name: "Bangalore",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Bangalore",
        },
        {
            _id: "639ee735a0a2c38566697433",
            name: "Hyderabad",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Hyderabad",
        },
    ];
    if (checkAdmin == "admin") {
        CityNames = house;
    }

    const [openForm, setOpenForm] = useState(false);
    const fetchData = async () => {
        try {
            setLoading(true);
            let start = new Date(startDate);
            let end = new Date(endDate);
            end = end.setHours(23, 59, 59, 999);
            end = new Date(end).toUTCString();
            const url = `/api/ticket/ordernew?jobType=${jobType}&type=Scheduled&city=${city}&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
            let data = await axiosInstance.get(url);
            setData({ ...data.data.data });
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    let exportData = () => {
        let start = new Date(startDate);
        let end = new Date(endDate);
        end = end.setHours(23, 59, 59, 999);
        end = new Date(end).toUTCString();
        const url = `${process.env.REACT_APP_API_URL}api/ticket/ordernew?jobType=${jobType}&type=Scheduled&city=${city}&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}&download=csv`;
        var newWindow = window.open(url);
    }

    // const handleDate = (event) => {
    //     if (event[0] != null && event[1] != null) {
    //         setStartDate(new Date(start).getTime());
    //         setEndDate(new Date(end).getTime());
    //         setStart();
    //         setEnd();
    //     }
    // };

    const handleCityChange = (e) => {
        let allstring = ``
        if (e.target.value === "All") {
            citiesAll?.map((item) => {
                allstring += item + ","
            })
            setCity(allstring);
        } else {
            setCity(e.target.value);
        }
    };

    const handleJobTypeChange = (e) => {
        let allstring = ``
        if (e.target.value === "All") {
            jobTypeAll?.map((item) => {
                allstring += item + ","
            })
            setJobType(allstring);
        } else {
            setJobType(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchData();
    };

    const locations = Object.keys(data);

    const calculateTotals = (data) => {
        let totals = {
            outwardDoneMatch: 0,
            outwardDoneNotMatch: 0,
            outwardNotDoneMatch: 0,
            outwardNotDoneNotMatch: 0,
            inwardDoneMatch: 0,
            inwardDoneNotMatch: 0,
            inwardNotDoneMatch: 0,
            inwardNotDoneNotMatch: 0,
        };

        locations?.forEach(location => {
            const locationData = data[location];
            totals.outwardDoneMatch += locationData.outward.done.match;
            totals.outwardDoneNotMatch += locationData.outward.done.notMatch;
            totals.outwardNotDoneMatch += locationData.outward.notDone.match;
            totals.outwardNotDoneNotMatch += locationData.outward.notDone.notMatch;
            totals.inwardDoneMatch += locationData.inward.done.match;
            totals.inwardDoneNotMatch += locationData.inward.done.notMatch;
            totals.inwardNotDoneMatch += locationData.inward.notDone.match;
            totals.inwardNotDoneNotMatch += locationData.inward.notDone.notMatch;
        });
        return totals;
    };

    const totals = calculateTotals(data);
    // const exportOder = () => {
    //     let status = "Assigned" ? "assigned" : "scheduled"
    //     let url = `${process.env.REACT_APP_API_URL}api/ticket/order?type=${status}&download=csv&`;
    //     url =
    //         url +
    //         `start=${new Date(startDate).getTime()}&end=${new Date(endDate).getTime()}`;
    //     var newWindow = window.open(url);
    // };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = async (inOutValue, doneNotDone, matchNotMatch, location) => {

        setOpen(true);
        setLoadingPop(true);
        try {
            let start = new Date(startDate);
            let end = new Date(endDate);
            end = end.setHours(23, 59, 59, 999);
            end = new Date(end).toUTCString();
            const url = `${process.env.REACT_APP_API_URL}api/ticket/ordernew/taskdetails?jobType=${jobType}&type=scheduled&city=${location}&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}&download=csv`;
            const data = await axiosInstance.get(url);

            // const updatedData = data?.data?.data?.map(item => ({
            //     ...item,
            //     match: item["Tried Barcode"] === item["Barcode"] ? "match" : "notMatch"
            // }));
            console.log(data.data.data, "Data type");

            const updatedData = data?.data?.data?.map(item => ({
                ...item,
                match: item["Job Type"] == "Manual Entry"
                    ? "match"
                    : (item["Tried Barcode"] === item["Barcode"] ? "match" : "notMatch")
            }));

            console.log(updatedData, 'After set mathch and all ');

            formateTableData(inOutValue, doneNotDone, matchNotMatch, location, updatedData);
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingPop(false);
        }
    }


    // const formateTableData = (inOutValue, doneNotDone, matchNotMatch, location, data) => {
    //     console.log(data, doneNotDone, matchNotMatch, inOutValue);

    //     const filterData = data?.filter(item =>
    //         item?.Movement == inOutValue &&
    //         (item['Physical Status'].toUpperCase() === doneNotDone.toUpperCase() || item['Physical Status'] === "Pending") &&
    //         item?.match == matchNotMatch
    //     );


    //     setTaskDetails(filterData);
    //     return filterData; // Return the filtered data if needed
    // };



    const formateTableData = (inOutValue, doneNotDone, matchNotMatch, location, data) => {

        const filterData = data?.filter(item =>
            item?.Movement === inOutValue &&
            (
                item['Physical Status'].toUpperCase() === doneNotDone.toUpperCase() ||
                item['Physical Status'].toUpperCase() === "PENDING"
            ) &&
            item?.match === matchNotMatch
        );


        setTaskDetails(filterData); // Ensure setTaskDetails is defined
        return filterData;
    };


    const downloadExcel = (data) => {

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);


        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });


        const link = document.createElement("a");


        link.href = URL.createObjectURL(blob);


        link.download = "example.xlsx";


        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    const handleFormClose = () => {
        setOpenForm(false)
    }
    const handleFormOpen = () => {
        setOpenForm(true)
    }

    const [formData, setFormData] = useState({
        typeCategory: "",
        taskCategory: "",
        match: "match",
        scheduledDate: "",
        soNumber: "",
        ticketId: "",
        customerName: "",
        productName: "",
        quantity: "",
        physicalStatus: "",
        vehicleNumber: "",
        agentName: "",
        odooStatus: "",
        verifiedBy: "",
        city: "",
        movement: "",
    });

    const handleChangef = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmitF = (e) => {
        e.preventDefault();
        saveFormData();
    };

    const saveFormData = async () => {

        try {
            const response = await axiosInstance.post(`/api/barcodeTask/save-form`, formData);
            toast.success("Data saved Successfully")
            return response.data; // Return response for further use

        } catch (error) {
            console.error('Error saving form data:', error);
            alert('Failed to save form data. Please try again.');
        } finally {
            handleFormClose()
        }
    };

    const getFormData = async () => {
        try {
            const response = await axiosInstance.get(`/api/barcodeTask/getData`);
            console.log('Retrieved form data:', response.data);
            return response.data.data; // Return the list of forms
        } catch (error) {
            console.error('Error fetching form data:', error);
            alert('Failed to fetch form data. Please try again.');
        }
    };

    return (
        <div style={{ maxWidth: "98%" }}>
            <div style={{}}>
                <Modal
                    open={openForm}
                    onClose={handleFormClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="Heading_style">Manual Data Addition</div>
                        <form onSubmit={handleSubmitF} className="form-container">
                            <div className="form-row">
                                <div style={{ marginBottom: "10px" }} className="form-group">
                                    <label htmlFor="taskCategory">Select Task Category </label>
                                    <select
                                        id="taskCategory"
                                        name="taskCategory"
                                        value={formData.taskCategory}
                                        onChange={handleChangef}
                                    >
                                        <option value="">Select Task Category</option>
                                        <option value="Spare part in/Out">Spare part in/Out</option>
                                        <option value="Consumables in/out">Consumables in/out</option>
                                        <option value="Sample items">Sample items</option>
                                        <option value="other">Other</option>
                                    </select>
                                </div>
                                <div style={{ marginBottom: "10px" }} className="form-group">
                                    <label htmlFor="match">Select Match </label>
                                    <select
                                        id="match"
                                        name="match"
                                        value={formData.match}
                                        onChange={handleChangef}
                                    >
                                        <option value="match">match</option>
                                    </select>
                                </div>

                            </div>
                            <div className="form-row">

                            </div>
                            {formData.taskCategory == "other" && <div className="form-group">
                                <label htmlFor="typeCategory">Task Category</label>
                                <input
                                    type="text"
                                    id="typeCategory"
                                    name="typeCategory"
                                    value={formData.typeCategory}
                                    onChange={handleChangef}
                                />
                            </div>}
                            {/* Scheduled Date and SO Number */}
                            <div className="form-row">
                                {/* <div className="form-group">
                                    <label htmlFor="scheduledDate">Scheduled Date :</label>
                                    <input
                                        type="date"
                                        id="scheduledDate"
                                        name="scheduledDate"
                                        value={formData.scheduledDate}
                                        onChange={handleChangef}
                                    />
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="scheduledDate">Scheduled Date </label>
                                    <input
                                        type="date"
                                        id="scheduledDate"
                                        name="scheduledDate"
                                        value={formData.scheduledDate}
                                        onChange={handleChangef}
                                        min={new Date(new Date().setDate(new Date().getDate() - 1))
                                            .toISOString()
                                            .split("T")[0]} // Yesterday's date
                                        max={new Date().toISOString().split("T")[0]} // Today's date
                                    />

                                </div>
                                <div className="form-group">
                                    <label htmlFor="soNumber">SO Number </label>
                                    <input
                                        type="text"
                                        id="soNumber"
                                        name="soNumber"
                                        value={formData.soNumber}
                                        onChange={handleChangef}
                                    />
                                </div>
                            </div>
                            {/* Ticket ID and Customer Name */}
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="ticketId">Ticket ID </label>
                                    <input
                                        type="text"
                                        id="ticketId"
                                        name="ticketId"
                                        value={formData.ticketId}
                                        onChange={handleChangef}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="customerName">Customer/Vendor Name </label>
                                    <input
                                        type="text"
                                        id="customerName"
                                        name="customerName"
                                        value={formData.customerName}
                                        onChange={handleChangef}
                                    />
                                </div>
                            </div>

                            {/* Product Name and Qty */}
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="productName">Products/Spare Part Name </label>
                                    <input
                                        type="text"
                                        id="productName"
                                        name="productName"
                                        value={formData.productName}
                                        onChange={handleChangef}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="qty">Quantity </label>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        value={formData.quantity}
                                        onChange={handleChangef}
                                    />
                                </div>
                            </div>
                            {[
                                { label: "Physical Status", name: "physicalStatus", type: 'drop', options: [{ name: "Select Physical Status", value: 'Select Physical Status' }, { name: "Done", value: 'done' }, { name: "Not Done", value: 'notdone' }] },
                                { label: "Agent Name", name: "agentName", type: 'input' },
                                { label: "Odoo Status", name: "odooStatus", type: 'drop', options: [{ name: "Select Odoo Status", value: 'Select odoo Status' }, { name: "Done", value: 'done' }, { name: "Not Done", value: 'notdone' }] },
                                { label: "Verified By", name: "verifiedBy", type: 'input' },
                                { label: "City", name: "city", type: 'drop', options: [{ city: "Select City ", name: "Select City" }, ...CityNames] },
                                { label: "Movement", name: "movement", type: 'drop', options: [{ name: "Select Movement", value: '' }, { name: "Inward", value: 'In' }, { name: "outward", value: 'OUT' }] },
                            ].reduce((rows, field, index, arr) => {
                                if (index % 2 === 0) rows.push(arr.slice(index, index + 2));
                                return rows;
                            }, []).map((pair, idx) => (
                                <div className="form-row" key={idx}>
                                    {pair?.map(({ label, name, type, options }) => (
                                        <div className="form-group" key={name}>
                                            <label htmlFor={name}>{label} </label>
                                            {type == "input" ? < input
                                                type="text"
                                                id={name}
                                                name={name}
                                                value={formData[name]}
                                                onChange={handleChangef}
                                            /> : <div className="form-group">
                                                <select
                                                    id="vehicleNumber"
                                                    name={name}
                                                    value={formData[name]}
                                                    onChange={handleChangef}>
                                                    {options?.map((item) => {
                                                        return <option value={item?.value}>{item?.name}</option>
                                                    })}
                                                </select>
                                            </div>}
                                        </div>
                                    ))}
                                </div>
                            ))}

                            <div style={{ marginBottom: '5px' }} className="form-group">
                                <label htmlFor="vehicleNumber">Vehicle Number </label>
                                {/* <select
                                    id="vehicleNumber"
                                    name="vehicleNumber"
                                    value={formData.vehicleNumber}
                                    onChange={handleChangef}
                                >
                                    <option value="">Select Vehicle Number</option>
                                    <option value="AB123CD">AB123CD</option>
                                    <option value="EF456GH">EF456GH</option>
                                    <option value="IJ789KL">IJ789KL</option>
                                </select> */}

                                <input
                                    type="vehicleNumber"
                                    id="vehicleNumber"
                                    name="vehicleNumber"
                                    value={formData.vehicleNumber}
                                    onChange={handleChangef}
                                />
                            </div>

                            {/* Submit Button */}
                            <div className="form-group">
                                <button type="submit" className="submit-button">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </Box>
                </Modal>

                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                            <div style={{ padding: "5px", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ marginRight: "5px", whiteSpace: "nowrap" }}>Date Range:</label>
                                <DatePicker
                                    id='someDatePicker'
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true} />
                            </div>
                            <div style={{ padding: "5px", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ marginRight: "5px", whiteSpace: "nowrap" }}>City</label>
                                <select style={{ padding: "5px", width: "191px" }} value={city} onChange={handleCityChange}>
                                    {cities?.map((item, key) => {
                                        return <option key={key} value={item}>{item}</option>
                                    })}
                                </select>
                            </div>
                            <div style={{ padding: "5px", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ marginRight: "5px", whiteSpace: "nowrap" }}>Job Type:</label>
                                <select style={{ padding: "5px", width: "191px" }} value={jobType} onChange={handleJobTypeChange}>
                                    {jobTypes?.map((item, key) => {
                                        return <option key={key} value={item}>{item}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: "10px" }}>
                            <button className="TatSubmitbutton" type="submit">Submit</button>
                            <button onClick={() => exportData()} className="TatSubmitbutton" type="submit">Export</button>
                            <button onClick={() => setOpenForm(true)} className="TatSubmitbutton" type="submit">Add Data</button>

                        </div>
                    </div>
                </form>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style1}>
                    {!loadingPop ? <> <div className={'ContainerPopBarcode'}>
                        <div className={"HeadingPopbarcode"}>
                            Task Details
                        </div>
                        <div >
                            <ClearIcon
                                style={{
                                    cursor: "pointer",
                                    color: "black",
                                }}
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                        <div style={{ overflow: 'scroll' }} className="TableVehicleTaskDetails">
                            {<table border="1" cellPadding="10" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th style={cellStyleHeaderBold}>Scheduled Date</th>
                                        <th style={cellStyleHeaderBold}>SO Number</th>
                                        <th style={cellStyleHeaderBold}>Ticket ID</th>
                                        <th style={cellStyleHeaderBold}>Customer Name</th>
                                        <th style={cellStyleHeaderBold}>Product Name</th>
                                        <th style={cellStyleHeaderBold}>Physical Status</th>
                                        <th style={cellStyleHeaderBold}>Vehicle Number</th>
                                        <th style={cellStyleHeaderBold}>Agent Name</th>
                                        <th style={cellStyleHeaderBold}>Job Type</th>
                                        <th style={cellStyleHeaderBold}>Odoo Status</th>
                                        <th style={cellStyleHeaderBold}>Verified By</th>
                                        <th style={cellStyleHeaderBold}>Tried Barcode</th>
                                        <th style={cellStyleHeaderBold}>Barcode</th>
                                        <th style={cellStyleHeaderBold}>Status</th>
                                        <th style={cellStyleHeaderBold}>City</th>
                                        <th style={cellStyleHeaderBold}>Movement</th>
                                        <th style={cellStyleHeaderBold}>Order Date</th>
                                        <th style={cellStyleHeaderBold}>Not scanning reasons</th>
                                        <th style={cellStyleHeaderBold}>match</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {taskDetails?.map((item) => {
                                        return <tr>
                                            <td style={cellStyle}>{item['Scheduled Date']}</td>
                                            <td style={cellStyle}>{item['SO Number']}</td>
                                            <td style={cellStyle}>{item['Ticket ID']}</td>
                                            <td style={cellStyle}>{item['Customer Name']}</td>
                                            <td style={cellStyle}>{item['Product Name']}</td>
                                            <td style={cellStyle}>{item['Physical Status']}</td>
                                            <td style={cellStyle}>{item['Vehicle Number']}</td>
                                            <td style={cellStyle}>{item['Agent Name']}</td>
                                            {/* <td style={cellStyle}>{item['Barcode']}</td> */}
                                            <td style={cellStyle}>{item['Job Type']}</td>
                                            <td style={cellStyle}>{item['Odoo Status']}</td>
                                            <td style={cellStyle}>{item['Verified By']}</td>
                                            <td style={cellStyle}>{item['Tried Barcode']}</td>
                                            <td style={cellStyle}>{item['Barcode']}</td>
                                            <td style={cellStyle}>{item['Status']}</td>
                                            {/* <td style={cellStyle}>{item['Transition date']}</td> */}
                                            <td style={cellStyle}>{item['City']}</td>
                                            <td style={cellStyle}>{item['Movement']}</td>
                                            <td style={cellStyle}>{item['Order Date']}</td>
                                            <td style={cellStyle}>{item['Not scanning reasons']}</td>
                                            <td style={cellStyle}>{item['match']}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>}
                        </div>
                        <div style={containerStyle}>
                            {/* <button onClick={() => exportTaskDetails()} className="TatSubmitbutton">Export</button> */}
                            <button onClick={() => downloadExcel(taskDetails)} className="TatSubmitbutton">Export</button>
                        </div></> : <div style={{ height: "40vh", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                        <BounceLoader />
                    </div>}
                </Box>
            </Modal>

            {!loading ? <table className="barcode-match-table">
                <thead>
                    <tr style={{ color: "black" }} className="header">
                        <th style={cellStyleHeader} colSpan="2">Movement type</th>
                        <th style={cellStyleHeader} colSpan="4">Outward</th>
                        <th style={cellStyleHeader} colSpan="4">Inward</th>
                        <th style={cellStyleHeader} rowSpan="2">Grand Total</th>
                    </tr>
                    <tr style={{ color: "black" }} className="subheader">
                        <th style={cellStyleHeader} colSpan="2">Row Labels</th>
                        <th style={cellStyleHeader} colSpan="2">Done</th>
                        <th style={cellStyleHeader} colSpan="2">Not Done</th>
                        <th style={cellStyleHeader} colSpan="2">Done</th>
                        <th style={cellStyleHeader} colSpan="2">Not Done</th>
                    </tr>
                    <tr>
                        <th style={cellStyleHeader} colSpan="2" className="empty-cell"></th>
                        <th style={cellStyleHeader}>Matched</th>
                        <th style={cellStyleHeader}>Non-Matched</th>
                        <th style={cellStyleHeader}>Matched</th>
                        <th style={cellStyleHeader}>Non-Matched</th>
                        <th style={cellStyleHeader}>Matched</th>
                        <th style={cellStyleHeader}>Non-Matched</th>
                        <th style={cellStyleHeader}>Matched</th>
                        <th style={cellStyleHeader}>Non-Matched</th>
                        <th style={cellStyleHeader} className="empty-cell"></th>
                    </tr>
                </thead>
                <tbody>
                    {locations?.map(location => (
                        <tr key={location}>
                            <td colSpan="2" className="row-label">{location}</td>
                            <td style={data?.[location]?.outward?.done?.match ? linkStyle : {}} onClick={() => data[location].outward.done.match && handleOpen("OUT", "Done", "match", location)}>{data[location].outward.done.match}</td>
                            <td style={data?.[location]?.outward?.done?.notMatch ? linkStyle : {}} onClick={() => data[location].outward.done.notMatch && handleOpen("OUT", "Done", "notMatch", location)}>{data[location].outward.done.notMatch}</td>
                            <td style={data?.[location]?.outward?.notDone?.match ? linkStyle : {}} onClick={() => data[location].outward.notDone.match && handleOpen("OUT", "Not Done", "match", location)}>{data[location].outward.notDone.match}</td>
                            <td style={data?.[location]?.outward?.notDone?.notMatch ? linkStyle : {}} onClick={() => data[location].outward.notDone.notMatch && handleOpen("OUT", "Not Done", "notMatch", location)}>{data[location].outward.notDone.notMatch}</td>

                            <td style={data?.[location]?.inward?.done?.match ? linkStyle : {}} onClick={() => data[location].inward.done.match && handleOpen("In", "Done", "match", location)}>{data[location].inward.done.match}</td>
                            <td style={data?.[location]?.inward?.done?.notMatch ? linkStyle : {}} onClick={() => data[location].inward.done.notMatch && handleOpen("In", "Done", "notMatch", location)}>{data[location].inward.done.notMatch}</td>
                            <td style={data?.[location]?.inward?.notDone?.match ? linkStyle : {}} onClick={() => data[location].inward.notDone.match && handleOpen("In", "Not Done", "match", location)}>{data[location].inward.notDone.match}</td>
                            <td style={data?.[location]?.inward?.notDone?.notMatch ? linkStyle : {}} onClick={() => data[location].inward.notDone.notMatch && handleOpen("In", "Not Done", "notMatch", location)}>{data[location].inward.notDone.notMatch}</td>
                            <td>
                                {Object.values(data[location].outward.done).reduce((a, b) => a + b, 0) +
                                    Object.values(data[location].outward.notDone).reduce((a, b) => a + b, 0) +
                                    Object.values(data[location].inward.done).reduce((a, b) => a + b, 0) +
                                    Object.values(data[location].inward.notDone).reduce((a, b) => a + b, 0)}
                            </td>
                        </tr>
                    ))}
                    <tr className="grand-total">
                        <td colSpan="2">Grand Total</td>
                        <td>{totals.outwardDoneMatch}</td>
                        <td>{totals.outwardDoneNotMatch}</td>
                        <td>{totals.outwardNotDoneMatch}</td>
                        <td>{totals.outwardNotDoneNotMatch}</td>
                        <td>{totals.inwardDoneMatch}</td>
                        <td>{totals.inwardDoneNotMatch}</td>
                        <td>{totals.inwardNotDoneMatch}</td>
                        <td>{totals.inwardNotDoneNotMatch}</td>
                        <td>
                            {Object.values(totals).reduce((a, b) => a + b, 0)}
                        </td>
                    </tr>
                </tbody>
            </table> :
                <div style={{ height: "40vh", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                    <BounceLoader />
                </div>
            }
        </div>
    );
};

export default BarcodeMatchTable;



const cellStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#fff",
    whiteSpace: "nowrap"
};

const cellStyleHeader = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#D8E1F1",
    whiteSpace: "nowrap"
};

const cellStyleHeaderBold = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#D8E1F1",
    whiteSpace: "nowrap",
    fontWeight: "bold"
};

const containerStyle = {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: 'end'
}

const linkStyle = {
    cursor: "pointer",
    color: 'blue',
    textDecoration: "underline"
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxHeight: "650px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};
