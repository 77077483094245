import React from "react";
import Calagory_Order from "../../assets/Calagory_Order.png";
import Catagory_Service_Request from "../../assets/Catagory_Service_Request.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TaskModalTable from "../city/cityTable/TaskModalTable";
import installation from "../../assets/maintenance.png";
// import refund from "../../assets/cashback.png";
import upgrad from "../../assets/upgrade.png";
import refund from "../../assets/delivery.png"
import replace from "../../assets/replace.png"
import relocation from "../../assets/relocate.png"
import orderTransfer from "../../assets/orderTransfer.png"
import stockTransfer from "../../assets/stockTransfer.png"
import axiosInstance from "../../axios/AxiosInstance";
// import {makeStyles} from '@mui/styles';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// const useStyles = makeStyles((theme) => ({
//     modal: {
//       display: 'flex',
// background :"red"
//     },
//     paper: {
//       backgroundColor: theme.palette.background.paper,
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3),
//     },
//   }));

function Category({ data, setReloard, reloard }) {
  // const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <div className="filter">

      {data.category == "Order" ? (
        <img
          onClick={handleOpen}
          style={{ width: "27px" }}
          src={Calagory_Order}
        />
      ) : (
        data?.subCategory == "Installation" ?
          < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={installation}
          /> : data?.subCategory == "Repair" ? < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={Catagory_Service_Request}
          /> : data?.subCategory == "Pickup and Refund" ? < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={refund}
          /> : data?.subCategory == "Upgrade" ? < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={upgrad}
          /> : data?.subCategory == "Replace" ? < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={replace}
          /> : data?.subCategory == "Relocation" ? < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={relocation}
          /> : data?.subCategory == "Order Transfer" ? < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={orderTransfer}
          /> : data?.subCategory == "Stock Transfer" ? < img
            onClick={handleOpen}
            style={{ width: "27px" }}
            src={stockTransfer}
          /> : null
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      // className={classes.modal}
      // sx={{maxWidth : "none !important", width :"100% !important"}}
      >
        <Box sx={style}>
          <TaskModalTable ticketData={data} setReloard={setReloard} reloard={reloard} />
        </Box>
      </Modal>
    </div>
  );
}

export default Category;
