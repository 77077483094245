import { Box, Stack, Typography, OutlinedInput, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
// import MessageTable from "./messagetable/MessageTable";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
// import FilterMessage from "./messagetable/FilterMessage";
import wh_export_img from "../../assets/wh_export_img.png";
import AuditTrailFilterMessage from "./auditTrailFilterMessage";
import AuditTrailTable from "./auditTrailTable";
import FilterDateModal from "./FilterDateModal";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../../axios/AxiosInstance";

const AuditTrail = () => {
  const [searchParams] = useSearchParams();
  const [showAdd, setShowAdd] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [flag, setFlag] = useState(true);
  const [filters, setFilters] = useState("");
  const [cityname, setCityName] = useState(searchParams.get("city"));
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [tableData, setTableData] = useState(0);
  const [lastPage, setLastPage] = useState(true);
  const [finalPage, setFinalPage] = useState(false);
  const [start, setStart] = useState(1);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);
  const [end, setEnd] = useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  function createData(curr) {
    return { ...curr };
  }
  const exportTicket = async () => {
    if (filters) {

      var newWindow = window.open(`${process.env.REACT_APP_API_URL}${filters}&download=csv`);
    } else {
      var newWindow = window.open(
        `${process.env.REACT_APP_API_URL}api/ticket/auditTrall?city=${cityname}&download=csv`
      );
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
    if (userData) {
      const userRole = userData.role;
      const outArr = userData.out;
      const userPermissions = userRole[0].permissions;

      userPermissions.map((curr, index) => {
        if (outArr[index].key === "message" && curr.create === true) {
          setShowAdd(true);
        }
      });
      console.log(searchParams.get("city"));
    }
  }, []);

  return (
    <>
      <div>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
            marginTop: "60px",
          }}
        >
          Audit Trail
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: "1.5rem",
            backgroundColor: "#fff",
            marginTop: "1rem",
            // display: "flex",
            // gap: "10px",
          }}
        >
          <Stack
            direction="row"
            // justifyContent="space-between"
            justifyContent="flex-start"
            gap="10px"
            alignItems="center"
            sx={{
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            <AuditTrailFilterMessage
              messages={messages}
              setMessages={setMessages}
              setLoading={setLoading}
              createData={createData}
              loading={loading}
              setFilterData={setFilterData}
              city={cityname}
              setCity={setCityName}
              setTotalPage={setTotalPage}
              page={page}
              limit={limit}
              setPage={setPage}
              setFilters={setFilters}
            />
            <FilterDateModal
              flag={flag}
              setFlag={setFlag}
              city={cityname}
              setTableData={setTableData}
              filters={filters}
              setFilters={setFilters}
              setTotalPage={setTotalPage}
              page={page}
              limit={limit}
              setPage={setPage}
              loading={loading}
              setLoading={setLoading}
              setStart={setStart}
              setEnd={setEnd}
              setLastPage={setLastPage}
              setFinalPage={setFinalPage}
              setMessages={setMessages}
              setIsDateFilterApplied={setIsDateFilterApplied}
            />
            <Button
              variant="contained"
              sx={{
                width: "120px",
                height: "40px",
                // marginLeft:"20px",
                backgroundColor: "#000",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem",
                  width: "100px",
                  height: "40px",
                },
              }}
              onClick={exportTicket}
            >
              <img
                style={{
                  marginRight: "0.5rem",
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem",
                  },
                }}
                src={wh_export_img}
                className="wh_export_img"
                alt="export img"
              />
              Export
            </Button>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              marginBottom: "1rem",
              flexWrap: "wrap",
              background: "red",
            }}
          >
            <AuditTrailTable
              messages={messages}
              setMessages={setMessages}
              loading={loading}
              city={cityname}
              setCityName={setCityName}
              setLoading={setLoading}
              createData={createData}
              setTotalPage={setTotalPage}
              totalPage={totalPage}
              start={start}
              end={end}
              filters={filters}
              isDateFilterApplied={isDateFilterApplied}
            />
          </Stack>
        </Box>
      </div>
    </>
  );
};

export default AuditTrail;
