// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manager_container {
    background-color: #F5F5F5;
}

.wh_searchEmailInput,
.wh_selectEmailInput {
    width: 200px;
    height: 40px;
    padding: 0.5rem;
    border-radius: 5px;
    outline:none;
    border: 1px solid rgba(68, 68, 68, 0.6);
    font-family: Poppins,sans-serif;
}

.wh_selectEmailInput {
    width: 120px;
}   

@media screen and (max-width: 600px) {
    .wh_selectEmailInput {
        margin: 0.5rem 0.5rem 0.5rem 0rem!important;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/agent/Agent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,uCAAuC;IACvC,+BAA+B;AACnC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,2CAA2C;IAC/C;AACJ","sourcesContent":[".manager_container {\n    background-color: #F5F5F5;\n}\n\n.wh_searchEmailInput,\n.wh_selectEmailInput {\n    width: 200px;\n    height: 40px;\n    padding: 0.5rem;\n    border-radius: 5px;\n    outline:none;\n    border: 1px solid rgba(68, 68, 68, 0.6);\n    font-family: Poppins,sans-serif;\n}\n\n.wh_selectEmailInput {\n    width: 120px;\n}   \n\n@media screen and (max-width: 600px) {\n    .wh_selectEmailInput {\n        margin: 0.5rem 0.5rem 0.5rem 0rem!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
