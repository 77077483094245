import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import styled from "styled-components";
import axiosInstance from "../../axios/AxiosInstance";
import { toast } from "react-toastify";

export default function BasicTransport({ data, setReloard, reloard }) {
  const [age, setAge] = React.useState("");
  const [adhoc, setadhoc] = React.useState(
    data?.cell?.row?.original?.adhoc_vehicle
  );
  const [checkState, setCheckState] = React.useState(false);




  let disabled = [
    "Completed",
    "Failed",
    "Delivered",
    "Pickup Done",
    "Task Failed",
    "Pickup Failed",
    "Delivery Failed",
  ];

  //   const InputBox = styled.input`
  //   width: 100%;
  //   height: 40px;
  //   padding: 10px;
  //   font-size: 18px;
  // `;
  useEffect(() => { }, [checkState]);

  const handleKeyDown = async (event) => {
    event.stopPropagation();

    if (event.key == "Enter") {
      const body = {
        adhoc_vehicle: event.target.value,
        userId: JSON.parse(localStorage.getItem("wh_tk"))?.data?._id,
      };

      axiosInstance
        .post(`api/ticket/${data?.cell?.row?.original?._id}`, body)
        .then((res) => {
          if (res.data.success) {
            toast.success(res?.data?.data);
            setReloard(++reloard);
            // closeDetail();
          }
          setCheckState(true);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  useEffect(() => {
    setadhoc(data?.cell?.row?.original?.adhoc_vehicle);
  }, [data?.cell?.row?.original?.adhoc_vehicle]);

  const handleChange = async (event) => {
    setAge(event.target.value);
    let body = {
      transport: event.target.value,
      userId: JSON.parse(localStorage.getItem("wh_tk")).data._id,
    };
    const res = await axiosInstance.post(
      `api/ticket/${data.cell.row.original._id}`,
      body
    );
    if (res.status === 200) {
      toast.success("Update successfull");
      setReloard(++reloard);
    } else {
      return alert("Something went wrong !!");
    }
  };
  const inputStyles = {
    padding: "-3px",
  };

  return (
    <Box
      InputProps={{
        style: inputStyles,
      }}
    >
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">Transport</InputLabel>

        <Select
          disabled={disabled.includes(data.cell.row.original.status)}
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={
            age ||
            data?.cell?.row?.original?.transport ||
            data?.cell?.row?.original?.adhoc_vehicle
          }
          onChange={handleChange}
          label="Age"
          sx={{
            width: "200px",
          }}
          defaultValue={true}
        >
          <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            value={null}
          >empty</MenuItem>
          {data?.headers
            ?.filter((item) => item.Header == "Transport")?.[0]
            ?.as?.map((item) => (
              <MenuItem
                onKeyDown={(e) => e.stopPropagation()}
                key={item._id}
                value={item?.["vehicle number"]}
              >
                {item["vehicle number"]}
              </MenuItem>
            ))}
          {/* <MenuItem key={'adhoc'} value={'adhoc'}>{'adhoc'}</MenuItem> */}
          {!!adhoc && (
            <MenuItem
              onKeyDown={(e) => e.stopPropagation()}
              key={adhoc}
              value={adhoc}>
              {adhoc}
            </MenuItem>
          )}
          {
            <TextField
              id="standard-basic"
              defaultValue={adhoc}
              label={adhoc ? adhoc : "Enter Adhoc Vehicle"}
              onKeyDown={handleKeyDown}
            />
          }
          {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <InputBox
              value={data?.cell?.row?.original?.comment}
              onKeyDown={handleKeyDown}
            />
// <MenuItem key={adhoc} value={adhoc}>{adhoc}</MenuItem>
          </div> */}
        </Select>
      </FormControl>
    </Box>
  );
}
