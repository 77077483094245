import React, { useState, useEffect, useMemo } from 'react';
import styles from "./style.module.css";
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import BounceLoader from "react-spinners/BounceLoader";
import DataNotFound from '../../assets/dataNotfound.svg'
import {
    Select,
    MenuItem,
} from "@mui/material";
import axiosInstance from "../../axios/AxiosInstance";
import moment from 'moment';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from "@mui/icons-material/Clear";
import { MarginTwoTone } from '@mui/icons-material';
import * as XLSX from "xlsx";

const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    minHeight: "50vh",
    overflow: 'scroll'
};


const DatePickerContainer = styled.div`
    margin: 20px 0;
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
`;

const Index = () => {

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("scheduled");
    const [statusForTrip, setstatusForTrip] = useState("scheduled");
    const [taskDetails, setTaskDetails] = useState([]);
    const [exportItem, setExportItem] = useState()
    const [jobTypeValue, setjobTypeValue] = useState();
    const [loadingTaskDetails, setLoadingTaskDetails] = useState(false);
    const [agentName, setAgentName] = useState();

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate1, endDate1] = dateRange;

    const handleChange = (event) => {
        setStatus(event.target.value);
        setstatusForTrip(event.target.value == "Assigned" ? "assigned" : "scheduled")
    };

    let CityNames = JSON.parse(localStorage.getItem("wh_tk"))?.data?.warehouses;
    let checkAdmin = JSON.parse(localStorage.getItem("wh_tk"))?.data?.type;


    let house = [
        {
            _id: "6353dd2fc9383308bab3b855",
            name: "Noida",
            createdAt: "2022-10-22T12:08:15.928Z",
            updatedAt: "2022-10-22T12:08:15.928Z",
            __v: 0,
            city: "Noida",
        },
        {
            _id: "6385d82369d7b11ec8dbce2f",
            name: "Pune",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Pune",
        },
        {
            _id: "639ee663a0a2c3856669742c",
            name: "Mumbai",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Mumbai",
        },
        {
            _id: "639ee6aaa0a2c3856669742e",
            name: "Jodhpur",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Jodhpur",
        },
        {
            _id: "639ee6d6a0a2c38566697430",
            name: "Gurgaon",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Gurgaon",
        },
        {
            _id: "639ee6ffa0a2c38566697431",
            name: "Bangalore",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Bangalore",
        },
        {
            _id: "639ee735a0a2c38566697433",
            name: "Hyderabad",
            createdAt: "2022-10-22T06:46:24.580Z",
            updatedAt: "2022-10-22T06:46:24.580Z",
            __v: 0,
            city: "Hyderabad",
        },
    ];

    if (checkAdmin == "admin") {
        CityNames = house;
    }

    const [city, setCity] = useState(
        CityNames && (CityNames[1]?.name ? CityNames[1]?.name : CityNames[0]?.name)
    );

    const handleOrderData = () => {
        if (startDate && endDate) {
            getData()

        }
    }

    const handleDate = (dates) => {
        const [start, end] = dates;
        const date = new Date();

        setStartDate(start);
        setEndDate(end);
    };

    const getData = async (sD, eD) => {
        try {
            setLoading(true)
            const res = await axiosInstance.get(`/api/time-Duration?city=${city}&startDate=${sD ? moment(sD?.toUTCString()).format("YYYY-MM-DD") : moment(startDate?.toUTCString()).format("YYYY-MM-DD")}&endDate=${eD ? moment(eD?.toUTCString()).format("YYYY-MM-DD") : moment(endDate?.toUTCString()).format("YYYY-MM-DD")}`);
            setTableData(res?.data?.data);
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getData()
    }, [city, startDate, endDate])

    const getInitialDate = () => {
        const today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        let dayBeforeYesterday = new Date(today);
        dayBeforeYesterday.setDate(today.getDate() - 2);

        setStartDate(dayBeforeYesterday)
        setEndDate(yesterday);
        getData(dayBeforeYesterday, yesterday)
    }

    useEffect(() => {
        getInitialDate()
    }, [])

    const handleTask = (value, cat) => {
        const data = value.jobTypes.filter((item) => {
            if (item?.jobType == cat) {
                return item
            }
        })
        return data[0]
    }


    function convertMilliseconds(milliseconds) {
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    const totalTime = (value) => {
        const grandTotalTime = value?.jobTypes?.reduce((total, job) => Math.abs(total) + Math.abs(job.totalTime), 0);
        const data = convertMilliseconds(grandTotalTime);

        return data;
    }
    const totalNumberOfTask = (value) => {
        let newarray = [];
        const data = tableData?.map((item) => {
            item?.jobTypes?.map((i) => {
                if (i.jobType == value) {
                    newarray.push(i)
                }
            })
        })
        const totalSum = newarray?.reduce((sum, job) => sum + job.numberOfTasks, 0);
        return totalSum

    }
    const reset = () => {
        setStartDate(null);
        setEndDate(null);
    };


    const totalNumberofTime = (value) => {
        let newarray = [];
        const data = tableData?.map((item) => {
            item?.jobTypes?.map((i) => {
                if (i.jobType == value) {
                    newarray.push(i)
                }
            })
        })
        const totalSum = newarray?.reduce((sum, job) => sum + job.totalTime, 0);
        return convertMilliseconds(totalSum)
    }

    const grandTotalOfTime = useMemo(() => {
        const timearray = [];
        tableData?.forEach((item) => {
            item.jobTypes?.forEach((i) => {
                timearray.push(i?.totalTime);
            });
        });
        const sum = timearray.reduce((acc, num) => acc + num, 0);
        return convertMilliseconds(sum);
    }, [tableData]);



    const exportTicket = async () => {
        const sD = new Date(startDate).getTime();
        const eD = new Date(endDate).getTime();
        console.log(sD, eD)


        let url = `${process.env.REACT_APP_API_URL}api/trip/export?&download=csv&type=${statusForTrip}&`;
        url =
            url +
            `&start=${sD}&end=${eD}&city=${city}`;
        var newWindow = window.open(url);
    };

    const [open, setOpen] = useState(false);

    const convertToISTNew = (timestamp) => {

        if (!timestamp) {
            return "No date provided";  // Return a suitable message for null
        }

        const date = new Date(timestamp);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return "Invalid Date";  // Handle invalid date format
        }
        // Formatting options for IST
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kolkata',  // Timezone for IST
            timeZoneName: 'short',
        };

        // Return the formatted date string in IST
        return date.toLocaleString('en-IN', options);
    };

    const handleOpen = (value, jobType) => {
        console.log(value?.jobTypes, jobType, "From Task Details");

        const data = value?.jobTypes?.filter((item) => {
            return item.jobType == jobType
        })
        console.log(value?.agent, "Hello");
        setAgentName(value?.agent);
        const cleanedObjects = data[0]?.jobData?.map(obj => (
            {
                "Transport": obj?.transport ? obj?.transport : obj?.adhoc_vehicle || "",
                "Status": obj?.status || "",
                "Case Addigned Date": moment(obj?.caseAssignedDate).format("YYYY-MM-DD"),   // Not available in original data
                "Agent Name": value?.agent,  // No field for agent name in original data
                "Case ID": obj.caseId || "",
                "First Name": obj.firstName || "",
                "Last Name": obj.lastName || "",
                "Email": obj.email || "",
                "Sub Category": obj.subCategory || "",
                "Category": obj.category || "",
                "Case URL": obj.caseUrl || "",
                "City": obj.city || "",
                "Invoice URL": obj.invoiceUrl || "",
                "Job Type": obj.jobType || "",
                "Order ID": obj.orderId || "",
                "Subject": obj.subject || "",
                "Ticket Number": obj.ticketNumber || "",
                "Transition Date": moment(obj?.transitionDate).format("YYYY-MM-DD"),
                "Schedule Date": moment(obj?.scheduledDate).format("YYYY-MM-DD"),
                "Schedule Time": moment(obj.scheduledDate).format("HH:mm"),
                "Delivery Date": moment(obj.deliverDate).format("YYYY-MM-DD"),
                "Delivery Time": moment(obj.deliverDate).format("HH:mm"),
                "LOB": obj.lob || "",
                "Start Trip": moment(obj.startTrip).format("HH:mm") || null,
                "End Trip": moment(obj.endTrip).format("HH:mm") || null,
                "Total Time": totalTimeFuntion(obj.startTrip, obj.endTrip),
                "Login Time": convertToISTNew(obj?.loginTime),
                "Logout Time": convertToISTNew(obj?.logoutTime)
            }
        ));

        setTaskDetails(cleanedObjects);
        setOpen(true)

    }



    const handleClose = () => {
        setOpen(false)
    }
    const downloadExcel = (data) => {
        console.log(data, "hello")

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);


        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });


        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });


        const link = document.createElement("a");


        link.href = URL.createObjectURL(blob);


        link.download = "example.xlsx";


        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    function convertMilliseconds(milliseconds) {
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    const totalTimeFuntion = (start, end) => {
        const endDate = new Date(end);
        const startDate = new Date(start);
        const timeDiff = endDate - startDate;
        return convertMilliseconds(timeDiff);
    }



    return (
        <div className={styles.MainContainer}>
            <div className={styles.Heading}> Task Time Duration</div>
            <div className={styles.DateContainer}>
                <DatePickerContainer>
                    <div style={{ display: 'flex', gap: "20px" }}>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDate}
                            id="DashboardInput"
                        />
                        <div>
                            {/* <Select
                                value={city || CityNames[1]?.name}
                                style={{
                                    marginLeft: "3px",
                                    width: "220px",
                                    borderColor: "black",
                                    height: "30px",
                                    padding: "18px"
                                }}
                                type="text"
                                placeholder="Enter City"
                                onChange={(event) => {
                                    setCity(event.target.value);
                                    setPage(1);
                                }}
                            >
                                {CityNames?.map((data, index) => {
                                    return (
                                        <MenuItem key={index} value={data?.name}>
                                            {data?.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select> */}
                            <select style={{ padding: "9px", width: "191px" }} value={city || CityNames[1]?.name}
                                onChange={(event) => {
                                    setCity(event.target.value);
                                    setPage(1);
                                }}>
                                {CityNames?.map((item, key) => {
                                    return <option key={key} value={item?.name}>{item?.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <button onClick={() => exportTicket()} className="TatSubmitbutton">Export</button>
                        <button onClick={() => handleOrderData()} className="TatSubmitbutton">Submit</button>
                    </div>
                </DatePickerContainer>
            </div>
            <div className={styles.TableContainer}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={style1}>
                        <div className={styles.ContainerPop}>
                            <div className={styles.HeadingPop}>
                                Task Details
                            </div>
                            <div className={styles.CrossButton}>
                                <ClearIcon
                                    style={{
                                        cursor: "pointer",
                                        color: "black",
                                    }}
                                    onClick={handleClose}
                                />
                            </div>
                        </div>
                        <div style={{ overflow: 'scroll' }} className="TableVehicleTaskDetails">
                            {!loadingTaskDetails ? <table border="1" cellPadding="10" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th style={cellStyleHeaderBold}>Transport</th>
                                        <th style={cellStyleHeaderBold}>Status</th>
                                        <th style={cellStyleHeaderBold}>Case Add</th>
                                        <th style={cellStyleHeaderBold}>Agent Name</th>
                                        <th style={cellStyleHeaderBold}>Case ID</th>
                                        <th style={cellStyleHeaderBold}>First Name</th>
                                        <th style={cellStyleHeaderBold}>Last Name</th>
                                        <th style={cellStyleHeaderBold}>Email</th>
                                        <th style={cellStyleHeaderBold}>Sub Category</th>
                                        <th style={cellStyleHeaderBold}>Category</th>
                                        <th style={cellStyleHeaderBold}>Case URL</th>
                                        <th style={cellStyleHeaderBold}>City</th>
                                        <th style={cellStyleHeaderBold}>Invoice URL</th>
                                        <th style={cellStyleHeaderBold}>Job Type</th>
                                        <th style={cellStyleHeaderBold}>Order ID</th>
                                        <th style={cellStyleHeaderBold}>Subject</th>
                                        <th style={cellStyleHeaderBold}>Ticket Number</th>
                                        <th style={cellStyleHeaderBold}>Transition Date</th>
                                        <th style={cellStyleHeaderBold}>Schedule Date</th>
                                        <th style={cellStyleHeaderBold}>Schedule Time</th>
                                        <th style={cellStyleHeaderBold}>Delivery Date</th>
                                        <th style={cellStyleHeaderBold}>Delivery Time</th>
                                        <th style={cellStyleHeaderBold}>LOB</th>
                                        <th style={cellStyleHeaderBold}>Start Trip</th>
                                        <th style={cellStyleHeaderBold}> End Trip</th>
                                        <th style={cellStyleHeaderBold}>Total Time</th>
                                        <th style={cellStyleHeaderBold}>Login Time</th>
                                        <th style={cellStyleHeaderBold}>Logout Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {taskDetails?.map((row, index) => (
                                        <tr key={index}>
                                            <td style={cellStyle}>{row?.Transport ? row?.Transport : row['vAdhoc Vehicle']}</td>
                                            <td style={cellStyle}>{row?.Status}</td>
                                            <td style={cellStyle}>{row['Case Addigned Date']}</td>
                                            <td style={cellStyle}>{agentName}</td>
                                            <td style={cellStyle}>{row['Case ID']}</td>
                                            <td style={cellStyle}>{row['First Name']}</td>
                                            <td style={cellStyle}>{row['Last Name']}</td>
                                            <td style={cellStyle}>{row?.Email}</td>
                                            <td style={cellStyle}>{row["Sub Category"]}</td>
                                            <td style={cellStyle}> {row?.Category}</td>
                                            <td style={cellStyle}><a href={row['Case URL']}>Link</a></td>
                                            <td style={cellStyle}>{row?.City}</td>
                                            <td style={cellStyle}><a href={row.invoiceUrl}>Invoice</a></td>
                                            <td style={cellStyle}>{row['Job Type']}</td>
                                            <td style={cellStyle}>{row['Order ID']}</td>
                                            <td style={cellStyle}>{row['Subject']}</td>
                                            <td style={cellStyle}>{row['Ticket Number']}</td>
                                            <td style={cellStyle}>{row['Transition Date']}</td>
                                            <td style={cellStyle}>{row['Schedule Date']}</td>
                                            <td style={cellStyle}>{row['Schedule Time']}</td>
                                            <td style={cellStyle}>{row['Delivery Date']}</td>
                                            <td style={cellStyle}>{row['Delivery Time']}</td>
                                            <td style={cellStyle}>{row?.LOB}</td>
                                            <td style={cellStyle}>{row["Start Trip"]}</td>
                                            <td style={cellStyle}>{row['End Trip']}</td>
                                            <td style={cellStyle}>{row["Total Time"]}</td>
                                            <td style={cellStyle}>{row["Login Time"]}</td>
                                            <td style={cellStyle}>{row["Logout Time"]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> : <div className={styles.LoaderContainer}> <BounceLoader /></div>}
                        </div>
                        <div style={containerStyle}>
                            {/* <button onClick={() => exportTaskDetails()} className="TatSubmitbutton">Export</button> */}
                            <button onClick={() => downloadExcel(taskDetails)} className="TatSubmitbutton">Export</button>
                        </div>
                    </Box>
                </Modal>
                <div style={{ overflow: 'scroll' }}>
                    {!loading ? <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <th colSpan="50" style={cellStyleHeader}>Task</th>
                            </tr>
                            <tr>
                                <th rowSpan="2" style={cellStyleHeader}>Transport</th>
                                <th rowSpan="2" style={cellStyleHeader}>Agent Name</th>
                                <th colSpan="2" style={cellStyleHeader}>B2B</th>
                                <th colSpan="2" style={cellStyleHeader}>New - Rental</th>
                                <th colSpan="2" style={cellStyleHeader}>Pickup and Refund</th>
                                <th colSpan="2" style={cellStyleHeader}>Relocation</th>
                                <th colSpan="2" style={cellStyleHeader}>Repair</th>
                                <th colSpan="2" style={cellStyleHeader}>Upgrade</th>
                                <th colSpan="2" style={cellStyleHeader}>Replace</th>
                                <th colSpan="2" style={cellStyleHeader}>Installation</th>
                                <th colSpan="2" style={cellStyleHeader}>New - Buy</th>
                                <th rowSpan="2" style={cellStyleHeader}>Grand Total</th>
                            </tr>
                            <tr>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                                <th style={cellStyleHeader}>No. of Task</th>
                                <th style={cellStyleHeader}>Total Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((item, index) => {
                                return <tr key={index}>
                                    <td style={cellStyle}>{item?.transport}</td>
                                    <td style={cellStyle}>{item?.agent}</td>
                                    <td onClick={() => handleTask(item, "B2B")?.numberOfTasks && handleOpen(item, "B2B")} style={handleTask(item, "B2B")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "B2B")?.numberOfTasks ? handleTask(item, "B2B")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "B2B")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "B2B")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "B2B")?.totalTime) : 0
                                    }
                                    </td>

                                    <td onClick={() => handleTask(item, "New - Rental")?.numberOfTasks && handleOpen(item, "New - Rental")} style={handleTask(item, "New - Rental")?.numberOfTasks ? cellStyleActive : cellStyle}> {handleTask(item, "New - Rental")?.numberOfTasks ? handleTask(item, "New - Rental")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "New - Rental")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "New - Rental")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "New - Rental")?.totalTime) : 0
                                    }</td>

                                    <td onClick={() => handleTask(item, "Pickup and Refund")?.numberOfTasks && handleOpen(item, "Pickup and Refund")} style={handleTask(item, "Pickup and Refund")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "Pickup and Refund")?.numberOfTasks ? handleTask(item, "Pickup and Refund")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Pickup and Refund")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Pickup and Refund")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Pickup and Refund")?.totalTime) : 0
                                    }</td>

                                    <td onClick={() => handleTask(item, "Relocation")?.numberOfTasks && handleOpen(item, "Relocation")} style={handleTask(item, "Relocation")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "Relocation")?.numberOfTasks ? handleTask(item, "Relocation")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Relocation")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Relocation")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Relocation")?.totalTime) : 0
                                    }</td>

                                    <td onClick={() => handleTask(item, "Repair")?.numberOfTasks && handleOpen(item, "Repair")} style={handleTask(item, "Repair")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "Repair")?.numberOfTasks ? handleTask(item, "Repair")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Repair")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Repair")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Repair")?.totalTime) : 0
                                    }</td>
                                    <td onClick={() => handleTask(item, "Upgrade")?.numberOfTasks && handleOpen(item, "Upgrade")} style={handleTask(item, "Upgrade")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "Upgrade")?.numberOfTasks ? handleTask(item, "Upgrade")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Upgrade")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Upgrade")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Upgrade")?.totalTime) : 0
                                    }</td>
                                    <td onClick={() => handleTask(item, "Replace")?.numberOfTasks && handleOpen(item, "Replace")} style={handleTask(item, "Replace")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "Replace")?.numberOfTasks ? handleTask(item, "Replace")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Replace")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Replace")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Replace")?.totalTime) : 0
                                    }</td>
                                    <td onClick={() => handleTask(item, "Installation")?.numberOfTasks & handleOpen(item, "Installation")} style={handleTask(item, "Installation")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "Installation")?.numberOfTasks ? handleTask(item, "Installation")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "Installation")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "Installation")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "Installation")?.totalTime) : 0
                                    }</td>
                                    <td onClick={() => handleTask(item, "New - Buy")?.numberOfTasks && handleOpen(item, "New - Buy")} style={handleTask(item, "New - Buy")?.numberOfTasks ? cellStyleActive : cellStyle}>{handleTask(item, "New - Buy")?.numberOfTasks ? handleTask(item, "New - Buy")?.numberOfTasks : 0}</td>
                                    <td style={cellStyle}>{handleTask(item, "New - Buy")?.totalTime
                                        // && moment(Math.abs(handleTask(item, "New - Buy")?.totalTime) * 1000).format('HH:mm:ss')
                                        ? convertMilliseconds(handleTask(item, "New - Buy")?.totalTime) : 0
                                    }</td>
                                    <td style={cellStyle}>{totalTime(item)}</td>
                                </tr>
                            })}
                        </tbody>
                        <tfoot>
                            <tr style={{ backgroundColor: "#d9d9d9" }}>
                                <th colSpan="2" style={cellStyleHeader}>Grand Total</th>
                                <td style={cellStyleHeader}>{totalNumberOfTask("B2B")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("B2B")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("New - Rental")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("New - Rental")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("Pickup and Refund")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("Pickup and Refund")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("Relocation")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("Relocation")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("Repair")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("Repair")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("Upgrade")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("Upgrade")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("Replace")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("Replace")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("Installation")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("Installation")}</td>
                                <td style={cellStyleHeader}>{totalNumberOfTask("New - Buy")}</td>
                                <td style={cellStyleHeader}>{totalNumberofTime("New - Buy")}</td>
                                <td style={cellStyleHeaderBold}>{grandTotalOfTime}</td>
                            </tr>
                        </tfoot>
                    </table> :
                        <div style={{ height: "40vh", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                            <BounceLoader />
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default Index;



const cellStyle = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#fff",
    whiteSpace: "nowrap",
    cursor: "pointer"
};
const cellStyleActive = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#fff",
    whiteSpace: "nowrap",
    cursor: "pointer",
    color: 'blue',
    textDecoration: "underline"
};
const cellStyleHeader = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#D8E1F1",
    whiteSpace: "nowrap"
};
const cellStyleHeaderBold = {
    border: "1px solid black",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#D8E1F1",
    whiteSpace: "nowrap",
    fontWeight: "bold"
};
const containerStyle = {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "end",
    justifyContent: 'end'
}
