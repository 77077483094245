import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import axiosInstance from '../../../axios/AxiosInstance';
import Loading from '../../../components/loading/Loading';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ManagerEditModal from "./ManagerEditModal"
import BounceLoader from "react-spinners/BounceLoader";
const columns = [
    {
        id: 'name',
        label: 'Name',
        minWidth: 170
    },
    // {
    //     id: '_id',
    //     label: 'ID',
    //     minWidth: 100
    // },
    {
        id: 'email',
        label: 'Email',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'phone',
        label: 'Phone',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'warehouses',
        label: 'City',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'mode',
        label: 'Online/Offline',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 'updaterole',
        label: 'Update Role',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 'edit',
        label: 'Edit details',
        minWidth: 100,
        align: 'center',
    },
];


export default function ManagerTable({
    loading,
    setLoading,
    managers,
    setManagers,
    createData
}) {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(100);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [update, setUpdate] = useState(false);
    const [flag, setFlag] = useState(true);

    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleUpdate = (row) => {
        // console.log(row);
        navigate('/updateroleother', { state: { row: row } });
    }

    const getManagers = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/api/manager`);
            if (response.status === 200) {
                setLoading(false);
                let allManagers = response.data.data;
                allManagers = allManagers.map((manager) => {
                    return createData(
                        manager
                    )
                });
                setManagers(allManagers);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    useEffect(() => {
        getManagers();
        const userData = JSON.parse(localStorage.getItem('wh_tk'))?.data;
        if (userData) {
            const userRole = userData.role;
            const outArr = userData.out;
            const userPermissions = userRole[0].permissions;
            userPermissions.map((curr, index) => {
                if (outArr[index].key === 'manager' && curr.update === true) {
                    setUpdate(true);
                }
            })
        }

    }, [flag]);

    return (
        <Paper sx={{
            width: '100%',
            overflow: 'hidden',
            boxShadow: 'none'
        }}>
            <TableContainer sx={{ maxHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{
                        backgroundColor: '#F2F4FF'
                    }} >
                        <TableRow>
                            {columns?.map((column) => {

                                if (column.id === 'updaterole' || column.id === 'edit') {
                                    if (update === true) {
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, backgroundColor: '#F2F4FF' }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        )
                                    }
                                }
                                else {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#F2F4FF' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ?
                                <div style={{ width: "90vw" }} className="LoadingContainer"><BounceLoader /> </div> :
                                managers && managers?.length > 0 ?
                                    managers
                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        ?.map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {columns?.map((column) => {
                                                        const value = row[column.id];

                                                        if (column.id === 'updaterole') {
                                                            if (update === true) {
                                                                return (
                                                                    <TableCell sx={{ border: 'none' }} key={column.id} align={column.align}>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{
                                                                                height: "40px",
                                                                                backgroundColor: '#000',
                                                                                color: '#fff',
                                                                                // marginTop:"10px",
                                                                                fontSize: "0.875rem !important",
                                                                                '&:hover': {
                                                                                    backgroundColor: '#000',
                                                                                    opacity: '0.8'
                                                                                }
                                                                            }}
                                                                            onClick={() => { handleUpdate(row) }}
                                                                        >
                                                                            Update
                                                                        </Button>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        }
                                                        else if (column.id === 'mode') {
                                                            return (
                                                                <TableCell sx={{ border: 'none' }} key={column.id} align={column.align}>
                                                                    {row.status === true ? "Online" : "Offline"}
                                                                </TableCell>
                                                            )
                                                        }
                                                        else if (column.id === 'edit') {
                                                            if (update === true) {
                                                                return (
                                                                    <TableCell sx={{ border: 'none' }} key={column.id} align={column.align}>
                                                                        <ManagerEditModal flag={flag} setFlag={setFlag} id={row._id} />
                                                                    </TableCell>
                                                                )
                                                            }
                                                        }

                                                        return (
                                                            <TableCell sx={{
                                                                border: 'none'
                                                            }} key={column.id} align={column.align}>
                                                                {
                                                                    column.id === 'warehouses' ?
                                                                        value?.map((warehouse) => {
                                                                            return (
                                                                                <Typography key={warehouse.city} style={{
                                                                                    // width: '100%',
                                                                                    // margin: '0rem auto',
                                                                                    // borderRadius: '5px',
                                                                                    // padding: '0.3rem',
                                                                                    // marginBottom:'0.5rem',
                                                                                    // border:"1px solid grey"
                                                                                }} >
                                                                                    {warehouse.city}
                                                                                </Typography>
                                                                            )
                                                                        })
                                                                        : value
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })
                                    : <TableRow>
                                        <Typography sx={{ padding: '1rem' }} >No Data Found</Typography>
                                    </TableRow>

                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={managers?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
