import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import "react-datepicker/dist/react-datepicker.css";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as momenttimezone from "moment-timezone";
import axiosInstance from "../../../axios/AxiosInstance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "black",
};


let disabled = ["Completed", "Failed", "Delivered", "Pickup Done", "Task Failed", "Pickup Failed", "Delivery Failed"]
export default function BasicModal({ data, setReloard, reloard, open, setOpen }) {

  let subcategory = data?.subcategory


  const handleClose = () => setOpen(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [statusItems, setStatusItems] = useState([]);
  const [status, setstatus] = useState("");
  const [edit, setEdit] = useState(false);

  const handleOpen = () => {


  };
  const userid = JSON.parse(localStorage.getItem("wh_tk"))?.data?._id;
  useEffect(() => {
    setSelectedDate(data?.scheduledDate ? dateMap(data.scheduledDate) : new Date());
    setSatus(data)
  }, [data?.status])
  function dateMap(date) {
    date = new Date(date)
    let finalDate = null
    let today = new Date().getDate()
    let todayFullDate = new Date()
    let scheduledDate = null
    if (date) {
      scheduledDate = new Date(date).getDate()
    }
    if (today == scheduledDate) {
      finalDate = new Date()
    }
    if ((today - scheduledDate) == 1) {
      finalDate = todayFullDate.setDate(todayFullDate.getDate() - 1);
    }
    if ((today - scheduledDate) == 2) {
      finalDate = todayFullDate.setDate(todayFullDate.getDate() - 2);
    }
    if (scheduledDate > today) {
      finalDate = date
    }

    return finalDate
  }

  function setSatus(params) {
    if (
      params.status === "Pending" ||
      params.status === "Pickup Pending" ||
      params.status === "Order Verified"
    ) {

      if (params.subCategory === "New - Rental" || params.subCategory === "New - Buy" || params.subCategory === "B2B") {

        setStatusItems(new_rentalItems);
      } else if (params.subCategory === "Pickup and Refund") {
        setStatusItems(pickrefItems);
      } else {
        setStatusItems(else_items);
      }
    }
    else {
      if (params.subCategory === "New - Rental" || params.subCategory === "New - Buy" || params.subCategory === "B2B") {
        setStatusItems(new_rentaldelivery);
      } else if (params.subCategory === "Pickup and Refund") {
        setStatusItems(pickrdeliveryItems);
      } else {
        setStatusItems(else_delivery_items);
      }
    }

  }

  const options = [
    { key: 1, text: "Choice 1", value: 1 },
    { key: 2, text: "Choice 2", value: 2 },
    { key: 3, text: "Choice 3", value: 3 },
  ];
  const handleDateChange = (date) => {

    setSelectedDate(date);
  };
  const pickrdeliveryItems = [
    {
      value: "Pickup Done",
      text: "Pickup Done",
    },
    {
      value: "Pickup Pending",
      text: "Pickup Rescheduled",
    },
  ];

  const new_rentaldelivery = [
    {
      value: "Delivered",
      text: "Delivered",
    },
    {
      value: "Order Verified",
      text: "Delivery Rescheduled",
    },
  ];

  const pickrefItems = [
    {
      value: "Pickup Scheduled",
      text: "Pickup Scheduled",
    },
  ];

  const new_rentalItems = [
    {
      value: "Delivery Scheduled",
      text: "Delivery Scheduled",
    },
  ];

  const else_delivery_items = [
    {
      value: "Completed",
      text: "Completed",
    },
    {
      value: "Pending",
      text: "Rescheduled",
    },
  ];

  const else_items = [
    {
      value: "Scheduled",
      text: "Scheduled",
    },
  ];

  const newCandleChange = (event) => {
    setstatus(event.target.value)
  }

  const handleChange = async () => {
    try {
      setEdit(true);
      const inputDatetime = momenttimezone.tz(selectedDate, 'America/New_York');
      let newStatus = ["Order Verified", "Pending", "Pickup Pending"].includes(status) ? true : false
      const utcDatetime = inputDatetime.utc().toISOString();
      let body = {
        caseId: data.caseId,
        rescheduled: newStatus,
        scheduledDate: utcDatetime,
        status: status,
        userId: userid
      }

      if (status == "Delivered" || status == "Pickup Done" || status == "Completed") {
        body.transport = data.transport
        body.agentId = data.agentId
        delete body.scheduledDate
        body.deliverDate = utcDatetime
      }
      if (
        data.adhoc_vehicle
      ) {
        body.adhoc_vehicle = data.adhoc_vehicle
      }

      if (newStatus) {
        delete body.caseId
        delete body.scheduledDate
      }
      if (
        status == "Delivered" ||
        status == "Pickup Done" ||
        status == "Completed" ||
        status == "Delivery Failed" ||
        status == "Task Failed" ||
        status == "Pending" ||
        status == "Order Verified" ||
        status == "Pickup Pending" ||
        status == "Pickup Failed"
      ) {
        if (data.adhoc_vehicle && data.agentId || data.agentId && data.transpor !== "-") {

          if (data.category == "Order" && status == "Delivered") {
            body.delivery_successful = true;
          } else if (
            data.category == "Service Request" &&
            status == "Pickup Done"
          ) {
            body.pickup_done_confirmation = true;
          } else if (
            data.category == "Service Request" &&
            status == "Completed"
          ) {
            body.replacement_upgrade_done = true;
          }

          const res = await axiosInstance.post(`api/ticket/${data._id}`, body);
          if (res.status === 200) {
            setOpen(false)
            toast.success("Update successfull");
            setReloard(++reloard)
          } else {
            return alert("Something went wrong !!");
          }
        } else {
          toast.warning("Please Select Agent and Transpor");
        }
      } else {
        const res = await axiosInstance.post(`api/ticket/${data._id}`, body);
        if (res.status === 200) {
          setOpen(false)
          toast.success("Update successfull");
          setReloard(++reloard)
        } else {
          return alert("Something went wrong !!");
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex" }}>
            <Box >
              <p> Select Date</p>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                showTimeSelect
                minDate={selectedDate}
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="abc"
              />
            </Box>
            <Box sx={{ minWidth: 70, marginLeft: "10px", marginTop: "9px" }}>
              <FormControl fullWidth   >
                {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  status
                </InputLabel> */}
                <Select
                  labelId="demo-select-small-label"
                  id="status-select"
                  value={status}
                  label="Age"
                  onChange={newCandleChange}
                // label="Select"
                // style={{
                //   textAlign: 'center'
                // }}
                >
                  {
                    statusItems?.map((data, index) => {
                      return <MenuItem key={index} value={data.value}>{data.text}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Button
            disabled={edit}
            variant="contained"
            sx={{
              marginTop: "15px",
              whiteSpace: "nowrap",
              height: "30px",
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "13px !important",
              "&:hover": {
                backgroundColor: "#000",
                opacity: "0.8",
              },
              "@media (max-width: 785px)": {
                fontSize: "0.6rem !important",
                // width:"60px",
                height: "30px",
              },
            }}
            onClick={handleChange}
          >
            Submit
          </Button>
        </Box>

      </Modal>
    </div>
  );
}
