import { Box, Stack, Typography, OutlinedInput, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './Customers.css';
import AddIcon from '@mui/icons-material/Add';
import CustomersTable from './customersTable/CustomersTable';
import SearchIcon from '@mui/icons-material/Search';
import wh_export_img from '../../assets/wh_export_img.png';
import { useNavigate } from 'react-router-dom';
import FilterCustomer from "./FilterCustomer";

function Customers() {

  const navigate = useNavigate();
  const authData = localStorage.getItem("wh_tk");
  useEffect(() => {
    if (!authData) {
      navigate("/login", { replace: true });
    }
  }, [])
  const [showAdd, setShowAdd] = useState(false);
  const [flag, setFlag] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [filters, setFilters] = useState('');
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState(0);

  function createData(name, id, email, phone, city, mode) {
    return { name, id, email, phone, city, mode };
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('wh_tk'))?.data;

    if (userData) {
      const userRole = userData?.role;
      const outArr = userData?.out;
      const userPermissions = userRole[0]?.permissions;

      userPermissions?.map((curr, index) => {
        if (outArr[index].key === 'customers' && curr.create === true) {
          setShowAdd(true);
        }
      })
    }
    // console.log(userData);

  }, [])

  return (
    <div >
      <Typography sx={{
        fontSize: '1.5rem',
        fontWeight: '700',
        marginTop:"30px"
      }} >Customers</Typography>
      <Box sx={{
        width: '100%',
        height: '100%',
        // padding: '1.5rem',
        backgroundColor: '#fff',
        marginTop: '1rem'
      }} >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            marginBottom: '1rem',
            flexWrap: 'wrap'
          }} spacing={1} >
          {/* <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              flexWrap:'wrap'
            }} >
            <Stack 
              className="wh_searchEmailInput"
              direction='row'
              alignItems='center' >
              <SearchIcon sx={{
                color:'#8F8F8F',
              }} />
              <input
                style={{
                  border: 'none',
                  outline: 'none',
                  fontFamily:'Poppins, sans-serif',
                  padding:'0.1rem'
                }}
                placeholder="Search customer..."
              />
            </Stack>
            <select className="wh_selectEmailInput" >
              <option>Customer ID</option>
            </select>
            <select className="wh_selectEmailInput" >
              <option>Exact Search</option>
            </select>
          </Stack> */}
          <Stack sx={{
            width: window.innerWidth > 600 ? '40%' : '100%'
          }} direction='row'
            spacing={2}
            justifyContent='flex-start' >

            {showAdd &&
              <Button
                variant="contained"
                sx={{
                  width: '200px',
                  height: "40px",
                  backgroundColor: '#000',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#000',
                    opacity: '0.8'
                  }
                }}
                onClick={() => { navigate('/customers/add_customers') }}
              >
                <AddIcon sx={{
                  marginRight: '0.5rem'
                }} />
                Add Customers
              </Button>
            }
            {/* <Button
              variant="contained"
              sx={{
                width:'120px',
                height: '40px',
                marginLeft:"20px",
                backgroundColor: '#000',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#000',
                  opacity: '0.8'
                }
              }} >
              <img src={wh_export_img} className="wh_export_img" alt="export img" />
              Export
            </Button> */}
            <FilterCustomer flag={flag} setFlag={setFlag} setCustomers={setCustomers} createData={createData} filters={filters} setFilters={setFilters} limit={limit} setTotalPage={setTotalPage} />
          </Stack>
        </Stack>
        <CustomersTable flag={flag}
          setFlag={setFlag}
          customers={customers}
          setCustomers={setCustomers}
          createData={createData}
          filters={filters}
          limit={limit}
          setLimit={setLimit}
          totalPage={totalPage}
          setTotalPage={setTotalPage}
        />
      </Box>
    </div>
  )
}

export default Customers;