import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Select from "../../../components/select/Select";
import axiosInstance from "../../../axios/AxiosInstance";
import Input from "../../../components/input/Input";
import { Stack } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import ClearIcon from "@mui/icons-material/Clear";
import Loading from "../../../components/loading/Loading";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { FormControlLabel, Checkbox } from "@mui/material";
import { Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: 330,
  },
};

const items = [
  {
    value: "ASC",
    text: "ASC",
  },
  {
    value: "DESC",
    text: "DESC",
  },
];

const ColumnSelector = ({checkedColumns, setCheckedColumns, allColumns,columnSelector}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const savedColumns = Object.keys(columnSelector);
  const UserData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
  const sendColumnData = async (UserData,newColumnsObj)=>{
    try {
      const result = await axiosInstance.post('api/user/column/selector',{
        "user_id":UserData?._id,
        "column_selector":newColumnsObj
      });
      //  console.log(result);
       if(result.status===200){
        const newUserData = {
          "data":{...UserData,column_selector:newColumnsObj},
          "success":true
        };
        localStorage.setItem("wh_tk", JSON.stringify(newUserData));
        setCheckedColumns(newColumnsObj);
       }
    } catch (error) {
        alert('Something wrong happend!')
    }
}

  const handleChange = (event) => {
    const currentValue = event.target.value;
    // if (checkedColumns.hasOwnProperty(currentValue)) {
      sendColumnData(UserData,{
        ...checkedColumns,
        [currentValue]:event.target.checked
      })
      setCheckedColumns({
        ...checkedColumns,
        [currentValue]:event.target.checked
      });

    // } else {
      // setCheckedColumns([...checkedColumns, currentValue]);
    // }
  };
  return (
    <div>
      <Button
        variant="contained"
        sx={{
         whiteSpace:"nowrap",
          height: "40px",
          backgroundColor: "#000",
          color: "#fff",
          fontSize: "0.875rem !important",
          "&:hover": {
            backgroundColor: "#000",
            opacity: "0.8",
          },
          "@media (max-width: 785px)": {
            fontSize: "0.6rem !important",
            height: "30px",
          },
        }}
        onClick={handleOpen}
      >
        <ViewColumnIcon
          sx={{
            whiteSpace:'nowrap',
            marginRight: "10px",
            "@media (max-width: 785px)": {
              fontSize: "1rem !important",
            },
          }}
        />
        Column Selector
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon
            sx={{
              position: "absolute",
              right: "-30px",
              top: "-22px",
              cursor: "pointer",
              color: "white",
              "@media (max-width: 400px)": {
                right: "10px",
                top: "10px",
                color: "black",
              },
            }}
            onClick={handleClose}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select columns to show
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack direction="column">
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  marginBottom: "50px",
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      {savedColumns?.map((column) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checkedColumns.hasOwnProperty(column)? checkedColumns[column] :true}
                                onChange={handleChange}
                                value={column}
                                sx={{
                                  color: "#000",
                                  "&.Mui-checked": {
                                    color: "#000",
                                  },
                                }}
                              />
                            }
                            label={column}
                            color="primary"
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
              {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={getdata}
                >
                  {loading1 ? <Loading /> : "Ok"}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "40px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem!important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                  }}
                  onClick={reset}
                >
                  {loading2 ? <Loading /> : "Reset"}
                </Button>
              </Stack> */}
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ColumnSelector;
