import axios from "axios";

const base_url_prod = "https://erp.cityfurnish.com/";
const base_utl_test = "https://erpstaging.cityfurnish.com/"




export const getDetails_purchase_recieve_tracker_pick = async (poNumber) => {

    const url = `https://erp.cityfurnish.com/cityfurnish/purchase/recieve_tracker_pick`;
    const data = {
        params: {
            purchaseorder: [
                {
                    name: poNumber
                },
            ],
        },
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log(response.data?.result, "hello From PO");

        return response.data?.result;  // Return the data part of the response
    } catch (error) {
        console.error('Error in API call:', error);
        throw error; // Rethrow the error to handle it properly where the function is called
    }
}





export const getDetails_internal_transfer_tracker_out = async (internaltransferNumber) => {

    const url = `https://erp.cityfurnish.com/cityfurnish/internal/internal_transfer_tracker_out`;
    const data = {
        params: {
            internaltransfer: [
                {
                    name: internaltransferNumber
                },
            ],
        },
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data?.result;  // Return the data part of the response
    } catch (error) {
        console.error('Error in API call:', error);
        throw error; // Rethrow the error to handle it properly where the function is called
    }
}


export const getDetails_internal_transfer_tracker_in = async (internaltransferNumber) => {
    const url = `https://erp.cityfurnish.com/cityfurnish/internal/internal_transfer_tracker_in`;
    const data = {
        params: {
            internaltransfer: [
                {
                    name: internaltransferNumber
                },
            ],
        },
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data?.result;  // Return the data part of the response
    } catch (error) {
        console.error('Error in API call:', error);
        throw error; // Rethrow the error to handle it properly where the function is called
    }

}



export const getDetails_refurb_tracker_in = async (internaltransferNumber) => {

    const url = `${base_url_prod}cityfurnish/refurb/refurb_tracker_in`;
    const data = {
        params: {
            refurb: [
                {
                    name: internaltransferNumber
                },
            ],
        },
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; // Return the data part of the response
    } catch (error) {
        console.error('Error in API call:', error);
        throw error; // Rethrow the error to handle it properly where the function is called
    }
}

export const getDetails_refurb_tracker_out = async (internaltransferNumber) => {
    const url = `${base_url_prod}cityfurnish/refurb/refurb_tracker_out`;
    const data = {
        params: {
            refurb: [
                {
                    name: internaltransferNumber
                },
            ],
        },
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data; // Return the data part of the response
    } catch (error) {
        console.error('Error in API call:', error);
        throw error; // Rethrow the error to handle it properly where the function is called
    }
};

