// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manager_container {
    background-color: #F5F5F5;
}

.wh_searchEmailInput,
.wh_selectEmailInput {
    width: 200px;
    height: 40px;
    padding: 0.5rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(68, 68, 68, 0.6);
    font-family: Poppins, sans-serif;
}

.wh_selectEmailInput {
    width: 120px;
}

@media screen and (max-width: 600px) {
    .wh_selectEmailInput {
        margin: 0.5rem 0.5rem 0.5rem 0rem !important;
    }
}

.ContainerPopAgent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.HeadingPopAgent {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.CrossButtonAgent {}

.AddContainerAgent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
}

.PhoneNameAgent {
    padding: 10px;
    border-radius: 10px;
}

.PhoneNumberAgent {
    padding: 10px;
    border-radius: 10px;
}

.CreateAgentButton {
    padding: 10px;
    margin-top: 30px;
    border-radius: 10px;
    background-color: #000;
    color: white;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/agent/Agent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,uCAAuC;IACvC,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,4CAA4C;IAChD;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA,mBAAmB;;AAEnB;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".manager_container {\n    background-color: #F5F5F5;\n}\n\n.wh_searchEmailInput,\n.wh_selectEmailInput {\n    width: 200px;\n    height: 40px;\n    padding: 0.5rem;\n    border-radius: 5px;\n    outline: none;\n    border: 1px solid rgba(68, 68, 68, 0.6);\n    font-family: Poppins, sans-serif;\n}\n\n.wh_selectEmailInput {\n    width: 120px;\n}\n\n@media screen and (max-width: 600px) {\n    .wh_selectEmailInput {\n        margin: 0.5rem 0.5rem 0.5rem 0rem !important;\n    }\n}\n\n.ContainerPopAgent {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.HeadingPopAgent {\n    font-size: 16px;\n    font-weight: bold;\n    margin-bottom: 10px;\n}\n\n.CrossButtonAgent {}\n\n.AddContainerAgent {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding-top: 20px;\n}\n\n.PhoneNameAgent {\n    padding: 10px;\n    border-radius: 10px;\n}\n\n.PhoneNumberAgent {\n    padding: 10px;\n    border-radius: 10px;\n}\n\n.CreateAgentButton {\n    padding: 10px;\n    margin-top: 30px;\n    border-radius: 10px;\n    background-color: #000;\n    color: white;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
