import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Typography } from '@mui/material';
import axiosInstance from '../../../axios/AxiosInstance';
import Loading from '../../../components/loading/Loading';
import { useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
const columns = [
    { id: 'id', label: 'Customer ID', minWidth: 100 },
    { id: 'name', label: 'Name', minWidth: 170 },
    {
        id: 'phone',
        label: 'Phone',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'email',
        label: 'Email',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'city',
        label: 'Address',
        minWidth: 170,
        align: 'left',
    },
    // {
    //     id: 'action',
    //     label: 'Action',
    //     minWidth: 100,
    //     align: 'center',
    // },
];

export default function CustomersTable({ flag, setFlag, customers, setCustomers, createData, filters, limit, setLimit, totalPage, setTotalPage }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);

        setLoading(true);
        const response = await axiosInstance.get(`/api/customer?page=${newPage + 1}&limit=${limit}${filters}`);
        console.log(response);
        if (response.status === 200) {
            setLoading(false);
            setTotalPage(response.data.count);
            let allCustomers = response.data.data;
            allCustomers = allCustomers.map((customer) => {
                let customerName = "";
                if (customer.firstName) customerName += customer.firstName + " ";
                if (customer.lastName) customerName += customer.lastName;
                return createData(
                    customerName,
                    customer.cf.cf_customer_id ? customer.cf.cf_customer_id : "-",
                    customer.email,
                    customer.phone,
                    customer.city ? customer.street + ", " + customer.state + ", " + customer.city + ", " + customer.country + ", " + customer.zip : "-"
                )
            })
            setCustomers(allCustomers);
        }
    };

    const handleChangeRowsPerPage = async (event) => {
        // console.log(event.target.value);
        setRowsPerPage(event.target.value);
        setPage(0);
        setLimit(event.target.value);

        setLoading(true);
        const response = await axiosInstance.get(`/api/customer?page=${1}&limit=${event.target.value}${filters}`);
        // console.log(response);
        if (response.status === 200) {
            setLoading(false);
            setTotalPage(response.data.count);
            let allCustomers = response.data.data;
            allCustomers = allCustomers.map((customer) => {
                let customerName = "";
                if (customer.firstName) customerName += customer.firstName + " ";
                if (customer.lastName) customerName += customer.lastName;
                return createData(
                    customerName,
                    customer.cf.cf_customer_id ? customer.cf.cf_customer_id : "-",
                    customer.email,
                    customer.phone,
                    customer.city ? customer.street + ", " + customer.state + ", " + customer.city + ", " + customer.country + ", " + customer.zip : "-"
                )
            })
            setCustomers(allCustomers);
        }
    };

    const getDetails = (name) => {
        navigate(`/city/${name}`, { state: { Cityname: name, name: name } });
    }

    const getAllCustomers = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/api/customer?page=${page + 1}&limit=${limit}`);
            if (response.status === 200) {
                setLoading(false);
                setTotalPage(response.data.count);
                let allCustomers = response.data.data;
                // console.log(allCustomers);
                allCustomers = allCustomers.map((customer) => {
                    let customerName = "";
                    if (customer.firstName) customerName += customer.firstName + " ";
                    if (customer.lastName) customerName += customer.lastName;
                    return createData(
                        customerName,
                        customer.cf.cf_customer_id ? customer.cf.cf_customer_id : "-",
                        customer.email,
                        customer.phone,
                        customer.city ? customer.street + ", " + customer.state + ", " + customer.city + ", " + customer.country + ", " + customer.zip : "-"
                    )
                })
                setCustomers(allCustomers);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    useEffect(() => {
        setLimit(rowsPerPage);
        getAllCustomers();
    }, []);

    return (
        <Paper sx={{
            width: '100%',
            overflow: 'hidden',
            boxShadow: 'none'
        }}>
            <TableContainer sx={{ maxHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{
                        backgroundColor: '#F2F4FF'
                    }} >
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: '#F2F4FF' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ?
                                <div style={{ width: "90vw" }} className="LoadingContainer"><BounceLoader /> </div> :
                                customers && customers?.length > 0 ?
                                    customers
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {columns?.map((column) => {
                                                        const value = row[column.id];

                                                        if (column.id === 'action') {
                                                            return (
                                                                <TableCell sx={{
                                                                    border: 'none'
                                                                }} key={column.id} align={column.align}>
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                            height: '40px',
                                                                            backgroundColor: '#000',
                                                                            color: '#fff',
                                                                            fontSize: "0.8rem!important",
                                                                            '&:hover': {
                                                                                backgroundColor: '#000',
                                                                                opacity: '0.8'
                                                                            }
                                                                        }} onClick={() => { getDetails(row.name) }} >
                                                                        Details
                                                                    </Button>
                                                                </TableCell>
                                                            );
                                                        }
                                                        return (
                                                            <TableCell sx={{
                                                                border: 'none'
                                                            }} key={column.id} align={column.align}>
                                                                {
                                                                    value
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        }) :
                                    <TableRow>
                                        <Typography style={{ padding: '1rem' }} >
                                            No Data Found
                                        </Typography>
                                    </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
