import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import wh_export_img from "../../assets/wh_export_img.png";
import axiosInstance from "../../axios/AxiosInstance";
import AuditBox from "../../components/audit/auditBox";
import DetailBox from "../../components/detailView/DetailBox";
import CityTable from "./cityTable/CityTable";
import ColumnSelector from "./cityTable/ColumnSelector";
import FilterDateModal from "./cityTable/FilterDateModal";
import FilterModal from "./cityTable/FilterModal";
import Sort from "./cityTable/Sort";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useNavigate } from "react-router-dom";

const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  top: 50%;
  margin-top: -50vh;
  left: 0;
`;

ChartJS.register(ArcElement, Tooltip, Legend);

const City = () => {
  const location = useLocation();
  const [overlay, setOverlay] = useState(false);
  const [flag, setFlag] = useState(true);
  const [filters, setFilters] = useState("");
  const [Cityname, setCityName] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const [lastPage, setLastPage] = useState(true);
  const [finalPage, setFinalPage] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const navigate = useNavigate();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const allColumnsObj = {
    Name: true,
    Email: true,
    Category: true,
    City: true,
    "Order ID": true,
    "Ticket ID": true,
    "Assigned Date": true,
    "Scheduled Date": true,
    "Completed On": true,
    "Sub Category": true,
    Status: true,
    "Sub status": true,
    Remarks: true,
    Agent: true,
    Trips: true,
    Edit: true,
    "Audit Trail": true,
    "Phone": true
  };
  const allColumns = Object.keys(allColumnsObj);

  const [tableData, setTableData] = useState([]);

  const [auditStyle, setAuditStyle] = useState({
    position: "absolute",
    top: "50%",
    right: "0",
    marginTop: window.innerWidth > 900 ? "-50vh" : "calc(-50vh + 56px)",
    zIndex: "160",
    height: "100vh",
    width: window.innerWidth > 600 ? "40%" : "100%",
    transition: "all 1s ease",
    backgroundColor: "white",
    transform: "translateX(110%)",
    display: "none",
    padding: "20px",
  });
  const [auditId, setAuditId] = useState("");

  const [detailStyle, setDetailStyle] = useState({
    position: "absolute",
    top: "0",
    right: "0",
    zIndex: "150",
    height: "100vh",
    width: window.innerWidth > 600 ? "40%" : "75%",
    transition: "all 1s ease",
    backgroundColor: "white",
    transform: "translateX(110%)",
    display: "none",
    padding: "10px",
  });
  const [detailData, setDetailData] = useState({});

  const UserData = JSON.parse(localStorage.getItem("wh_tk"))?.data;

  const userId = UserData && UserData?._id;
  const columnSelector = UserData && UserData?.column_selector
    ? UserData?.column_selector
    : allColumnsObj;

  const [checkedColumns, setCheckedColumns] = useState(columnSelector && columnSelector);
  const sendColumnData = async (UserData, newColumnsObj) => {
    try {
      const result = await axiosInstance.post("api/user/column/selector", {
        user_id: UserData._id,
        column_selector: newColumnsObj,
      });

      if (result.status === 200) {
        const newUserData = {
          data: { ...UserData, column_selector: newColumnsObj },
          success: true,
        };
        localStorage.setItem("wh_tk", JSON.stringify(newUserData));
        setCheckedColumns(newColumnsObj);
      }
    } catch (error) {
      alert("Something wrong happend!");
    }
  };

  useEffect(() => {
    if (!UserData?.column_selector) {
      sendColumnData(UserData, allColumnsObj);
    }
  }, []);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const start = now.getTime();
    now.setHours(23, 59, 59, 999);
    const end = now.getTime();
    if (UserData.type === "agent") {
      try {
        const tableRes = await axiosInstance.get(
          `api/ticket?page=${newPage + 1
          }&limit=${limit}&agentId=${userId}&city=${Cityname}${filters}`
        );
        if (tableRes.status === 200) {
          setLoading(false);
          setTableData(tableRes?.data?.data);
          setTotalPage(tableRes?.data?.count);
        }
      } catch (error) {
        console.error(error)
      }

    } else {
      try {
        const tableRes = await axiosInstance.get(
          `api/ticket?page=${newPage + 1
          }&limit=${limit}&city=${Cityname}${filters}`
        );

        if (tableRes.status === 200) {
          setLoading(false);
          setTableData(tableRes?.data?.data);
          setTotalPage(tableRes?.data?.count);
        }
      } catch (err) {
        console.error(err)
      }
    }
  };

  const handleChangePageMobile = async (newPage) => {
    if (newPage < page) {
      setStart(start - limit);
      if (end + limit >= totalPage) setEnd(start - 1);
      else setEnd(end - limit);
    } else {
      setStart(start + limit);

      if (end + limit >= totalPage) setEnd(totalPage);
      else setEnd(end + limit);
    }

    if (newPage < 0) {
      return;
    }

    if (newPage === 0) {
      setLastPage(true);
    } else {
      setLastPage(false);
    }

    setPage(newPage);
    setLoading(true);

    if (UserData.type === "agent") {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const start = now.getTime();
      now.setHours(23, 59, 59, 999);
      const end = now.getTime();
      try {
        const tableRes = await axiosInstance.get(
          `api/ticket?page=${newPage + 1
          }&limit=${limit}&agentId=${userId}&city=${Cityname}${filters}`
        );
        if (tableRes.status === 200) {
          setLoading(false);
          setTableData(tableRes?.data?.data);
          setTotalPage(tableRes?.data?.count);
          setFinalPage((newPage + 1) * limit >= tableRes?.data?.count);
        }
      } catch (error) {
        console.error(error)
      }

    } else {
      try {
        const tableRes = await axiosInstance.get(
          `api/ticket?page=${newPage + 1
          }&limit=${limit}&city=${Cityname}${filters}`
        );

        if (tableRes.status === 200) {
          setLoading(false);
          setTableData(tableRes?.data?.data);
          setTotalPage(tableRes?.data?.count);
          setFinalPage((newPage + 1) * limit >= tableRes?.data?.count);
        }
      } catch (error) {
        console.error(error)
      }

    }
  };

  const handleChangeRowsPerPage = async (event) => {
    // console.log(event.target.value);
    setRowsPerPage(event.target.value);
    setLimit(event.target.value);
    setPage(0);
    setLoading(true);

    if (UserData.type === "agent") {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const start = now.getTime();
      now.setHours(23, 59, 59, 999);
      const end = now.getTime();

      try {
        const tableRes = await axiosInstance.get(
          `api/ticket?page=${1}&limit=${event.target.value
          }&agentId=${userId}&city=${Cityname}${filters}`
        );

        if (tableRes.status === 200) {
          setLoading(false);
          setTableData(tableRes.data?.data);
          setTotalPage(tableRes.data?.count);
        }
      } catch (error) {
        console.error(error)
      }


    } else {
      try {
        const tableRes = await axiosInstance.get(
          `api/ticket?page=${1}&limit=${event.target.value
          }&city=${Cityname}${filters}`
        );

        if (tableRes.status === 200) {
          setLoading(false);
          setTableData(tableRes?.data?.data);
          setTotalPage(tableRes?.data?.count);
        }
      } catch (error) {
        console.error(error)
      }

    }
  };

  const getdata = async () => {
    try {
      setLoading(true);
      const tableRes = await axiosInstance.get(
        `api/ticket?page=${page + 1}&limit=${limit}&city=${Cityname}${filters}`
      );
      // console.log(tableRes)
      if (tableRes.status === 200) {
        setLoading(false);
        setTableData(tableRes?.data?.data);
        setTotalPage(tableRes?.data?.count);
        setLastPage(true);
        if (tableRes?.data?.count === 0) setStart(0);
        else setStart(1);
        if (limit >= tableRes?.data?.count) setEnd(tableRes?.data?.count);
        else setEnd(limit);
        if (tableRes?.data?.count <= limit) setFinalPage(true);
        else setFinalPage(false);
      }
    } catch (error) {
      console.error(error)
    }
  };

  const getInitialData = async () => {
    try {
      setLoading(true);
      const tableRes = await axiosInstance.get(
        `api/ticket?page=${page + 1}&limit=${limit}&city=${Cityname}`
      );

      if (tableRes.status === 200) {
        setLoading(false);
        setTableData(tableRes?.data?.data);
        setTotalPage(tableRes?.data?.count);
        setLastPage(true);
        if (tableRes?.data?.count === 0) setStart(0);
        else setStart(1);
        if (limit >= tableRes?.data?.count) setEnd(tableRes?.data?.count);
        else setEnd(limit);
        if (tableRes?.data?.count <= limit) setFinalPage(true);
        else setFinalPage(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAgentdata = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `api/ticket?page=${page + 1
        }&limit=${limit}&agentId=${userId}&city=${Cityname}${filters}`
      );
      if (res.status === 200) {
        setLoading(false);
        setTableData(res?.data?.data);
        setTotalPage(res?.data?.count);
        if (res?.data?.count === 0) setStart(0);
        else setStart(1);
        if (limit >= res?.data?.count) setEnd(res?.data?.count);
        else setEnd(limit);
      }
    } catch (error) {
      console.error(error);

    }

  };

  const getInitialAgentdata = async () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const start = now.getTime();
    now.setHours(23, 59, 59, 999);
    const end = now.getTime();

    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `api/ticket?page=${page + 1
        }&limit=${limit}&agentId=${userId}&city=${Cityname}&status=Pickup Scheduled,Delivery Scheduled,Scheduled`
      );
      if (res.status === 200) {
        setLoading(false);
        setTableData(res?.data?.data);
        setTotalPage(res?.data?.count);
        if (res?.data?.count === 0) setStart(0);
        else setStart(1);
        if (limit >= res?.data?.count) setEnd(res?.data?.count);
        else setEnd(limit);
      }
    } catch (error) {
      console.error(error);

    }
  };

  const exportData = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: Cityname,
      filters: filters,
    };
    // console.log(
    //   `${BASE_URL}api/ticket?&city=${Cityname}&download=csv${filters}`
    // );
    var newWindow = window.open(
      `${process.env.REACT_APP_API_URL}api/ticket?&city=${Cityname}&download=csv${filters}`
    );
    newWindow.passedData = myObj;
  };

  const exportTicket = async () => {
    const myObj = {
      page: page,
      limit: limit,
      Cityname: Cityname,
      filters: filters,
    };
    // console.log(`${BASE_URL}api/trip/export?&city=${Cityname}&download=csv${filters}`);
    var newWindow = window.open(
      `${process.env.REACT_APP_API_URL}api/trip/export?&city=${Cityname}&download=csv&${filters}`
    );
    newWindow.passedData = myObj;
  };

  const openAudit = (id) => {
    setAuditStyle({ ...auditStyle, display: "unset" });
    setAuditId(id);

    setTimeout(() => {
      setAuditStyle({
        ...auditStyle,
        transform: "translateX(0)",
        display: "unset",
      });
    }, 100);
    setOverlay(true);
  };

  useEffect(() => {
    if (location !== null && location !== undefined) {
      const { state } = location;

      if (state !== null && state !== undefined) {
        const { Cityname } = state;
        setCityName(Cityname);
      }
    }
  }, [location]);

  useEffect(() => {
    if (Cityname !== "") {
      if (UserData.type === "agent") {
        getInitialAgentdata();
      } else {
        getInitialData();
      }
    }

    setFlag(!flag);
    setIsSmall(window.innerWidth < 600);
  }, [Cityname]);

  const getData = async function (isToday) {
    try {
      const now = new Date();
      const yesterday = new Date(now.getTime() + 24 * 60 * 60 * 1000);
      yesterday.setHours(0, 0, 0, 0);
      now.setHours(0, 0, 0, 0);
      const start = isToday ? now.getTime() : yesterday.getTime();
      now.setHours(23, 59, 59, 999);
      yesterday.setHours(23, 59, 59, 999);
      const end = isToday ? now.getTime() : yesterday.getTime();

      let url = `&type=scheduled&start=${start}&end=${end}`;

      setLoading(true);

      setFilters(url);
      const UserData = JSON.parse(localStorage.getItem("wh_tk")).data;
      const userId = UserData._id;
      let baseUrl = "";

      if (UserData.type === "agent") {
        baseUrl = `api/ticket?page=${page + 1
          }&limit=${limit}&agentId=${userId}&city=${Cityname}`;
      } else {
        baseUrl = `api/ticket?page=${page + 1}&limit=${limit}&city=${Cityname}`;
      }

      url = baseUrl + url;

      const tableRes = await axiosInstance.get(url);
      if (tableRes.status === 200) {
        setLoading(false);
        setTableData(tableRes?.data?.data);
        setTotalPage(tableRes?.data?.count);
        setLastPage(true);
        if (tableRes?.data?.count === 0) setStart(0);
        else setStart(1);
        if (limit >= tableRes?.data?.count) setEnd(tableRes?.data?.count);
        else setEnd(limit);
        if (tableRes?.data?.count <= limit) setFinalPage(true);
        else setFinalPage(false);
      } else {
        setLoading(false);
        alert("Something went wrong!");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Something went wrong!!");
    }
    setFlag(!flag);
  };

  const handleTodayBtn = () => {
    setFilters("&type=deliver&start=1680633000000&end=1680719399999");
  };

  return (
    <>
      {overlay && <Overlay></Overlay>}
      <AuditBox
        style={auditStyle}
        setAuditStyle={setAuditStyle}
        auditId={auditId}
        setOverlay={setOverlay}
        flag={flag}
        setFlag={setFlag}
      />
      <DetailBox
        Cityname={Cityname}
        style={detailStyle}
        setOverlay={setOverlay}
        setDetailStyle={setDetailStyle}
        data={detailData}
        tableData={tableData}
        setTableData={setTableData}
        flag={flag}
        setFlag={setFlag}
        getdata={getdata}
        getAgentdata={getAgentdata}
        openAudit={openAudit}
        isSmall={isSmall}
      />
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            height: "20%",
            mt: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
            }}
          >
            {Cityname}
          </Typography>

          <Stack
            sx={{
              "@media (min-width: 786px)": {
                display: "none",
              },
            }}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {/* <Button
              variant="contained"
              sx={{
                width: "120px",
                height: "40px",
                // marginLeft:"20px",
                backgroundColor: "#000",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                "@media (max-width: 785px)": {
                  fontSize: "0.6rem",
                  width: "80px",
                  height: "30px",
                },
              }}
              onClick={exportData}
            >
              <img
                style={{
                  marginRight: "0.5rem",
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem",
                  },
                }}
                src={wh_export_img}
                className="wh_export_img"
                alt="export img"
              />
              Export
            </Button> */}
            <div>
              <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                Dashboard
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleClose}>Profile</MenuItem>
                          <MenuItem onClick={handleClose}>My account</MenuItem>
                          <MenuItem onClick={handleClose}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </Stack>

        <Box
          sx={{
            width: "100%",
            height: "80%",
            padding: "1.5rem",
            position: "fixed",
            marginTop: "1rem",

            "@media (max-width: 600px)": {
              padding: "1.5rem 0.5rem 0.5rem",
              backgroundColor: "#EFF5F5",
              position: "static",
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              marginBottom: "1rem",
              flexWrap: "nowrap",
              "@media (max-width: 785px)": {
                width: "100%",
                justifyContent: "space-between",
                flexWrap: "wrap",
              },
            }}
            spacing={1}
          >
            <Stack
              sx={{
                "@media (max-width: 785px)": {
                  width: "100%",
                  marginTop: "10px",
                  // justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "10px",
                  margin: "0",
                },
              }}
              direction="row"
              justifyContent=""
              alignItems="center"
              // spacing={2}
              gap={"10px"}
            >
              <FilterDateModal
                flag={flag}
                setFlag={setFlag}
                city={Cityname}
                setTableData={setTableData}
                filters={filters}
                setFilters={setFilters}
                setTotalPage={setTotalPage}
                page={page}
                limit={limit}
                setPage={setPage}
                loading={loading}
                setLoading={setLoading}
                setStart={setStart}
                setEnd={setEnd}
                setLastPage={setLastPage}
                setFinalPage={setFinalPage}
              />
              <FilterModal
                flag={flag}
                setFlag={setFlag}
                city={Cityname}
                setTableData={setTableData}
                filters={filters}
                setFilters={setFilters}
                setTotalPage={setTotalPage}
                page={page}
                limit={limit}
                setPage={setPage}
                loading={loading}
                setLoading={setLoading}
                setStart={setStart}
                setEnd={setEnd}
                setLastPage={setLastPage}
                setFinalPage={setFinalPage}
              />

              <Sort
                flag={flag}
                setFlag={setFlag}
                city={Cityname}
                setTableData={setTableData}
                filters={filters}
                setFilters={setFilters}
                setTotalPage={setTotalPage}
                page={page}
                limit={limit}
                setPage={setPage}
              />
              <ColumnSelector
                checkedColumns={checkedColumns}
                setCheckedColumns={setCheckedColumns}
                allColumns={allColumns}
                columnSelector={columnSelector}
              />
              <Button
                variant="contained"
                sx={{
                  whiteSpace: "nowrap",
                  height: "40px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "0.875rem !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem !important",
                    height: "30px",
                  },
                }}
                onClick={() => {
                  getData(true);
                }}
              >
                Today
              </Button>
              <Button
                variant="contained"
                sx={{
                  whiteSpace: "nowrap",
                  height: "40px",
                  backgroundColor: "#000",
                  color: "#fff",
                  fontSize: "0.875rem !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem !important",
                    height: "30px",
                  },
                }}
                onClick={() => {
                  getData(false);
                }}
              >
                Tomorrow
              </Button>
              <Button
                sx={{
                  whiteSpace: "nowrap",
                  height: "40px",
                  backgroundColor: "#000",
                  color: "#fff",
                  // ml: "10px",
                  fontSize: "0.875rem !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem !important",
                    height: "30px",
                  },
                }}
                onClick={() => {
                  navigate(`/auditTrail?city=${Cityname}`);
                }}
              >
                Audit Trail
              </Button>
              <Button
                variant="contained"
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                sx={{
                  whiteSpace: "nowrap",
                  backgroundColor: "#000",
                  color: "#fff",
                  height: "40px",
                  backgroundColor: "#000",
                  color: "#fff",
                  // ml: "10px",
                  fontSize: "0.875rem !important",
                  "&:hover": {
                    backgroundColor: "#000",
                    opacity: "0.8",
                  },
                  "@media (max-width: 785px)": {
                    fontSize: "0.6rem !important",
                    height: "30px",
                  },
                }}
              >
                Export
              </Button>
            </Stack>
            <div>
              <Popper
                sx={{ zIndex: "999", width: "90px" }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper sx={{ width: "100%" }}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={() => {
                              exportData();
                              handleClose();
                            }}
                          >
                            City
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              exportTicket();
                              handleClose();
                            }}
                          >
                            Trip
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
          {/* </Stack> */}
          <CityTable
            flag={flag}
            setFlag={setFlag}
            data={tableData}
            auditStyle={auditStyle}
            setAuditStyle={setAuditStyle}
            setAuditId={setAuditId}
            detailStyle={detailStyle}
            setDetailStyle={setDetailStyle}
            setDetailData={setDetailData}
            setOverlay={setOverlay}
            getdata={getdata}
            page={page}
            setPage={setPage}
            handleChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalPage={totalPage}
            loading={loading}
            openAudit={openAudit}
            handleChangePageMobile={handleChangePageMobile}
            lastPage={lastPage}
            finalPage={finalPage}
            start={start}
            end={end}
            isSmall={isSmall}
            getAgentdata={getAgentdata}
            checkedColumns={checkedColumns}
          />
        </Box>
        <div></div>
      </div>
    </>
  );
};

export default City;
