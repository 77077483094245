import React, { useState, useEffect } from 'react';
import { Button, Container, Stack, Typography } from '@mui/material'
import wh_sign_lbg from '../../assets/wh_sign_lbg.png';
import Input from '../../components/input/LoginInput';
import axiosInstance from '../../axios/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading/Loading';

function Forgot() {

    const [value, setValue] = useState("");
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState("");
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [isPhone, setIsPhone] = useState(false);
    const [isEmail, setIsEmail] = useState(false);

    const navigate = useNavigate();

    // const userData = JSON.parse(localStorage.getItem('wh_tk')).data;
    // const userId = userData._id;
    const PHONE_REGEX = new RegExp(/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/);
    const EMAIL_REGEX = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);


    const handleIdChange = (ev) => {

        if (PHONE_REGEX.test(ev.target.value) === true) {
            setIsPhone(true);
        }
        else {
            setIsPhone(false)
        }

        if (EMAIL_REGEX.test(ev.target.value) === true) {
            setIsEmail(true);
        }
        else {
            setIsEmail(false);
        }

        // console.log(EMAIL_REGEX.test(ev.target.value));
        setValue(ev.target.value);
    }

    const sendOtp = async () => {

        setLoading1(true);

        let body = {}

        if (isEmail === true) {
            body = {
                email: value
            }
        }
        else {
            body = {
                phone: parseInt(value)
            }
        }

        try {
            const res = await axiosInstance.post('api/user/auth/send_otp', body)
            // console.log(res);
            if (res.status === 200) {
                setLoading1(false);
                alert(res.data.message);
            }
            else {
                setLoading1(false);
                alert("Something went  wrong");
            }
        }
        catch (err) {
            console.log(err)
            setLoading1(false);
            alert("Something went wrong");
        }
    }

    const handlePassChange = (ev) => {
        setPassword(ev.target.value);
    }

    const handleOTPchange = (ev) => {
        setOtp(ev.target.value);
    }

    const changePassword = async () => {
        setLoading2(true);

        let body = {}

        if (isEmail === true) {
            body = {
                email: value,
                otp: parseInt(otp),
                password: password,
                reset: true
            }
        }
        else {
            body = {
                phone: parseInt(value),
                otp: parseInt(otp),
                password: password,
                reset: true
            }
        }

        try {
            const res = await axiosInstance.post('api/user/auth/verify_otp', body)
            if (res.status === 200) {
                setLoading2(false);
                alert(res.data.message);
                navigate('/login')
            }
            else {
                setLoading2(false);
                alert("Something went  wrong");
            }
        }
        catch (err) {
            console.log(err)
            setLoading2(false);
            alert("Something went  wrong");
        }
    }

    return (
        <Container>
            <Stack
                direction={window.innerWidth > 600 ? "row" : "column-reverse"}
                sx={{
                    padding: '5rem 0rem 0rem'
                }}
                spacing={2}  >
                <Stack
                    sx={{
                        width: window.innerWidth > 600 ? '50%' : '100%',
                        paddingLeft: '2rem'
                    }} >
                    <Typography
                        variant="h3"
                        sx={{
                            color: '#484848',
                            fontWeight: '500'
                        }} >Reset Password</Typography>
                    <Typography
                        variant="p"
                        sx={{
                            color: '#838383',
                            fontWeight: '700',
                            marginTop: '0.5rem'
                        }} >Enter email to reset your password.</Typography>
                    <Stack
                        direction="column"
                        spacing={3}
                        sx={{
                            mt: 5,
                            width: '80%'
                        }} >
                        <Input
                            type="email"
                            value={value}
                            setvalue={setValue}
                            style={{
                                border: '1px solid #CCCAD6',
                                borderRadius: '8px',
                                outline: 'none'
                            }}
                            handleChange={handleIdChange}
                            placeholder="Enter registered email or phone" />

                        {
                            (isPhone === false && isEmail === false && value !== "") &&
                            <Typography
                                variant="p"
                                sx={{
                                    color: 'red',
                                    marginTop: '0.2rem !important',
                                    marginBottom: '0.5rem'
                                }} >Phone or Email entered is Invalid</Typography>
                        }

                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <Button
                                sx={{
                                    backgroundColor: '#000',
                                    color: '#fff',
                                    // padding: '0.5rem 0rem',
                                    width: '100px',
                                    // borderRadius: '40px',
                                    fontSize: "0.8rem!important",
                                    '&:hover': {
                                        backgroundColor: '#000',
                                        opacity: '0.8'
                                    }
                                }}
                                onClick={sendOtp}
                            >
                                {
                                    loading1 ?
                                        <Loading color="#fff" /> :
                                        "Send OTP"
                                }
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={3}
                        sx={{
                            mt: 5,
                            width: '80%'
                        }} >
                        <Input
                            type="email"
                            value={otp}
                            setvalue={setOtp}
                            style={{
                                border: '1px solid #CCCAD6',
                                borderRadius: '8px',
                                outline: 'none'
                            }}
                            handleChange={handleOTPchange}
                            placeholder="Enter OTP" />
                        <Input
                            value={password}
                            setvalue={setPassword}
                            type="password"
                            style={{
                                border: '1px solid #CCCAD6',
                                borderRadius: '8px',
                                outline: 'none'
                            }}
                            handleChange={handlePassChange}
                            placeholder="Enter new password" />

                        <Button
                            sx={{
                                backgroundColor: '#000',
                                color: '#fff',
                                // padding: '0.5rem 0rem',
                                // width: '100px',
                                // borderRadius: '40px',
                                fontSize: "0.8rem!important",
                                '&:hover': {
                                    backgroundColor: '#000',
                                    opacity: '0.8'
                                }
                            }}
                            onClick={changePassword}
                        >
                            {
                                loading2 ?
                                    <Loading color="#fff" /> :
                                    "Change Password"
                            }
                        </Button>
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        width: window.innerWidth > 600 ? '50%' : '100%'
                    }} >
                    <Stack sx={{
                        width: '100%',
                        height: '90%'
                    }} >
                        <img style={{ width: '100%', height: '100%' }} src={wh_sign_lbg} alt="warehouse img" />
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    )
}

export default Forgot