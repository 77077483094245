import React, { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import { Typography, Button, Box } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Unstable_Popup as Popup } from "@mui/base/Unstable_Popup";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/AxiosInstance";
import DatePicker from "react-datepicker";
ChartJS.register(ArcElement, Tooltip, Legend);

const City = () => {

  const navigate = useNavigate();
  const authData = localStorage.getItem("wh_tk");
  useEffect(() => {
    if (!authData) {
      navigate("/login", { replace: true });
    }
  }, [])

  const [tableData, setTableData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [delivery, setDelivery] = useState({});
  const [pickup, setPickup] = useState({});
  const [sr, setSr] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(false);
  const [status, setStatus] = useState("Assigned");
  const [statusForTrip, setstatusForTrip] = useState("assigned");
  // const [minDate,setMinDate] = useState(dayjs('2017-01-01'));
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate1, endDate1] = dateRange;

  const [anchor, setAnchor] = React.useState(null);

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const newopen = Boolean(anchor);
  const id = newopen ? "simple-popup" : undefined;

  const items = [
    {
      value: "one",
      text: "one",
    },
    {
      value: "two",
      text: "two",
    },
    {
      value: "three",
      text: "three",
    },
    {
      value: "four",
      text: "four",
    },
  ];
  let CityNames = JSON.parse(localStorage.getItem("wh_tk"))?.data?.warehouses;
  let checkAdmin = JSON.parse(localStorage.getItem("wh_tk"))?.data?.type;

  let house = [
    {
      _id: "6353dd2fc9383308bab3b855",
      name: "Noida",
      createdAt: "2022-10-22T12:08:15.928Z",
      updatedAt: "2022-10-22T12:08:15.928Z",
      __v: 0,
      city: "Noida",
    },
    {
      _id: "6385d82369d7b11ec8dbce2f",
      name: "Pune",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Pune",
    },
    {
      _id: "639ee663a0a2c3856669742c",
      name: "Mumbai",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Mumbai",
    },
    {
      _id: "639ee6aaa0a2c3856669742e",
      name: "Jodhpur",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Jodhpur",
    },
    {
      _id: "639ee6d6a0a2c38566697430",
      name: "Gurgaon",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Gurgaon",
    },
    {
      _id: "639ee6ffa0a2c38566697431",
      name: "Bangalore",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Bangalore",
    },
    {
      _id: "639ee735a0a2c38566697433",
      name: "Hyderabad",
      createdAt: "2022-10-22T06:46:24.580Z",
      updatedAt: "2022-10-22T06:46:24.580Z",
      __v: 0,
      city: "Hyderabad",
    },
  ];
  if (checkAdmin == "admin") {
    CityNames = house;
  }
  const [tableCity, setTableCity] = useState(CityNames && CityNames[0]?.name);


  let handleDate = (event) => {
    console.log(event);
    setDateRange(event);
    if (!event[0] == null && !event[1] == null) {
      // console.log("event", event);
    }
  };

  const handleCityChange = (city) => {
    setTableCity(city);
  };
  const handleStart = (date) => {
    setStartDate(date);
  };
  const handleEnd = (date) => {
    setEndDate(date);
  };

  //   useEffect(() => {
  //     const authData = localStorage.getItem("wh_tk");
  //     if(!authData) {
  //       navigate('/login', { replace: true });
  //     } else {
  //       setUserRole(() => JSON.parse(authData).type);
  //     }
  //   },[]);

  const startTimeStamp = (d) => {
    let myDate =
      (d.$D < 10 ? "0" + d.$D : d.$D) +
      "-" +
      (d.$M < 10 ? "0" + d.$M : d.$M) +
      "-" +
      d.$y;
    // console.log(myDate);
    myDate = myDate.split("-");
    var newDate = new Date(myDate[2], myDate[1], myDate[0]);
    return newDate.getTime();
  };
  const endTimeStamp = (d) => {
    let myDate =
      (d.$D < 10 ? "0" + d.$D : d.$D) +
      "-" +
      (d.$M < 10 ? "0" + d.$M : d.$M) +
      "-" +
      d.$y;

    myDate = myDate.split("-");
    var newDate = new Date(myDate[2], myDate[1], myDate[0], "23", "59", "59");
    return newDate.getTime();
  };

  useEffect(() => {
    const authData = localStorage.getItem("wh_tk");
    if (!authData) {
      navigate("/login", { replace: true });
    } else {
      setUserRole(() => JSON.parse(authData).type);
      const UserData = JSON.parse(localStorage.getItem("wh_tk")).data;
      const userId = UserData._id;
      const getAgentdata = async () => {
        const res = await axiosInstance.get(
          `api/ticket?agentId=${userId}&city=${tableCity}`
        );
        setTableData(res?.data?.data);
      };

      if (UserData.type === "agent") {
        getAgentdata();
      } else {
        getData();
      }
    }
  }, [tableCity, startDate1, endDate1, status]);

  const exportTicket = async () => {

    let url = `${process.env.REACT_APP_API_URL}api/trip/export?&download=csv&type=${statusForTrip}${checkAdmin !== "admin" ? (CityNames.length > 1 ? "" : '&city=' + tableCity) : ""}&`;
    if (startDate1 == null && endDate1 == null) {
      var targetDate = new Date();
      var thirtyDaysAgo = new Date(targetDate);
      thirtyDaysAgo.setDate(targetDate.getDate() - 7);
      thirtyDaysAgo.setHours(23, 59, 59, 999);
      setDateRange([thirtyDaysAgo, targetDate]);
      let start = new Date(dateRange[0]).toUTCString();
      let end = new Date(dateRange[1]);
      end = end.setHours(23, 59, 59, 999);
      end = new Date(end).toUTCString();
      if (start > end) {
        alert("End date cannot be before start date");
        reset();
        return;
      }
      url =
        url +
        `start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
    } else {
      let start = new Date(dateRange[0]).toUTCString();
      let end = new Date(dateRange[1]);
      end = end.setHours(23, 59, 59, 999);
      end = new Date(end).toUTCString();
      url =
        url +
        `start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
    }
    var newWindow = window.open(url);
  };
  const getData = async () => {
    const authData = localStorage.getItem("wh_tk");
    if (authData) {
      let url = `api/dashboard/delivery-count?city=${tableCity}&status=${status}`;
      let pickupUrl = `api/dashboard/pickup-count?city=${tableCity}&status=${status}`;
      let srUrl = `api/dashboard/sr-count?city=${tableCity}&status=${status}`;

      if (startDate1 == null && endDate1 == null) {
        var targetDate = new Date();
        var thirtyDaysAgo = new Date(targetDate);
        thirtyDaysAgo.setDate(targetDate.getDate() - 7);
        thirtyDaysAgo.setHours(23, 59, 59, 999);
        setDateRange([thirtyDaysAgo, targetDate]);
        let start = new Date(dateRange[0]).toUTCString();
        let end = new Date(dateRange[1]);
        end = end.setHours(23, 59, 59, 999);
        end = new Date(end).toUTCString();
        if (start > end) {
          alert("End date cannot be before start date");
          reset();
          return;
        }
        url =
          url +
          `&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
        pickupUrl =
          pickupUrl +
          `&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
        srUrl =
          srUrl +
          `&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
      } else {
        let start = new Date(dateRange[0]).toUTCString();
        let end = new Date(dateRange[1]);
        end = end.setHours(23, 59, 59, 999);
        end = new Date(end).toUTCString();
        url =
          url +
          `&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
        pickupUrl =
          pickupUrl +
          `&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
        srUrl =
          srUrl +
          `&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
      }
      if (startDate1 && endDate1) {
        const res = await axiosInstance.get(url);
        const pickupRes = await axiosInstance.get(pickupUrl);
        const srRes = await axiosInstance.get(srUrl);
        const finalData = res?.data?.data[0];
        const finalPick = pickupRes?.data?.data[0];
        const srData = srRes?.data?.data[0];
        setDelivery(finalData);
        setPickup(finalPick);
        setSr(srData);
        setCheck(true);
        const tableRes = await axiosInstance.get(`api/ticket?city=${tableCity}`);
        setTableData(tableRes?.data?.data);
      }
    }
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
    setstatusForTrip(event.target.value == "Assigned" ? "assigned" : "scheduled")
  };
  const reset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const exportOder = () => {
    let url = `${process.env.REACT_APP_API_URL}api/ticket/order?type=${status}&download=csv${checkAdmin !== "admin" ? '&city=' + tableCity : ""}&`;
    if (startDate1 == null && endDate1 == null) {
      var targetDate = new Date();
      var thirtyDaysAgo = new Date(targetDate);
      thirtyDaysAgo.setDate(targetDate.getDate() - 7);
      thirtyDaysAgo.setHours(23, 59, 59, 999);
      setDateRange([thirtyDaysAgo, targetDate]);
      let start = new Date(dateRange[0]).toUTCString();
      let end = new Date(dateRange[1]);
      end = end.setHours(23, 59, 59, 999);
      end = new Date(end).toUTCString();
      if (start > end) {
        alert("End date cannot be before start date");
        reset();
        return;
      }
      url =
        url +
        `start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
    } else {
      let start = new Date(dateRange[0]).toUTCString();
      let end = new Date(dateRange[1]);
      end = end.setHours(23, 59, 59, 999);
      end = new Date(end).toUTCString();
      url =
        url +
        `start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
    }
    var newWindow = window.open(url);
  };


  const exportAllTicket = () => {
    let newStaus = status == "Assigned" ? "assigned" : "scheduled"
    let url = `${process.env.REACT_APP_API_URL}api/ticket/dashticket?type=${newStaus}&download=csv${checkAdmin !== "admin" ? '&city=' + tableCity : ""}&`;
    if (startDate1 == null && endDate1 == null) {
      var targetDate = new Date();
      var thirtyDaysAgo = new Date(targetDate);
      thirtyDaysAgo.setDate(targetDate.getDate() - 7);
      thirtyDaysAgo.setHours(23, 59, 59, 999);
      setDateRange([thirtyDaysAgo, targetDate]);
      let start = new Date(dateRange[0]).toUTCString();
      let end = new Date(dateRange[1]);
      end = end.setHours(23, 59, 59, 999);
      end = new Date(end).toUTCString();
      if (start > end) {
        alert("End date cannot be before start date");
        reset();
        return;
      }
      url =
        url +
        `start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
    } else {
      let start = new Date(dateRange[0]).toUTCString();
      let end = new Date(dateRange[1]);
      end = end.setHours(23, 59, 59, 999);
      end = new Date(end).toUTCString();
      url =
        url +
        `start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`;
    }
    var newWindow = window.open(url);
  };
  const Shippeddata = {
    datasets: [
      {
        data: [
          parseInt(delivery.done) + parseInt(pickup.done) + parseInt(sr.done),
          parseInt(delivery.pending) +
          parseInt(pickup.pending) +
          parseInt(sr.pending),
          parseInt(delivery.assign) +
          parseInt(pickup.assign) +
          parseInt(sr.assign),
        ],
        backgroundColor: ["#475BE8", "#E4E8EF", "#E4E8EF"],
        borderWidth: 0,
      },
    ],
  };

  const Pendingdata = {
    datasets: [
      {
        data: [
          parseInt(delivery.done) + parseInt(pickup.done) + parseInt(sr.done),
          parseInt(delivery.pending) +
          parseInt(pickup.pending) +
          parseInt(sr.pending),
          parseInt(delivery.assign) +
          parseInt(pickup.assign) +
          parseInt(sr.assign),
        ],
        backgroundColor: ["#E4E8EF", "#F29A2E", "#E4E8EF"],
        borderWidth: 0,
      },
    ],
  };

  const Newdata = {
    datasets: [
      {
        data: [
          parseInt(delivery.done) + parseInt(pickup.done) + parseInt(sr.done),
          parseInt(delivery.pending) +
          parseInt(pickup.pending) +
          parseInt(sr.pending),
          parseInt(delivery.assign) +
          parseInt(pickup.assign) +
          parseInt(sr.assign),
        ],
        backgroundColor: ["#E4E8EF", "#E4E8EF", "#4CE13F"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div style={{ width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          mt: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
          }}
        >
          Dashboard
        </Typography>
        {/* <Select style={{width:"200px"}} items={items} label="Date" /> */}
        <div style={{ display: "flex", gap: "10px" }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selectsRange={true}
            startDate={startDate1}
            endDate={endDate1}
            onChange={handleDate}
            id="DashboardInput"
            className="DashboardInputClassName"
          />
          <Select
            value={status}
            // style={{ marginLeft: "3px", width: "70%", borderColor: "black" }}
            type="text"
            sx={{
              marginLeft: "3px",
              width: "220px",
              height: "30px",
              padding: "18px",
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #d5d5d5', // Default border
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #d5d5d5', // Focused state border
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #d5d5d5', // Border on hover
              },
              '& .MuiSelect-select': {
                padding: '10px', // Adjust the padding inside the select if needed
              },
              '&:focus-visible': {
                outline: 'none', // Remove outline when focused
              },
            }}
            placeholder="Enter City"
            onChange={handleChange}
            some={getData}
          >
            <MenuItem value={"Assigned"}>Assigned</MenuItem>

            <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
          </Select>
          <button
            style={{ padding: '4px 22px 4px 22px' }}
            className="TatSubmitbutton"
            // variant="container"
            // sx={{
            //   marginLeft: "3px",
            //   marginTop: "5px",
            //   height: "40px",
            //   backgroundColor: "#000",
            //   color: "#fff",
            //   fontSize: "0.8rem!important",
            //   "&:hover": {
            //     backgroundColor: "#000",
            //     opacity: "0.8",
            //   },
            // }}
            onClick={reset}>
            Reset
          </button>

          <button
            className="TatSubmitbutton"
            // aria-describedby={id}
            // type="button"
            onClick={handleClick}
          // variant="contained"
          // sx={{
          //   marginLeft: "3px",
          //   marginTop: "5px",
          //   height: "40px",
          //   backgroundColor: "#000",
          //   color: "#fff",
          //   fontSize: "0.8rem!important",
          //   "&:hover": {
          //     backgroundColor: "#000",
          //     opacity: "0.8",
          //   },
          // }}
          >
            Export
          </button>
          <Popup id={id} open={newopen} anchor={anchor}>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "3px",
                border: "1px solid black",
              }}
            >
              {" "}
              <MenuItem
                onClick={() => {
                  exportTicket();
                  handleClick();
                }}
              >
                Trip
              </MenuItem>
              <MenuItem
                onClick={() => {
                  exportOder();
                  handleClick();
                }}
              >
                Barcode
              </MenuItem>
              <MenuItem
                onClick={() => {
                  exportAllTicket();
                  handleClick();
                }}
              >
                All Ticket
              </MenuItem>
            </div>
          </Popup>
          {/* <Button
            variant="container"
            sx={{
              marginLeft: "3px",
              marginTop: "5px",
              height: "40px",
              backgroundColor: "#000",
              color: "#fff",
              fontSize: "0.8rem!important",
              "&:hover": {
                backgroundColor: "#000",
                opacity: "0.8",
              },
            }}
            onClick={exportOder}
          >
            Export
          </Button> */}
        </div>
      </Stack>
      {/* <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"BASE_URL
        sx={{
          width: "100%",
          marginTop: "30px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "30%",
            padding: "20px 10px",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <div>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              Assigned Tasks
            </Typography>

            <Typography
              sx={{
                fontSize: "3rem",
                fontWeight: "700",
                color: "#4CE13F",
              }}
            >
              {parseInt(delivery.assign) +
                parseInt(pickup.assign) +
                parseInt(sr.assign)}
            </Typography>
          </div>
          <div style={{ height: "100px", width: "100px" }}>
            <Doughnut
              data={Newdata}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "30%",
            padding: "20px 10px",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <div>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              Pending Tasks
            </Typography>

            <Typography
              sx={{
                fontSize: "3rem",
                fontWeight: "700",
                color: "#F29A2E",
              }}
            >
              {parseInt(delivery.pending) +
                parseInt(pickup.pending) +
                parseInt(sr.pending)}
            </Typography>
          </div>
          <div style={{ height: "100px", width: "100px" }}>
            <Doughnut
              data={Pendingdata}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "30%",
            padding: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <div>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              Completed Tasks
            </Typography>

            <Typography
              sx={{
                fontSize: "3rem",
                fontWeight: "700",
                color: "#475BE8",
              }}
            >
              {parseInt(delivery.done) +
                parseInt(pickup.done) +
                parseInt(sr.done)}
            </Typography>
          </div>
          <div style={{ height: "100px", width: "100px" }}>
            <Doughnut
              data={Shippeddata}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
        </Stack>
      </Stack> */}
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          marginTop: "20px",
        }}
      >
        {CityNames?.map((item) => {
          return <div
            style={{
              padding: "5px 20px",
              borderRadius: "5px",
              border: tableCity === item?.city ? "" : "1px solid black",
              backgroundColor: tableCity === item?.city ? "black" : "",
              color: tableCity === item?.city ? "white" : "",
              cursor: "Pointer",
            }}
            onClick={() => {
              handleCityChange(item?.city);
            }}
          >

            <Typography
              sx={{
                fontSize: "1rem",
              }}
            >
              {item?.city}
            </Typography>
          </div>
        })}

        {/* <div
          style={{
            padding: "5px 20px",
            borderRadius: "5px",
            border: tableCity === "Noida" ? "" : "1px solid black",
            backgroundColor: tableCity === "Noida" ? "black" : "",
            color: tableCity === "Noida" ? "white" : "",
            cursor: "Pointer",
          }}
          onClick={() => {
            handleCityChange("Noida");
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
            }}
          >
            Noida
          </Typography>
        </div>
        <div
          style={{
            padding: "5px 20px",
            borderRadius: "5px",
            border: tableCity === "Hyderabad" ? "" : "1px solid black",
            backgroundColor: tableCity === "Hyderabad" ? "black" : "",
            color: tableCity === "Hyderabad" ? "white" : "",
            cursor: "Pointer",
          }}
          onClick={() => {
            handleCityChange("Hyderabad");
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
            }}
          >
            Hyderabad
          </Typography>
        </div>
        <div
          style={{
            padding: "5px 20px",
            borderRadius: "5px",
            border: tableCity === "Bangalore" ? "" : "1px solid black",
            backgroundColor: tableCity === "Bangalore" ? "black" : "",
            color: tableCity === "Bangalore" ? "white" : "",
            cursor: "Pointer",
          }}
          onClick={() => {
            handleCityChange("Bangalore");
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
            }}
          >
            Bangalore
          </Typography>
        </div>
        <div
          style={{
            padding: "5px 20px",
            borderRadius: "5px",
            border: tableCity === "Mumbai" ? "" : "1px solid black",
            backgroundColor: tableCity === "Mumbai" ? "black" : "",
            color: tableCity === "Mumbai" ? "white" : "",
            cursor: "Pointer",
          }}
          onClick={() => {
            handleCityChange("Mumbai");
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
            }}
          >
            Mumbai
          </Typography>
        </div>
        <div
          style={{
            padding: "5px 20px",
            borderRadius: "5px",
            border: tableCity === "Pune" ? "" : "1px solid black",
            backgroundColor: tableCity === "Pune" ? "black" : "",
            color: tableCity === "Pune" ? "white" : "",
            cursor: "Pointer",
          }}
          onClick={() => {
            handleCityChange("Pune");
          }}
        >
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "500",
            }}
          >
            Pune
          </Typography>
        </div> */}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          marginTop: "30px",
        }}
      >
        <Stack
          direction="column"
          sx={{
            width: "30%",
            padding: "10px",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <div>
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Delivery
            </Typography>
          </div>

          {!check ? (
            ""
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Assigned
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {delivery?.assign[0]?.assign}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Scheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {delivery?.Scheduled[0]?.Scheduled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Unscheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {delivery?.Unscheduled[0]?.Unscheduled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Done
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {delivery?.done[0]?.deliverDate}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Cancelled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {delivery?.Cancelled[0]?.Cancelled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Rescheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {delivery?.Rescheduled[0]?.Rescheduled}
                </Typography>
              </Stack>
            </>
          )}

          {/* <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width:"100%",
                    marginBottom:"10px"
                }}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginBottom:"10px"
                    }} >Percentage</Typography>

                <Typography sx={{
                    fontSize: '1rem',
                    fontWeight:'500',
                    marginBottom:"10px"
                    }} >{delivery.percentage === null ? "0%" : `${delivery.percentage}%`}</Typography>    
            </Stack> */}
          <div style={{ position: "relative", width: "100%", height: "10px" }}>
            <div
              style={{
                height: "100%",
                position: "absolute",
                width: "100%",
                backgroundColor: "#F29A2E",
                borderRadius: "7.20174px",
              }}
            ></div>
            <div
              style={{
                height: "100%",
                position: "absolute",
                width: `${delivery.percentage}%`,
                backgroundColor: "#475BE8",
                borderRadius: "7.20174px",
              }}
            ></div>
          </div>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: "30%",
            padding: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <div>
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              Pickup
            </Typography>
          </div>

          {!check ? (
            ""
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Assigned
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {pickup?.assign[0]?.assign}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Scheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {pickup?.Scheduled[0]?.Scheduled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Unscheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {pickup?.Unscheduled[0]?.Unscheduled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Done
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {pickup?.done[0]?.deliverDate}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Cancelled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {pickup?.Cancelled[0]?.Cancelled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Rescheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {pickup?.Rescheduled[0]?.Rescheduled}
                </Typography>
              </Stack>
            </>
          )}
          {/* <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width:"100%",
                    marginBottom:"10px"
                }}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginBottom:"10px"
                    }} >Percentage</Typography>

                <Typography sx={{
                    fontSize: '1rem',
                    fontWeight:'500',
                    marginBottom:"10px"
                    }} >{pickup.percentage === null ? "0%" : `${pickup.percentage}%`}</Typography>    
            </Stack> */}
          <div style={{ position: "relative", width: "100%", height: "10px" }}>
            <div
              style={{
                height: "100%",
                position: "absolute",
                width: "100%",
                backgroundColor: "#F29A2E",
                borderRadius: "7.20174px",
              }}
            ></div>
            <div
              style={{
                height: "100%",
                position: "absolute",
                width: `${pickup.percentage}%`,
                backgroundColor: "#475BE8",
                borderRadius: "7.20174px",
              }}
            ></div>
          </div>
        </Stack>
        <Stack
          direction="column"
          sx={{
            width: "30%",
            padding: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <div>
            <Typography
              sx={{
                fontSize: "1.4rem",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              SR
            </Typography>
          </div>
          {!check ? (
            ""
          ) : (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Assigned
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {sr?.assign[0]?.assign}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Scheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {sr?.Scheduled[0]?.Scheduled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Unscheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {sr?.Unscheduled[0]?.Unscheduled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Done
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {sr?.done[0]?.deliverDate}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Cancelled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {sr?.Cancelled[0]?.Cancelled}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Rescheduled
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  {sr?.Rescheduled[0]?.Rescheduled}
                </Typography>
              </Stack>
            </>
          )}
          {/* <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    width:"100%",
                    marginBottom:"10px"
                }}
            >
                <Typography sx={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    marginBottom:"10px"
                    }} >Percentage</Typography>

                <Typography sx={{
                    fontSize: '1rem',
                    fontWeight:'500',
                    marginBottom:"10px"
                    }} >{sr.percentage === null ? "0%" : `${sr.percentage}%`}</Typography>    
            </Stack> */}
          <div style={{ position: "relative", width: "100%", height: "10px" }}>
            <div
              style={{
                height: "100%",
                position: "absolute",
                width: "100%",
                backgroundColor: "#F29A2E",
                borderRadius: "7.20174px",
              }}
            ></div>
            <div
              style={{
                height: "100%",
                position: "absolute",
                width: `${sr.percentage}%`,
                backgroundColor: "#475BE8",
                borderRadius: "7.20174px",
              }}
            ></div>
          </div>
        </Stack>
      </Stack>
      {/* <Box sx={{
        width: '100%', 
        height: '100%',
        padding: '1.5rem',
        backgroundColor: '#fff',
        marginTop: '1rem'
      }} >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            marginBottom: '1rem',
            flexWrap:'wrap'
          }} spacing={1} >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              flexWrap:'wrap'
            }} >
            <Stack 
              className="wh_searchEmailInput"
              direction='row'
              alignItems='center' >
              <SearchIcon sx={{
                color:'#8F8F8F',
              }} />
              <input
                style={{
                  border: 'none',
                  outline: 'none',
                  fontFamily:'Poppins, sans-serif',
                  padding:'0.1rem'
                }}
                placeholder="Search customer..."
              />
            </Stack>
            <select className="wh_selectEmailInput" >
              <option>Sort by</option>
            </select>
          </Stack>
          <Stack sx={{
            // width:window.innerWidth > 600 ? '' : '100%'
          }} direction='row'
          justifyContent='flex-end' 
          spacing={2} >
            <Button
                variant="contained"
                sx={{
                    // width: '220px',
                    height:"40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize:"0.8rem!important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }} 
                >
                <AddIcon sx={{
                    marginRight: '0.5rem'
                }} />
                Add a Column
            </Button> 
            <Button
                variant="contained"
                sx={{
                    // width: '250px',
                    height:"40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize:"0.8rem!important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }} 
                >
                <EditIcon sx={{
                    marginRight: '0.5rem'
                }} />
                Edit
            </Button> 
            <Button
                variant="contained"
                sx={{
                    // width: '250px',
                    height:"40px",
                    backgroundColor: '#000',
                    color: '#fff',
                    fontSize:"0.8rem!important",
                    '&:hover': {
                        backgroundColor: '#000',
                        opacity: '0.8'
                    }
                }} 
                >
                <AddIcon sx={{
                    marginRight: '0.5rem'
                }} />
                Add Data by Case ID
            </Button> 
            <Button
              variant="contained"
              sx={{
                // width:'120px',
                height: '40px',
                marginLeft:"20px",
                backgroundColor: '#000',
                color: '#fff',
                fontSize:"0.8rem!important",
                '&:hover': {
                  backgroundColor: '#000',
                  opacity: '0.8'
                }
              }} onClick={() => {navigate('/dashboard/add_data')}} >
              <AddIcon sx={{
                    marginRight: '0.5rem'
                }} />
                Add Data
            </Button>
          </Stack> 
        </Stack>
        <DashTable data = {tableData} />
      </Box> */}
    </div>
  );
};

export default City;
