import React, { useState } from "react";
import styled from "styled-components";

const InputBox = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 18px;
`;

const Input = ({ style, placeholder, defaultValue, value, setvalue, type, some }) => {

  const handleKeyDown = (event) => {
    if (event.key == "Enter" && value !== "") {
      some && some();
    }
  };

  const handleChange = (ev) => {
    setvalue(ev.target.value);
  };

  return (
    <InputBox
      type={type}
      style={style}
      defaultValue={defaultValue}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default Input;
