import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Html5QrcodeScanner } from "html5-qrcode";
import axiosInstance from '../../../axios/AxiosInstance';
import { toast } from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const QrCodeScanner = ({ set_id, _id, pickupId, cityName, type, qrCode, handleClose, id, setReloard, reloard }) => {
    const [scanResult, setScanResult] = useState(null);
    const [flashOn, setFlashOn] = useState(false);
    const videoTrackRef = useRef(null);
    const scannerRef = useRef(null);

    useEffect(() => {
        let timerId = setTimeout(() => {
            if (document.getElementById('reader')) {
                const scanner = new Html5QrcodeScanner('reader', {
                    qrbox: {
                        width: 500,
                        height: 500
                    },
                    fps: 5,
                });
                scanner.render(success, error);
                scannerRef.current = scanner;

                async function success(result) {
                    scanner.clear();
                    setScanResult(result);
                }

                function error(result) {
                    console.log(result);
                }

                if ("getInternalApi" in scanner) {
                    const internalApi = scanner?.getInternalApi();
                    const adjustQrBoxSize = () => {
                        const video = internalApi?.getVideoElement();
                        if (video) {
                            const aspectRatio = video?.videoWidth / video?.videoHeight;
                            const newWidth = Math.min(500, video?.videoWidth);
                            const newHeight = Math.min(500 / aspectRatio, video?.videoHeight);
                            internalApi?.setQrRegion(newWidth, newHeight);
                        }
                    };

                    internalApi?.onCameraStart(() => {
                        adjustQrBoxSize();
                    });
                }
            }
            clearTimeout(timerId);
        }, 500);

        return () => {
            if (scannerRef?.current) {
                scannerRef?.current.clear();
            }
        };
    }, [qrCode]);

    const toggleFlash = async () => {
        if (!videoTrackRef.current) {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            videoTrackRef.current = stream.getVideoTracks()[0];
        }

        const capabilities = videoTrackRef.current.getCapabilities();
        if (capabilities.torch) {
            const currentTorchState = flashOn;
            const newTorchState = !currentTorchState;

            try {
                await videoTrackRef.current.applyConstraints({
                    advanced: [{ torch: newTorchState }]
                });
                setFlashOn(newTorchState);
            } catch (error) {
                console.error('Failed to toggle torch', error);
            }
        } else {
            alert('Torch/Flashlight is not supported on this device.');
        }
    };

    const matchQRData = async () => {
        if (type === "Pickup and Refund") {
            await axiosInstance.post(`/api/order/pickUp`, {
                "barcode": scanResult,
                "id": pickupId,
                "status": 2,
                "cityName": cityName
            })
                .then(() => {
                    setReloard(++reloard);
                    toast.success("Done");
                    handleClose();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                    handleClose();
                });
        } else {
            await axiosInstance.post(`/api/order`, {
                "barcode": scanResult,
                "id": id,
                "status": 2,
                "_id": _id
            })
                .then(() => {
                    set_id("");
                    toast.success("Done");
                    setReloard(++reloard);
                    handleClose();
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                    handleClose();
                });
        }
    };

    useEffect(() => {
        if (scanResult) {
            matchQRData();
        }
    }, [scanResult]);

    return (
        <div>
            <Modal
                open={qrCode}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="App">
                        <h1>QR Code Scanner</h1>
                    </div>
                    <div id="reader"></div>
                    <div style={{ gap: "5px", display: "flex" }}>
                        <button className='qrbutton' onClick={toggleFlash}>
                            {flashOn ? 'Turn off Flash' : 'Turn on Flash'}
                        </button>
                        <button className='qr' onClick={handleClose}>
                            exit
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

// const QrCodeScanner = ({ set_id, _id, pickupId, cityName, type, qrCode, handleClose, id, setReloard, reloard }) => {
//     const [scanResult, setScanResult] = useState(null);
//     const [flashOn, setFlashOn] = useState(false);
//     const videoTrackRef = useRef(null);
//     const scannerRef = useRef(null);

//     useEffect(() => {
//         let timerId = setTimeout(() => {
//             if (document.getElementById('reader')) {
//                 const scanner = new Html5QrcodeScanner('reader', {
//                     qrbox: (viewfinderWidth, viewfinderHeight) => {
//                         // Adjusting the qrbox size based on the video element's aspect ratio
//                         const aspectRatio = viewfinderWidth / viewfinderHeight;
//                         const newWidth = Math.min(500, viewfinderWidth);
//                         const newHeight = newWidth / aspectRatio;
//                         return { width: newWidth, height: newHeight };
//                     },
//                     fps: 5,
//                 });
//                 scanner.render(success, error);
//                 scannerRef.current = scanner;

//                 function success(result) {
//                     scanner.clear();
//                     setScanResult(result);
//                 }

//                 function error(result) {
//                     console.log(result);
//                 }
//             }
//             clearTimeout(timerId);
//         }, 500);

//         return () => {
//             if (scannerRef.current) {
//                 scannerRef.current.clear();
//             }
//         };
//     }, [qrCode]);

//     const toggleFlash = async () => {
//         if (!videoTrackRef.current) {
//             const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
//             videoTrackRef.current = stream.getVideoTracks()[0];
//         }

//         const capabilities = videoTrackRef.current.getCapabilities();
//         if (capabilities.torch) {
//             const newTorchState = !flashOn;
//             try {
//                 await videoTrackRef.current.applyConstraints({
//                     advanced: [{ torch: newTorchState }]
//                 });
//                 setFlashOn(newTorchState);
//             } catch (error) {
//                 console.error('Failed to toggle torch', error);
//             }
//         } else {
//             alert('Torch/Flashlight is not supported on this device.');
//         }
//     };

//     const matchQRData = async () => {
//         const postData = {
//             barcode: scanResult,
//             status: 2,
//             cityName
//         };

//         const apiUrl = type === "Pickup and Refund" ? `/api/order/pickUp` : `/api/order`;
//         const data = type === "Pickup and Refund"
//             ? { ...postData, id: pickupId }
//             : { ...postData, id, _id };

//         try {
//             await axiosInstance.post(apiUrl, data);
//             setReloard(reloard + 1);
//             toast.success("Done");
//             handleClose();
//         } catch (error) {
//             toast.error(error.response?.data?.message || "Error occurred");
//             handleClose();
//         }
//     };

//     useEffect(() => {
//         if (scanResult) {
//             matchQRData();
//         }
//     }, [scanResult]);

//     return (
//         <div>
//             <Modal
//                 open={qrCode}
//                 onClose={handleClose}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Box sx={style}>
//                     <div className="App">
//                         <h1>QR Code Scanner</h1>
//                     </div>
//                     <div id="reader"></div>
//                     <div style={{ gap: "5px", display: "flex" }}>
//                         <button className='qrbutton' onClick={toggleFlash}>
//                             {flashOn ? 'Turn off Flash' : 'Turn on Flash'}
//                         </button>
//                         <button className='qr' onClick={handleClose}>
//                             Exit
//                         </button>
//                     </div>
//                 </Box>
//             </Modal>
//         </div>
//     );
// };


export default QrCodeScanner;
