import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import {
  Modal,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./input.module.css";
import NativeSelect from "@mui/material/NativeSelect";
import { logDOM } from "@testing-library/react";
import Creatable, { useCreatable } from 'react-select/creatable';
import { BorderBottom } from "@mui/icons-material";

const BasicTextFields = ({ handleClearFilter, vehicleData, filter, setfilter }) => {

  const ref = useRef()
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [dateFilterStatus, setDateFilterStatus] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [sortStatus, setSortStatus] = useState("");
  const [sortFillterStatus, setSortFillterStatus] = useState("");
  const [orderId, setorderId] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState();
  const [adhocVehicle, setAdhocvVehicle] = useState();

  // const [vehicleDataList, setVehicleDataList] = useState([{ "vehicle number": "All" }, ...vehicleData])

  const [vehicleDataList, setVehicleDataList] = useState([{ "vehicle number": "All" }, ...(vehicleData || [])]);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const setAllfilter = () => {
    setName("")
    setorderId("")
    setCategory("")
    setStatus('')
    setDateFilterStatus("")
    setDateRange([null, null])
    setfilter({})
    setSortStatus("")
    setSortFillterStatus("")
    window.location.reload()
  }


  let SubCategory = [
    "Pickup and Refund",
    "Replace",
    "Repair",
    "Relocation",
    "Installation",
    "Upgrade",
  ];

  let orderSubCategory = ["New - Rental", "New - Buy", "B2B", "Offline Rental"];

  let orderStatus = [
    "Delivered",
    "Delivery Failed",
    "Order Verified",
    "Delivery Scheduled",
  ];

  let PickupAndRefund = [
    "Pickup Pending",
    "Pickup Failed",
    "Pickup Done",
    "Pickup Scheduled",
  ]
  let subCategoryStatus = [
    "Scheduled",
    "Completed",
    "Pending",
  ];

  const inputStyles = {
    fontSize: "13px", // Set your desired font size
    fontFamily: "YourPreferredFont, sans-serif", // Replace with your desired font family
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  let handleDate = (event) => {
    setDateRange(event);
    if (!event[0] == null && !event[1] == null) {
      // console.log("event", event);
    }
  };

  let submit = () => {
    let error = false;
    if (dateFilterStatus) {
      if (dateRange[0] == null || dateRange[1] == null) {
        error = true;
        toast.warning("please select date");
      }
    }
    if ((dateRange[0] !== null, dateRange[1] !== null)) {
      if (!dateFilterStatus) {
        error = true;
        toast.warning("please select date status");
      }
    }
    if (sortFillterStatus || sortStatus) {
      if (!sortFillterStatus) {
        error = true;
        toast.warning("please sort value");
      }
      if (!sortStatus) {
        error = true;
        toast.warning("please sort status");
      }
    }

    if (!error) {
      if (dateFilterStatus) {
        let start = new Date(dateRange[0]).toUTCString();
        let end = new Date(dateRange[1]);
        end = end.setHours(23, 59, 59, 999);
        end = new Date(end).toUTCString();

        setfilter({
          ...filter,
          type: dateFilterStatus,
          start: new Date(start).getTime(),
          end: new Date(end).getTime(),
        });
      }
      if (sortFillterStatus) {
        setfilter({ ...filter, sortType: sortStatus, sort: sortFillterStatus });
      }
      setOpen(false);
    }
  };

  let handleSortFillterStatus = (event) => {
    setSortFillterStatus(event.target.value);
  };

  let handleDateFilterStatus = (event) => {
    setDateFilterStatus(event.target.value);
  };

  let handleChangeCategory = (event) => {
    setCategory(event.target.value);
    setfilter({ ...filter, category$contains: event.target.value });
  };

  let handleChangeSubCategory = (event) => {
    setSubCategory(event.target.value);
    setfilter({ ...filter, subCategory$contains: event.target.value });
  };

  let handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setfilter({ ...filter, status$contains: event.target.value });
  };

  const nameClick = (event) => {
    if (event.key == "Enter") {
      let some = name + "$contains";
      setfilter({ ...filter, [some]: event.target.value });
    }
  };
  let setNewName = (name) => {
    setName(name);
  };

  const handleChangeTransport = (e) => {

    if (e) {
      setVehicleNumber(e);
      setAdhocvVehicle('');
      setfilter({ ...filter, adhoc_vehicle$contains: "", transport$contains: e });
    }
  }

  // const handleKeyDownF = (e) => {
  //   if (e.target.value) {
  //     e.preventDefault();
  //     // let data = vehicleDataList;
  //     // data.push({ "vehicle number": e.target.value })
  //     // setVehicleDataList(data);
  //     setAdhocvVehicle(e.target.value);

  //     setfilter({ ...filter, transport$contains: "", adhoc_vehicle$contains: e.target.value });
  //   } else {
  //     e.preventDefault();
  //     setAdhocvVehicle('');
  //     setVehicleNumber('')
  //     setfilter({ ...filter, transport$contains: "", adhoc_vehicle$contains: "" });
  //   }

  // }

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     let data = vehicleDataList;
  //     data.push({ "vehicle number": event.target.value })
  //     setVehicleDataList(data);
  //     // Add your desired functionality here
  //   }
  // };

  const handleKeyDown = async (e) => {
    if (e) {
      // let data = vehicleDataList;
      // data.push({ "vehicle number": e.target.value })
      // setVehicleDataList(data);
      setAdhocvVehicle(e);
      setfilter({ ...filter, transport$contains: "", adhoc_vehicle$contains: e });
    } else {
      e.preventDefault();
      setAdhocvVehicle('');
      setVehicleNumber('')
      setfilter({ ...filter, transport$contains: "", adhoc_vehicle$contains: "" });
    }
  };

  // const handleOnChange = (e) => {
  //   let data = e.value;
  //   vehicleDataList?.filter((item) => {
  //     if (item["vehicle number"] !== data) {
  //       handleKeyDown(data)
  //     } else {
  //       handleChangeTransport(data)
  //     }
  //   })
  // }
  const handleOnChange = (e) => {
    let data = e.value;
    let matchFound = false;

    vehicleDataList?.forEach((item) => {
      if (item["vehicle number"] === data) {
        handleChangeTransport(data);
        matchFound = true;
        return;  // Exit the loop early once a match is found
      }
    });

    // If no match is found, call handleKeyDown
    if (!matchFound) {
      handleKeyDown(data);
    }
  };

  const handlePoint = (e) => {
    if (e.key == "Enter") {
      setfilter({ ...filter, ['point']: e.target.value });
    }
  }

  const handleLocation = (e) => {
    if (e.key == "Enter") {
      setfilter({ ...filter, ['location']: e.target.value });
    }
  }



  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { width: "15ch" },
      }}
      noValidate
      autoComplete="off"
      display="flex"
    >
      <TextField
        InputProps={{
          style: inputStyles,
        }}
        sx={{ width: "115px", marginTop: "-5px", marginBottom: "15px" }}
        id="standard-basic"
        label="Name"
        variant="standard"
        onKeyDown={nameClick}
        onClick={() => setNewName("name")}
      />
      <TextField
        InputProps={{
          style: inputStyles,
        }}
        sx={{
          width: "115px",
          marginTop: "-5px",
          marginLeft: "15px",
          marginBottom: "15px",
        }}
        id="standard-basic"
        label="Oder Id"
        variant="standard"
        onKeyDown={nameClick}
        onClick={() => setNewName("orderId")}
      />
      <TextField
        InputProps={{
          style: inputStyles,
        }}
        sx={{
          width: "115px",
          marginTop: "-5px",
          marginLeft: "15px",
          marginBottom: "15px",
        }}
        id="standard-basic"
        label="Email"
        variant="standard"
        onKeyDown={nameClick}
        onClick={() => setNewName("email")}
      />
      <TextField
        InputProps={{
          style: inputStyles,
        }}
        sx={{
          width: "115px",
          marginTop: "-5px",
          marginLeft: "15px",
          marginBottom: "15px",
        }}
        id="standard-basic"
        label="Ticket Id"
        variant="standard"
        onKeyDown={nameClick}
        onClick={() => setNewName("ticketNumber")}
      />
      <Box sx={{ marginLeft: "15px", marginTop: "-9px", width: "100px" }}>
        <FormControl variant="standard" sx={{ minWidth: 120, width: "100px" }}>
          <InputLabel
            sx={{ fontSize: "15px" }}
            id="demo-simple-select-standard-label"
          >
            Category
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={category}
            onChange={handleChangeCategory}
            label="category"
          >
            <MenuItem value={"Order"}>Order</MenuItem>
            <MenuItem value={"Service Request"}>Service Request</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ marginLeft: "15px", marginTop: "-9px" }}>
        <FormControl variant="standard" sx={{ minWidth: 120, width: "100px" }}>
          <InputLabel
            sx={{ fontSize: "15px" }}
            id="demo-simple-select-standard-label"
          >
            Sub Category
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={subCategory}
            onChange={handleChangeSubCategory}
            label="Sub Category"
          >
            {category == "Order"
              ? orderSubCategory.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))
              : null}
            {category == "Service Request"
              ? SubCategory.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))
              : null}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginLeft: "15px", marginTop: "-9px", }}>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel
            sx={{ fontSize: "15px" }}
            id="demo-simple-select-standard-label"
          >
            Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={status}
            onChange={handleChangeStatus}
            label="Status">
            {category == "Order"
              ? orderStatus.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))
              : null}

            {subCategory == "Pickup and Refund"
              ? PickupAndRefund.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))
              : subCategoryStatus.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ marginLeft: "15px", marginTop: "0px", }}>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel
            sx={{ fontSize: "15px" }}
            id="demo-simple-select-standard-label">
            {adhocVehicle ? adhocVehicle : "Transport"}
          </InputLabel>
          <Creatable styles={{ border: 'none' }} placeholder={"Transport"} id="Custom-Select" onChange={handleOnChange} isClearable={true} options={vehicleDataList?.map((item) => {
            return { value: item?.["vehicle number"], label: item?.["vehicle number"] }
          })} />
        </FormControl>
      </Box>

      <Box sx={{ width: "140px !important", display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: "15px", marginTop: "-23px", }}>
        <TextField InputProps={{ style: inputStyles, }}
          sx={{ width: "700px", }}
          variant="standard"
          id="standard-basic" label="Location"
          onKeyDown={handleLocation} />

        <TextField InputProps={{ style: inputStyles, }}
          sx={{ width: "700px", marginLeft: "10px" }} variant="standard"
          id="standard-basic" label="Point"
          onKeyDown={handlePoint} />
      </Box>
      <Box sx={{ marginTop: "7px" }}>
        {/* <Button
          onClick={handleOpen}
          variant="contained"
          sx={{
            whiteSpace: "nowrap",
            height: "30px",
            backgroundColor: "#000",
            color: "#fff",
            fontSize: "13px !important",
            "&:hover": {
              backgroundColor: "#000",
              opacity: "0.8",
            },
            "@media (max-width: 785px)": {
              fontSize: "0.6rem !important",
              // width:"60px",
              height: "30px",
            },
          }}
        >
          Date Filter
        </Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box>
            <Box sx={style}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Box sx={{ minWidth: 100 }}>
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      status
                    </InputLabel>
                    <NativeSelect
                      defaultValue={dateFilterStatus}
                      onChange={handleDateFilterStatus}
                    >
                      <option value={""}></option>
                      <option value={"scheduled"}>Scheduled</option>
                      <option value={"assigned"}>Assigned</option>
                      <option value={"deliver"}>Completed</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
                <Box sx={{ width: "100px", marginTop: "19px" }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    id={`${styles.input}`}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDate}
                    isClearable={true}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", marginTop: "10px", gap: "10px" }}>
                <Box>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel
                      sx={{ fontSize: "15px" }}
                      id="demo-simple-select-standard-label"
                    >
                      Sort By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={sortStatus}
                      onChange={(event) => setSortStatus(event.target.value)}
                      label="category"
                    >
                      <MenuItem value={"assigned"}>Assigned</MenuItem>
                      <MenuItem value={"scheduled"}>Schedule</MenuItem>
                      <MenuItem value={"deliver"}>Completed</MenuItem>
                    </Select>
                  </FormControl>
                </Box>{" "}
                <Box>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel
                      sx={{ fontSize: "15px" }}
                      id="demo-simple-select-standard-label"
                    >
                      Sort
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={sortFillterStatus}
                      onChange={handleSortFillterStatus}>

                      <MenuItem value={"AEC"}>AEC</MenuItem>
                      <MenuItem value={"DESC"}>DESC</MenuItem>

                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "10px",
                    whiteSpace: "nowrap",
                    height: "30px",
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "13px !important",
                    "&:hover": {
                      backgroundColor: "#000",
                      opacity: "0.8",
                    },
                    "@media (max-width: 785px)": {
                      fontSize: "0.6rem !important",
                      // width:"60px",
                      height: "30px",
                    },
                  }}
                  onClick={submit}
                >
                  Filter
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>

      <Box sx={{ marginTop: "7px", marginLeft: "-17px" }}>
        {/* <Button
          onClick={() => setAllfilter()}
          variant="contained"
          sx={{
            whiteSpace: "nowrap",
            height: "30px",
            backgroundColor: "#000",
            color: "#fff",
            fontSize: "13px !important",
            "&:hover": {
              backgroundColor: "#000",
              opacity: "0.8",
            },
            "@media (max-width: 785px)": {
              fontSize: "0.6rem !important",
              // width:"60px",
              height: "30px",
            },
          }}>
          Clear Filter
        </Button> */}
      </Box>
    </Box>
  );
}


export default BasicTextFields;