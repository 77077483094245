import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import phone from "../../assets/phone.png";
import Email from "../../assets/Email.png";
import Contact from "../../assets/Contact.png";
import axiosInstance from "../../axios/AxiosInstance";
import { toast } from "react-toastify";

import { Delhi_wareHouse_did, Banglore_wareHouse_did, Mumbai_Warehouse_did, Hyderabad_Warehouse_did, Pune_Warehouse_did } from "../../ozonetel/did";
import { Delhi_wareHouse_Number, Banglore_wareHouse_Number, Mumbai_Warehouse_Number, Hyderabad_Warehouse_Number, Pune_Warehouse_Number } from "../../ozonetel/did";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "black",
};

export default function Contect({ data }) {
  // console.log(data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [canCall, setCanCall] = React.useState(true);

  const userData = JSON.parse(localStorage.getItem('wh_tk'))?.data;




  const checkDid = () => {
    if (userData?.warehouses[0]?.city == "Mumbai") {
      return Mumbai_Warehouse_did;
    }
    else if (userData?.warehouses[0]?.city == "Bangalore") {
      return Banglore_wareHouse_did;
    }
    else if (userData?.warehouses[0]?.city == "Gurgaon") {
      return Delhi_wareHouse_did;
    }
    else if (userData?.warehouses[0]?.city == "Hyderabad") {
      return Hyderabad_Warehouse_did;
    }
    else if (userData?.warehouses[0]?.city == "Pune") {
      return Pune_Warehouse_did;
    }
  }

  const checknumber = () => {
    if (userData?.warehouses[0]?.city == "Mumbai") {
      return Mumbai_Warehouse_Number;
    }
    else if (userData?.warehouses[0]?.city == "Bangalore") {
      return Banglore_wareHouse_Number;
    }
    else if (userData?.warehouses[0]?.city == "Gurgaon") {
      return Delhi_wareHouse_Number;
    }
    else if (userData?.warehouses[0]?.city == "Hyderabad") {
      return Hyderabad_Warehouse_Number;
    }
    else if (userData?.warehouses[0]?.city == "Pune") {
      return Pune_Warehouse_Number;
    }
  }


  const handleCustomerCall = async (CustomerNumber) => {
    if (canCall) {
      setCanCall(false)
      try {
        const res = await axiosInstance.get(`${process.env.REACT_APP_OZONETEL_BASE_URL}/CAServices/PhoneManualDial.php?apiKey=${process.env.REACT_APP_OZONETEL_API_KEY}&userName=${process.env.REACT_APP_OZONETEL_USER_NAME}&custNumber=${CustomerNumber}&phoneName=${checknumber()}&did=${checkDid()}`);
        console.log(res)
        toast.success('Connecting your call, please wait...')
      } catch (error) {
        toast.success('Connecting your call, please wait...')
        toast.error('something went wrong ')
        console.log(error)
      } finally {
        setTimeout(() => {
          setCanCall(true)
        }, 3000);
      }
    }
  }

  return (
    <div>
      <img style={{ width: "27px" }} src={Contact} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h3>Contact Information</h3>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ padding: "5px" }}>
                <img style={{ width: "25px" }} src={phone} alt="react logo" />
              </Box>
              {userData?.type === "manager" ?
                <Box sx={{ padding: "5px" }}>
                  <button className='CallButtonToCustomer' onClick={() => handleCustomerCall(data?.phone)}>Call </button>
                </Box> :
                <Box sx={{ padding: "5px" }}>
                  {data?.phone}
                </Box>
              }

            </Box>
            {data?.cf_alt_contact_number !== "N/A" && <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ padding: "5px" }}>
                <img style={{ width: "25px" }} src={phone} alt="react logo" />
              </Box>
              {console.log(data?.cf_alt_contact_number, "Data from contact")}
              {userData?.type === "manager" ?
                <Box sx={{ padding: "5px" }}>
                  <button className='CallButtonToCustomer' onClick={() => handleCustomerCall(data?.cf_alt_contact_number)}>Call Alt Number</button>
                </Box> :
                <Box sx={{ padding: "5px" }}>
                  Alt Number: {data?.cf_alt_contact_number}
                </Box>
              }
            </Box>}
            <Box sx={{ display: "flex" }}>
              <Box sx={{ padding: "5px" }}>
                <img style={{ width: "25px" }} src={Email} alt="react logo" />
              </Box>
              <Box sx={{ padding: "5px" }}>{data.email}</Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ padding: "5px" }}>
                <img style={{ width: "25px" }} src={Email} alt="react logo" />
              </Box>
              <Box sx={{ padding: "5px" }}>{
                data.address?.cf_address_1
              }{data.address?.cf_address_2}{data.address?.cf_area}{data.address?.cf_city},{data.address?.cf_pincode},{data.address?.cf_state}</Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
