import { Box, Stack, Typography, OutlinedInput, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ManagerTable from './managerTable/ManagerTable';
import './Manager.css';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import FilterManager from "./managerTable/FilterManager";

function Manager() {

    const navigate = useNavigate();
    const [showAdd, setShowAdd] = useState(false);
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);

    function createData(curr) {
        return { ...curr };
    }

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('wh_tk'))?.data;
        if (userData) {
            const userRole = userData.role;
            const outArr = userData.out;
            const userPermissions = userRole[0].permissions;

            userPermissions?.map((curr, index) => {
                if (outArr[index].key === 'manager' && curr.create === true) {
                    setShowAdd(true);
                }
            })
        }
        // console.log(userData);
    }, [])

    return (
        <div >
            <Typography sx={{
                fontSize: '1.5rem',
                fontWeight: '700',
                marginTop: "30px"
            }} >Managers</Typography>
            <Box sx={{
                width: '100%',
                height: '100%',
                // padding: '1.5rem',
                backgroundColor: '#fff',
                marginTop: '1rem'
            }} >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        marginBottom: '1rem',
                        flexWrap: 'wrap'
                    }} >
                    {/* <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            flexWrap: 'wrap'
                        }} >
                        <Stack
                            className="wh_searchEmailInput"
                            direction='row'
                            alignItems='center' >
                            <SearchIcon sx={{
                                color: '#8F8F8F',
                            }} />
                            <input
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    fontFamily: 'Poppins, sans-serif',
                                    padding: '0.1rem'
                                }}
                                placeholder="Search customer..."
                            />
                        </Stack>
                        <select className="wh_selectEmailInput" >
                            <option>Email</option>
                        </select>
                        <select className="wh_selectEmailInput" >
                            <option>Exact Search</option>
                        </select>
                        <select className="wh_selectEmailInput" >
                            <option>All Managers</option>
                        </select>
                    </Stack> */}
                    <FilterManager setManagers={setManagers} setLoading={setLoading} createData={createData} loading={loading} />
                    {showAdd &&
                        <Stack>
                            <button
                                // variant="contained"
                                // sx={{
                                //     width: '200px',
                                //     backgroundColor: '#000',
                                //     color: '#fff',
                                //     '&:hover': {
                                //         backgroundColor: '#000',
                                //         opacity: '0.8'
                                //     }
                                // }}
                                className="TatSubmitbutton"
                                onClick={() => { navigate('/agent/add_manager') }}
                            >
                                <AddIcon sx={{
                                    marginRight: '0.5rem'
                                }} />
                                Add Manager
                            </button>
                        </Stack>
                    }
                </Stack>
                <ManagerTable
                    managers={managers}
                    setManagers={setManagers}
                    loading={loading}
                    setLoading={setLoading}
                    createData={createData}
                />
            </Box>
        </div>
    )
}

export default Manager;