import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from '../../axios/AxiosInstance'; // Assuming you have this set up
import styled from 'styled-components';
import BounceLoader from "react-spinners/BounceLoader";
import DataNotFound from '../../assets/dataNotfound.svg'

const TableContainer = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border:1px solid black;
  border-radius:10px;
`;

const TableHeader = styled.th`
  background-color: #d9e1f2;
  color:#000;
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
  
`;

const TableHeader1 = styled.th`
  background-color: #d9e1f2;
  color:#000;
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
    white-space: nowrap;
  
`;



const TableCell = styled.td`
  border: 1px solid #000;
  padding: 8px;
    text-align: center; 
`;
const TableCell1 = styled.td`
  border: 1px solid #000;
  padding: 8px;
    text-align: center;
   background-color: #d9e1f2;
  color:#000;
`;

const Label = styled.label`
  margin-right: 10px;
`;

const DatePickerContainer = styled.div`
  margin: 20px 0;
  display:flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
`;

const TotalRow = styled.div`
  font-weight: bold;
  margin-top: 20px;
  text-align: right;
`;

const ScheduleDateHeader = styled(TableHeader)`
  width: 20%; /* Adjust this width value as needed */
`;

const OrderPickupTable = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const [orderColumns, setOrderColumns] = useState([]);
    const [pickupColumns, setPickupColumns] = useState([]);
    const [serviceColumns, setServiceColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDate = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const getData = async (startDataV, endDataV) => {
        setLoading(true);
        setData([])
        try {
            if (startDataV && endDataV) {
                const start = new Date(startDataV);
                const end = new Date(endDataV);
                end.setHours(23, 59, 59, 999);
                try {
                    const response = await axiosInstance.get(`api/ticket/oderIdData?start=${start.toISOString()}&end=${end.toISOString()}`);
                    setData(response.data.data);
                    determineColumns(response.data.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            if (startDate && endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);
                end.setHours(23, 59, 59, 999);
                try {
                    const response = await axiosInstance.get(`api/ticket/oderIdData?start=${start.toISOString()}&end=${end.toISOString()}`);
                    setData(response.data.data);
                    determineColumns(response.data.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        } catch (error) {
        } finally {
            setLoading(false)
        }
    };

    const determineColumns = (data) => {
        const orderCols = new Set();
        const pickupCols = new Set();
        const serviceCols = new Set();

        data.forEach(cityData => {
            if (cityData.Order) {
                Object.keys(cityData.Order).forEach(key => orderCols.add(parseInt(key)));
            }
            if (cityData.PickupandRefund) {
                Object.keys(cityData.PickupandRefund).forEach(key => pickupCols.add(parseInt(key)));
            }
            if (cityData.ServiceRequest) {
                Object.keys(cityData.ServiceRequest).forEach(key => serviceCols.add(parseInt(key)));
            }
        });

        setOrderColumns(Array.from(orderCols).sort((a, b) => a - b));
        setPickupColumns(Array.from(pickupCols).sort((a, b) => a - b));
        setServiceColumns(Array.from(serviceCols).sort((a, b) => a - b));
    };

    // useEffect(() => {
    //     if (startDate && endDate) {
    //         getData();
    //     }
    // }, [startDate, endDate]);

    const handleOrderData = () => {
        if (startDate && endDate) {
            getData()
        }
    }

    const getInitialDate = () => {
        const today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1); // Correctly subtract one day
        setStartDate(yesterday);
        setEndDate(yesterday);
        getData(yesterday, yesterday);
    }

    useEffect(() => {
        getInitialDate()
    }, [])

    return (
        <TableContainer>
            <h3 style={{ fontSize: "26px", }}>
                Order Page
            </h3>
            <DatePickerContainer>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDate}
                    id="DashboardInput" />

                <button onClick={() => handleOrderData()} className="TatSubmitbutton">Submit</button>

            </DatePickerContainer>
            {data?.length > 0 ? <>
                <OrderTable data={data} orderColumns={orderColumns} startDate={startDate} endDate={endDate} />
                <ServiceRequestTable data={data} serviceColumns={serviceColumns} startDate={startDate} endDate={endDate} />
                <PickupAndRefundTable data={data} pickupColumns={pickupColumns} startDate={startDate} endDate={endDate} /></>
                : loading ? <div className="LoadingContainer"><BounceLoader /> </div> :
                    <div className='NotFoundContainer' style={{ marginTop: "10px" }}>
                        <img className='dataNotFountContainerImage' src={DataNotFound} />
                        <div style={{ fontSize: "26px" }}>Data Not Found</div>
                    </div>
            }
        </TableContainer>
    );
};

const OrderTable = ({ data, orderColumns, startDate, endDate }) => {
    const calculateTotal = (cityData) => {
        return Object.values(cityData.Order || {}).reduce((total, count) => total + count, 0);
    };

    const grandTotal = data.reduce((total, cityData) => total + calculateTotal(cityData), 0);

    const columnTotals = orderColumns.map(col =>
        data.reduce((total, cityData) => total + (cityData.Order?.[col] || 0), 0)
    );

    return (
        <div style={{ overflow: 'scroll' }}>
            {/* <StyledTable>
                <thead>
                    <tr>
                        <TableHeader colSpan={orderColumns.length + 3}>3.1 Order</TableHeader>
                    </tr>
                    <tr>
                        <ScheduleDateHeader>Scheduled Date</ScheduleDateHeader>
                        <TableHeader>City</TableHeader>
                        <TableHeader colSpan={orderColumns.length}>Order Count</TableHeader>
                        <TableHeader >Total Result</TableHeader>
                    </tr>
                    <tr>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        {orderColumns.map(col => <TableHeader key={col}>{col}</TableHeader>)}
                        <TableHeader></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.map((cityData, index) => (
                        <tr key={cityData._id}>
                            <TableCell >{startDate && endDate && index === 0 ?
                                `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}</TableCell>
                            <TableCell>{cityData._id}</TableCell>
                            {orderColumns.map(col => (
                                <TableCell key={col}>{cityData.Order?.[col] || ''}</TableCell>
                            ))}
                            <TableCell>{calculateTotal(cityData)}</TableCell>
                        </tr>
                    ))}
                    <tr>
                        <TableCell1 colSpan={2}>Total Result</TableCell1>
                        {columnTotals.map((total, index) => (
                            <TableCell1 key={index}>{total}</TableCell1>
                        ))}
                        <TableCell1 >{grandTotal}</TableCell1>
                    </tr>
                </tbody>
            </StyledTable> */}
            <StyledTable>
                <thead>
                    <tr>
                        <TableHeader colSpan={orderColumns.length + 3}>3.1 Order</TableHeader>
                    </tr>
                    <tr>
                        <ScheduleDateHeader>Scheduled Date</ScheduleDateHeader>
                        <TableHeader>City</TableHeader>
                        <TableHeader colSpan={orderColumns.length}>Order Count</TableHeader>
                        <TableHeader>Total Result</TableHeader>
                    </tr>
                    <tr>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        {orderColumns.map((col) => (
                            <TableHeader key={col}>{col}</TableHeader>
                        ))}
                        <TableHeader></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.map((cityData, index) => (
                        <tr key={cityData._id}>
                            {index === 0 && (
                                <TableCell rowSpan={data.length}>
                                    {startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}
                                </TableCell>
                            )}
                            <TableCell>{cityData._id}</TableCell>
                            {orderColumns.map((col) => (
                                <TableCell key={col}>{cityData.Order?.[col] || ''}</TableCell>
                            ))}
                            <TableCell>{calculateTotal(cityData)}</TableCell>
                        </tr>
                    ))}
                    <tr>
                        <TableCell1 colSpan={2}>Total Result</TableCell1>
                        {columnTotals.map((total, index) => (
                            <TableCell1 key={index}>{total}</TableCell1>
                        ))}
                        <TableCell1>{grandTotal}</TableCell1>
                    </tr>
                </tbody>
            </StyledTable>

        </div>
    );
};

const ServiceRequestTable = ({ data, serviceColumns, startDate, endDate }) => {
    const calculateTotal = (cityData) => {
        return Object.values(cityData.ServiceRequest || {}).reduce((total, count) => total + count, 0);
    };

    const grandTotal = data.reduce((total, cityData) => total + calculateTotal(cityData), 0);

    const columnTotals = serviceColumns.map(col =>
        data.reduce((total, cityData) => total + (cityData.ServiceRequest?.[col] || 0), 0)
    );

    return (
        <div style={{ overflow: 'scroll' }}>
            {/* <StyledTable>
                <thead>
                    <tr>
                        <TableHeader colSpan={serviceColumns.length + 3}>3.2 Service Request</TableHeader>
                    </tr>
                    <tr>
                        <TableHeader>Scheduled Date</TableHeader>
                        <TableHeader>City</TableHeader>
                        <TableHeader colSpan={serviceColumns.length}>Service Request Count</TableHeader>
                        <TableHeader>Total Result</TableHeader>
                    </tr>
                    <tr>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        {serviceColumns.map(col => <TableHeader key={col}>{col}</TableHeader>)}
                        <TableHeader></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((cityData, index) => (
                        <tr key={cityData._id}>
                            <TableCell >{startDate && endDate && index === 0 ?
                                `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}</TableCell>
                            <TableCell>{cityData._id}</TableCell>
                            {serviceColumns.map(col => (
                                <TableCell key={col}>{cityData.ServiceRequest?.[col] || ''}</TableCell>
                            ))}
                            <TableCell>{calculateTotal(cityData)}</TableCell>
                        </tr>
                    ))}
                    <tr>
                        <TableCell1 colSpan={2}>Total Result</TableCell1>
                        {columnTotals.map((total, index) => (
                            <TableCell1 key={index}>{total}</TableCell1>
                        ))}
                        <TableCell1>{grandTotal}</TableCell1>
                    </tr>
                </tbody>
            </StyledTable> */}
            <StyledTable>
                <thead>
                    <tr>
                        <TableHeader colSpan={serviceColumns.length + 3}>3.2 Service Request</TableHeader>
                    </tr>
                    <tr>
                        <ScheduleDateHeader>Scheduled Date</ScheduleDateHeader>
                        <TableHeader>City</TableHeader>
                        <TableHeader colSpan={serviceColumns.length}>Service Request Count</TableHeader>
                        <TableHeader>Total Result</TableHeader>
                    </tr>
                    <tr>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        {serviceColumns.map(col => <TableHeader key={col}>{col}</TableHeader>)}
                        <TableHeader></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {/* Render the first row with the date spanning all rows */}
                    <tr>
                        <TableCell rowSpan={data.length}>
                            {startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}
                        </TableCell>
                        <TableCell>{data[0]?._id}</TableCell>
                        {serviceColumns.map(col => (
                            <TableCell key={col}>{data[0]?.ServiceRequest?.[col] || ''}</TableCell>
                        ))}
                        <TableCell>{calculateTotal(data[0])}</TableCell>
                    </tr>

                    {/* Render the remaining rows without the date */}
                    {data.slice(1).map((cityData) => (
                        <tr key={cityData._id}>
                            <TableCell>{cityData._id}</TableCell>
                            {serviceColumns.map(col => (
                                <TableCell key={col}>{cityData.ServiceRequest?.[col] || ''}</TableCell>
                            ))}
                            <TableCell>{calculateTotal(cityData)}</TableCell>
                        </tr>
                    ))}

                    <tr>
                        <TableCell1 colSpan={2}>Total Result</TableCell1>
                        {columnTotals.map((total, index) => (
                            <TableCell1 key={index}>{total}</TableCell1>
                        ))}
                        <TableCell1>{grandTotal}</TableCell1>
                    </tr>
                </tbody>
            </StyledTable>

        </div>
    );
};

const PickupAndRefundTable = ({ data, pickupColumns, startDate, endDate }) => {
    const calculateTotal = (cityData) => {
        return Object.values(cityData.PickupandRefund || {}).reduce((total, count) => total + count, 0);
    };

    const grandTotal = data.reduce((total, cityData) => total + calculateTotal(cityData), 0);

    const columnTotals = pickupColumns.map(col =>
        data.reduce((total, cityData) => total + (cityData.PickupandRefund?.[col] || 0), 0)
    );

    return (
        <div style={{ overflow: 'scroll' }}>
            {/* <StyledTable>
                <thead>
                    <tr>
                        <TableHeader1 colSpan={pickupColumns.length + 3}>3.3 Order Pickup</TableHeader1>
                    </tr>
                    <tr>
                        <TableHeader >Scheduled Date</TableHeader>
                        <TableHeader>City</TableHeader>
                        <TableHeader colSpan={pickupColumns.length}>Pickup Count</TableHeader>
                        <TableHeader>Total Result</TableHeader>
                    </tr>
                    <tr>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        {pickupColumns?.map(col => <TableHeader key={col}>{col}</TableHeader>)}
                        <TableHeader></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.map((cityData, index) => (
                        <tr key={cityData._id}>
                            <TableCell>{startDate && endDate && index === 0 ?
                                `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}</TableCell>
                            <TableCell>{cityData._id}</TableCell>
                            {pickupColumns?.map(col => (
                                <TableCell key={col}>{cityData.PickupandRefund?.[col] || ''}</TableCell>
                            ))}
                            <TableCell>{calculateTotal(cityData)}</TableCell>
                        </tr>
                    ))}
                    <tr>
                        <TableCell1 colSpan={2}>Total Result</TableCell1>
                        {columnTotals.map((total, index) => (
                            <TableCell1 key={index}>{total}</TableCell1>
                        ))}
                        <TableCell1>{grandTotal}</TableCell1>
                    </tr>
                </tbody>
            </StyledTable> */}
            <StyledTable>
                <thead>
                    <tr>
                        <TableHeader colSpan={pickupColumns.length + 3}>3.3 Order Pickup</TableHeader>
                    </tr>
                    <tr>
                        <ScheduleDateHeader>Scheduled Date</ScheduleDateHeader>
                        <TableHeader>City</TableHeader>
                        <TableHeader colSpan={pickupColumns.length}>Pickup Count</TableHeader>
                        <TableHeader>Total Result</TableHeader>
                    </tr>
                    <tr>
                        <TableHeader></TableHeader>
                        <TableHeader></TableHeader>
                        {pickupColumns?.map(col => <TableHeader key={col}>{col}</TableHeader>)}
                        <TableHeader></TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.map((cityData, index) => (
                        <tr key={cityData._id}>
                            {/* Render the Scheduled Date in the first row and merge it with rowSpan */}
                            {index === 0 && (
                                <TableCell rowSpan={data.length}>
                                    {startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}
                                </TableCell>
                            )}
                            <TableCell>{cityData._id}</TableCell>
                            {pickupColumns?.map(col => (
                                <TableCell key={col}>{cityData.PickupandRefund?.[col] || ''}</TableCell>
                            ))}
                            <TableCell>{calculateTotal(cityData)}</TableCell>
                        </tr>
                    ))}
                    <tr>
                        <TableCell1 colSpan={2}>Total Result</TableCell1>
                        {columnTotals.map((total, index) => (
                            <TableCell1 key={index}>{total}</TableCell1>
                        ))}
                        <TableCell1>{grandTotal}</TableCell1>
                    </tr>
                </tbody>
            </StyledTable>

        </div>
    );
};

export default OrderPickupTable;