
import React, { useEffect, useState } from 'react';
import "./TaskScreen.css"
import FilterModal from "./Filter"
import axiosInstance from '../../../axios/AxiosInstance';
import moment from "moment-timezone";
import { useNavigate } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import styled, { keyframes } from 'styled-components';
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ThemeProvider } from '@mui/material/styles';
import { theme1 } from "../../../mui_theme/theme";
import BounceLoader from "react-spinners/BounceLoader";
import DataNotFound from "../../../assets/dataNotfound.svg"

const TaskScreen = () => {
    const [open, setOpen] = useState(false);
    const [canCall, setCanCall] = useState(true)
    const [Today, setToday] = useState("");
    const [Done, setDone] = useState(false);
    const [Not, setNot] = useState(false);
    const [filter, setFilter] = useState("");
    const [status, setStatus] = useState(0);
    const [Data, setData] = useState([]);
    const [reset, setReset] = useState(0);
    const [punchStatus, setPunchStatus] = useState(false);
    let [reload, setReload] = useState(0);
    let [canLogOut, setCanLogOut] = useState(false);
    let [startTrip, setStartTrip] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleLogout = () => {
        if (!canLogOut) {
            localStorage.removeItem("wh_tk");
            navigate('/login', { replace: true });
        } else {
            toast.error("Please Stop The Trip First.");
        }
    }

    let today = () => {
        const now = new Date();
        const yesterday = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        yesterday.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);
        let start = now.getTime()
        now.setHours(23, 59, 59, 999);
        yesterday.setHours(23, 59, 59, 999);
        let end = now.getTime()
        let url = `&type=scheduled&start=${start}&end=${end}`;
        setToday(url)
        setReload(++reload)
    }

    let getData = async () => {
        setLoading(true)
        try {
            const UserData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
            let baseUrl = ''
            if (UserData?.type === "agent") {
                baseUrl = `api/ticket?page=1&limit=1000&agentId=${UserData?._id}`;
            } else {
                baseUrl = `api/ticket?page=1&limit=1000`;
            }
            if (Today) {
                baseUrl = baseUrl + Today
            }
            else if (filter) {
                baseUrl = baseUrl + filter.slice(0, -1)
            }
            else if (Done && status == 3) {
                baseUrl = baseUrl + "&" + "Done=" + Done
            }
            else if (Not && status == 2) {
                baseUrl = baseUrl + "&" + "Not=" + Not
            }
            else {
                var targetDate = new Date();
                var thirtyDaysAgo = new Date(targetDate);
                thirtyDaysAgo.setDate(targetDate.getDate() - 7);
                thirtyDaysAgo.setHours(23, 59, 59, 999);
                let start = new Date(thirtyDaysAgo).toUTCString();
                let end = new Date(targetDate);
                end = end.setHours(23, 59, 59, 999);
                end = new Date(end).toUTCString();
                baseUrl = baseUrl + `&type=scheduled&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`
            }

            axiosInstance.get(`api/agent/status`).then((tableRes) => { setStartTrip(tableRes?.data?.data); setPunchStatus(tableRes?.data?.data) }).catch((err) => {
                console.error(err);
            })
            axiosInstance.get(baseUrl).then((tableRes) => {

                setData(tableRes?.data?.data);
                setCanLogOut(tableRes?.data?.data?.find((item) => item?.tripStatus == true))
            }).catch((e) => {
                console.error(e);
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    let Pending = async () => {
        setStatus(2)
        setReload(++reload)
        setNot("yes")
    }

    let DoneSome = async () => {
        setStatus(3)
        setReload(++reload)
        setDone("yes")
    }
    const startLogin = async () => {
        let url = `api/agent/start?`
        if (!startTrip) {
            url = url + `startLoginTime=${new Date()}`;
            console.log(url);
        } else {
            url = url + `endTiLogouTime=${new Date()}`;
            console.log(url);
        }
        // axiosInstance.get(url).then((tableRes) => { setStartTrip(tableRes.data.data) })
    }
    const handleOpen = () => setOpen(true);

    useEffect(() => {
        getData()
    }, [reload])


    useEffect(() => {
        setOpen(false)
        setToday("")
        setDone(false)
        setNot(false)
        setStatus(0)
        setData([])
        setReload(++reload)
    }, [reset])

    function convertToIST(utcDateTime, dateTime) {
        let istDate = moment(utcDateTime).tz("Asia/Kolkata");
        if (dateTime == "date") {
            return istDate.format("YYYY-MM-DD");
        } else {
            return istDate.format("HH:mm");
        }
    }
    async function callPunchInApi() {
        let url = "/api/agent/start?"
        let authData = localStorage.getItem("wh_tk");
        authData = JSON.parse(authData)
        if (punchStatus) {
            url = url + `endTiLogouTime=${new Date()}`
            authData.endTiLogouTime = new Date()
            localStorage.setItem("wh_tk", JSON.stringify(authData));
            setPunchStatus(false);
            toast.success(`Punch ${punchStatus ? "Out" : "In"} Successfully`);
        } else {
            authData.startTiLogouTime = new Date()
            localStorage.setItem("wh_tk", JSON.stringify(authData));
            url = url + `startTiLogouTime=${new Date()}`
            setPunchStatus(true);
            toast.success(`Punch ${punchStatus ? "Out" : "In"} Successfully `);
        }
        try {
            axiosInstance.get(url)
        } catch (error) {
            console.log(error);
        }

    }

    let callStartEndTrip = async (data) => {
        let body = {}
        if (data.tripStatus) {
            body.endDate = new Date()
            body.id = data.id
        } else {
            body.startDate = new Date()
            body.id = data.id
        }
        let url = `/api/ticket/start/stoptrip`
        try {
            await axiosInstance.post(url, body)
            setReload(++reload)
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const animate = keyframes`
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 400% 0;
      }
      100% {
        background-position: 0 0;
      }
    `;

    const AnimatedButton = styled.button`
      padding: 8px 16px;
      font-size: 16px;
      border: none;
      border-radius: 8px;
      position: relative;
      background-color: #ff0000; // Red background
      color: white;
      cursor: pointer;
      overflow: hidden;
      z-index: 1;
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        z-index: -1;
        border-radius: 8px;
        animation: ${animate} 20s linear infinite;
        background-size: 400%;
      }
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: 8px;
        background-color: #ff0000; // Red inner background
        z-index: -1;
      }
    `;

    const Rescheduled = async () => {
        try {
            const UserData = JSON.parse(localStorage.getItem("wh_tk"))?.data;
            let baseUrl = ''
            if (UserData?.type === "agent") {
                baseUrl = `api/ticket?page=1&limit=1000&agentId=${UserData?._id}`;
            } else {
                baseUrl = `api/ticket?page=1&limit=1000`;
            }
            if (Today) {
                baseUrl = baseUrl + Today
            }
            else if (filter) {
                baseUrl = baseUrl + filter.slice(0, -1)
            }
            else if (Done && status == 3) {
                baseUrl = baseUrl + "&" + "Done=" + Done
            }
            else if (Not && status == 2) {
                baseUrl = baseUrl + "&" + "Not=" + Not
            }
            setLoading(true)
            setData([])
            var targetDate = new Date();
            var thirtyDaysAgo = new Date(targetDate);
            thirtyDaysAgo.setDate(targetDate.getDate() - 7);
            thirtyDaysAgo.setHours(23, 59, 59, 999);
            let start = new Date(thirtyDaysAgo).toUTCString();
            let end = new Date(targetDate);
            end = end.setHours(23, 59, 59, 999);
            end = new Date(end).toUTCString();
            baseUrl = baseUrl + `&subStatus=Delivery to be Rescheduled&start=${new Date(start).getTime()}&end=${new Date(end).getTime()}`
            axiosInstance.get(baseUrl).then((tableRes) => {
                setData(tableRes?.data?.data);
                setCanLogOut(tableRes?.data?.data?.find((item) => item?.tripStatus == true))
            })
            return
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    let checkAdmin = JSON.parse(localStorage.getItem("wh_tk"))?.data?.type;
    let agentdata = JSON.parse(localStorage.getItem("wh_tk"))?.data

    console.log(agentdata?.phone, "Task Screen");

    const handleCustomerCall = async (CustomerNumber) => {
        if (canCall) {
            setCanCall(false)
            try {
                const res = await axiosInstance.get(`${process.env.REACT_APP_OZONETEL_BASE_URL}/CAServices/PhoneManualDial.php?apiKey=${process.env.REACT_APP_OZONETEL_API_KEY}&userName=${process.env.REACT_APP_OZONETEL_USER_NAME}&custNumber=${CustomerNumber}&phoneName=${agentdata?.phone}&did=${process.env.REACT_APP_OZONETEL_DID}`);
                console.log(res,)
                toast.success('Connecting your call, please wait...')
            } catch (error) {
                console.log(error)
                toast.error('something went wrong !')
            } finally {
                setTimeout(() => {
                    setCanCall(true)
                }, 3000);
            }
        }
    }

    return (
        <div>
            <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div
                        style={{
                            display: 'flex', columnGap: '10px'
                        }}
                    >
                        <h5 style={{
                            fontSize: "25px",
                            fontWeight: "700"
                        }}>Tasks</h5>
                        <ThemeProvider theme={theme1}>
                            <Button onClick={() => callPunchInApi()} size='small' variant="contained" startIcon={<AccessTimeIcon />}>
                                Punch {punchStatus ? "out" : "in"}
                            </Button>
                        </ThemeProvider>

                    </div>
                    <div onClick={handleLogout} style={{ display: "flex", alignItems: "center" }}>
                        <AccountCircleOutlinedIcon />
                        <p style={{
                            fontSize: "16px",
                            fontWeight: "700"
                        }}>Log Out</p>
                    </div>
                </div>
                <div className="TaskScreen_ButtonRow">
                    <div style={{ marginTop: "15px", whiteSpace: "nowrap" }}>
                        <button style={{
                            padding: "10px 20px",
                            borderRadius: "17px",
                            color: "white",
                            backgroundColor: "black",
                            border: "none",
                            cursor: 'pointer'
                        }}
                            onClick={handleOpen}
                        >Filter
                        </button>
                        <button style={{
                            padding: "10px 20px",
                            borderRadius: "17px",
                            color: "white",
                            backgroundColor: "black",
                            border: "none",
                            marginLeft: "10px",
                            cursor: 'pointer'
                        }}
                            onClick={today}
                        >Today
                        </button>
                        <button style={{
                            padding: "10px 20px",
                            borderRadius: "17px",
                            color: "white",
                            backgroundColor: "#39cf34",
                            color: "black",
                            border: "none",
                            marginLeft: "10px",
                            cursor: 'pointer'
                        }}
                            onClick={DoneSome}
                        >Done
                        </button>
                        <button style={{
                            padding: "10px 20px",
                            borderRadius: "17px",
                            color: "black",
                            backgroundColor: "#F4511E",
                            border: "none",
                            marginLeft: "10px",
                            cursor: 'pointer'

                        }} onClick={Pending}
                        >
                            Pending
                        </button>
                        <button style={{
                            padding: "10px 20px",
                            borderRadius: "17px",
                            color: "white",
                            backgroundColor: "#000",
                            border: "none",
                            marginLeft: "10px",
                            cursor: 'pointer'
                        }}
                            onClick={Rescheduled}
                        >Rescheduled
                        </button>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                        <button style={{
                            padding: "10px 20px",
                            borderRadius: "17px",
                            color: "white",
                            backgroundColor: "#000",
                            border: "none",
                            marginLeft: "10px",
                            cursor: 'pointer',
                            whiteSpace: "nowrap"
                        }}
                            onClick={() => { window.location.reload() }}
                        > Clear Filter
                        </button>
                    </div>
                </div>

                <div className={punchStatus ? null : "content"} onClick={() => punchStatus ? null : toast.error("Please Punch in")} style={{ overflowY: "scroll", overflowX: "hidden", height: "80vh", marginTop: "15px" }}>
                    {
                        Data?.length > 0 ? Data?.map((task) => {
                            return <div className={punchStatus ? "" : 'overlay'} key={task?._id}
                                style={{ backgroundColor: "#f7fffb", borderRadius: "10px", border: "solid  #afafaf 1px", display: "flex", justifyContent: "space-between", padding: "10px", marginTop: "15px" }}>
                                {console.log(task?.cf_alt_contact_number, "Task")}
                                <div style={{ fontSize: "12px", color: "#848a83" }}>
                                    <p className='spac'>Name</p>
                                    <p className='spac'>Phone No.</p>
                                    {task?.cf_alt_contact_number && <p className='spac'> Alt Phone No.</p>}
                                    <p className='spac'>Status</p>
                                    <p className='spac'>Scheduled Date</p>
                                    <p className='spac'>Scheduled Time</p>
                                </div>
                                <div style={{ fontSize: "12px", color: "#848a83" }}>
                                    <p className='spac'>{task?.firstName ? task?.firstName : "Name not Found"}</p>
                                    {agentdata?.type !== "agent" ?
                                        <p className='spac'>{task?.phone}</p> :
                                        <button className='CallButtonToCustomer' onClick={() => handleCustomerCall(task?.phone)}>Call</button>
                                    }
                                    <br/>
                                    {agentdata?.type !== "agent" ?
                                        <p className='spac'>{task?.cf_alt_contact_number}</p> :
                                        <button className='CallButtonToCustomer' onClick={() => handleCustomerCall(task?.cf_alt_contact_number)}>Alt Number Call</button>
                                    }
                                    <p className='spac'>{task?.status}</p>
                                    <p className='spac'>{task?.scheduledDate ? convertToIST(task?.scheduledDate, "date") : null}</p>
                                    <p className='spac'>{task?.scheduledDate ? convertToIST(task?.scheduledDate, "time") : null}</p>
                                </div>
                                <div style={{ display: "inline-grid" }}>
                                    <div className={task?.subCategory == "Pickup and Refund" ? "Pickup" : ["New - Rental", "New - Buy", "B2B", "Offline - Rental"].includes(task.subCategory) ? "Delivery" : task.subCategory}
                                        style={{
                                            color: "#051c03",
                                            fontSize: "12px",
                                            borderRadius: "10px",
                                            marginTop: "5px",
                                            border: "none",
                                            paddingLeft: "12px",
                                            paddingRight: "12px",
                                            paddingBottom: "3px",
                                            paddingTop: "3px",
                                            textAlign: 'center',
                                            whiteSpace: "nowrap"
                                        }}>{task?.subCategory == "Pickup and Refund" ? "Pickup" : ["New - Rental", "New - Buy", "B2B", "Offline - Rental"].includes(task.subCategory) ? "Delivery" : task.subCategory}</div>
                                    <div>
                                    </div>
                                    <button
                                        style={{
                                            backgroundColor: task.tripStatus ? "#ef6c00" : "white",
                                            color: task.tripStatus ? "white" : "#ff9100",
                                            fontSize: "12px",
                                            borderRadius: "25px",
                                            marginTop: "12px",
                                            border: "1px #ff9100 solid",
                                            margin: "15px",
                                            marginBottom: "5px",
                                            paddingBottom: "3px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            paddingTop: "3px",
                                            textAlign: 'center',
                                            whiteSpace: "nowrap"
                                        }}
                                        onClick={() => callStartEndTrip({ startDate: task?.startTrip, endDate: task?.endtrip, id: task?._id, tripStatus: task.tripStatus })}
                                    >{task?.tripStatus ? "End" : "Start"} Trip</button>
                                    <button
                                        style={{
                                            backgroundColor: "#fc5656",
                                            color: "white",
                                            fontSize: "10px",
                                            borderRadius: "25px",
                                            marginTop: "12px",
                                            border: "none",
                                            margin: "15px",
                                            marginBottom: "5px",
                                            paddingBottom: "3px",
                                            paddingTop: "3px",
                                            textAlign: 'center',
                                            whiteSpace: "nowrap"
                                        }}
                                        onClick={() => navigate(`/detailscreen?i=${task.ticketNumber}`, { replace: true })}
                                    >More</button>
                                </div>
                            </div>
                        })
                            : loading ?
                                <div style={{ height: "40vh", display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                    <BounceLoader />
                                </div> :
                                <div className='NotFoundContainer' style={{ marginTop: "10px" }}>
                                    <img className='dataNotFountContainerImage' src={DataNotFound} />
                                    <div style={{ fontSize: "26px" }}>{"Data Not Found"}</div>
                                </div>
                    }
                </div>
            </div>

            {open && <FilterModal setOpen={setOpen} open={open} setFilter={setFilter} reload={reload} setReload={setReload} setReset={setReset} reset={reset} />}
        </div >
    )


}

export default TaskScreen